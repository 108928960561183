.sc-message {
  width: 300px;
  margin: auto;
  padding-bottom: 10px;
  display: flex;
  .sc-message--content {
    width: 100%;
    display: flex;
  }
  .typing-msg {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: italic;
    padding: 0 10px;
    margin-bottom: -40px;
  }
  .sent {
    justify-content: flex-end;
    .sc-message--avatar {
      display: none;
    }
    .img-cnt {
      text-align: right;
      img {
        width: 80%;
        border-radius: 6px;
      }
    }
  }
  .received {
    .img-cnt {
      text-align: left;
      img {
        width: 80%;
        border-radius: 6px;
      }
    }
  }
  
  
  .sc-message--avatar {
    // background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif);
    background-color: #e7e7e7;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    align-self: center;
    margin-right: 15px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  
  .sc-message--text {
    padding: 17px 20px;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    width: calc(100% - 90px);
  }
  
  .sc-message--content.sent .sc-message--text {
    color: white;
    background-color: #234155;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
  }
  
  .sc-message--content.received .sc-message--text {
    color: #234155;
    background-color: #e7e7e7;
    margin-right: 40px;
  }
  
  .sc-message--emoji {
    font-size: 40px;
  }
  
  .sc-message--file {
    background: white;
    border: solid 1px #CCCDD1;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    text-decoration: none;
  }
  
  .sc-message--file p {
    margin: 0px 0px 0px 10px;
    color: rgba(86, 88, 103, 0.6);
    width: 150px;
    word-break: break-word;
  }
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

@media (max-width: 767px) {
  .sc-chat-window {
    .sc-message-list {
      background-color: #F2F2F2 !important;
      height: 90%;
      .sc-message {
        width: 94%;
        .sc-message--content.received {
          .sc-message--text {
            background: #fff;
          }
        }
      }
    }
  }
}