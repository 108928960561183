.accordians {
    border: 1px solid #70707033;
    color: #2e5266;

    .accordian {
        border-bottom: 1px solid #70707033;

        &:last-child {
            border-bottom: 0;
        }
        .accordion-question {
            padding: 8px;
            color: #2f5266;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            &:after {
                content: "";
                margin: 8px 10px;
                height: 8px;
                width: 8px;
                display: block;
                border: solid black;
                border-width: 0 1.5px 1.5px 0;
                transform: rotate(45deg);
                opacity: 0.5;
                transition: 0.3s linear;
            }
        }
        .accordion-answer {
            display: none;
            transition: 0.3s linear;
            .rsvp-body-text {
                padding: 0 15px;
                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0;
                }
                p {
                    margin-top: 5px;
                }
            }
        }
    }
    .accordian.open {
        .accordion-question {
            background-color: #f6f6f6;
            &:after {
                transform: rotate(-135deg);
            }
        }
        .accordion-answer {
            display: block;
            // .rsvp-body-text {
            //     .scholarship-grant {
            //         color: red;
            //         // background-color: #fff;
            //         h2 {
            //             letter-spacing: 1.28px;
            //             color: #234155;
            //             text-transform: uppercase;
            //             font-size: 20px;
            //             font-weight: 600;
            //         }
            //         p {
            //             // font-size: 25px;
            //             font-size: 18px;
            //             font-weight: 450;
            //         }

            //         ul > li {
            //             // font-size: 20px;
            //             font-size: 15px;
            //             font-weight: 300;
            //         }
            //         margin: 40px 150px;
            //         @media (max-width: "1024px") {
            //             margin: 40px 100px;
            //         }
            //         @media (max-width: "680") {
            //             margin: 40px 80px;
            //         }
            //         @media (max-width: "480px") {
            //             margin: 40px 15px;
            //         }
            //     }
            // }
        }
    }
}
