.app-content,
.content {
    min-height: calc(100vh - 80px);
    background-color: #f2f2f2;
    // padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    .wrapper {
        max-width: 1300px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        width: 100%;
        .row {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
            .col {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 20px;
            }
            .col-md-3 {
                flex: 0 0 20%;
                max-width: 20%;
            }
            .col-md-6 {
                flex: 0 0 60%;
                max-width: 60%;
                margin: 20px auto;
            }
            .col-md-9 {
                flex: 0 0 80%;
                max-width: 80%;
            }
            .col-md-5 {
                flex: 0 0 40%;
                max-width: 40%;
            }
            .col-md-7 {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
        .card {
            background: #ffffff;
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 20px;
        }
        .userPostBlock {
            padding-bottom: 0;
        }
        .profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                // margin: 10px 0;
            }
            label {
                margin-top: 10px;
                font-weight: 600;
                font-size: 16px;
            }
            ul {
                padding: 0;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 0;
                li {
                    list-style: none;
                    .btn {
                        color: #013855;
                        border: 1px solid #013855;
                        display: inline-flex;
                        padding: 6px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 30px;
                        min-width: 150px;
                        font-weight: 600;
                        font-size: 13px;
                        margin-bottom: 15px;
                        transition: 0.3s linear;
                        &:hover {
                            background-color: #e1bf42;
                            border-color: #e1bf42;
                            color: #fff;
                        }
                    }
                    .btn.btn-yellow {
                        background-color: #e1bf42;
                        border-color: #e1bf42;
                        color: #fff;
                    }
                }
            }
        }
        .yourself {
            display: flex;
            align-items: center;
            .links {
                display: flex;
                align-items: center;
                margin: 0 15px;
                position: relative;
                cursor: pointer;
                img {
                    margin-right: 10px;
                }
                // .down-arrow {
                //     margin-left: 15px;
                //     position: relative;
                //     top: -1px;
                //     height: 8px;
                //     width: 8px;
                //     display: block;
                //     border: solid black;
                //     border-width: 0 2px 2px 0;
                //     transform: rotate(45deg);
                //     opacity: 0.5;
                //     transition: .3s linear;
                //     text-indent: -9999px;
                // }
                .down-arrow {
                    margin-left: 5px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    transition: 0.3s linear;
                    &:hover {
                        background-color: #eee;
                    }
                    &:after {
                        content: ">";
                        display: block;
                        color: #234155;
                        transform: rotate(90deg);
                        opacity: 0.8;
                        transition: 0.3s linear;
                        font-size: 18px;
                    }
                }
                .profile-dropdown {
                    position: absolute;
                    top: 29px;
                    background-color: #fff;
                    right: -10px;
                    left: 0;
                    padding: 15px;
                    border-radius: 0 0 4px 4px;
                    box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.05);
                    z-index: 554;
                }
            }
            .links.open {
                .down-arrow {
                    transform: rotate(-135deg);
                }
            }
            textarea,
            input {
                background: #f3f3f3;
                border-radius: 23px;
                border: 0;
                outline: none;
                padding: 10px 20px;
                min-width: 160px;
                flex: 1;
                margin-left: 15px;
                display: inline-block;
                width: 100%;
                font-size: 14px;
            }
            textarea {
                min-height: 200px;
                margin: 0;
                padding-top: 20px;
                padding-right: 45px;
                font-size: 14px;
            }
            .file-input {
                background: url("https://d310ox1f4zno4b.cloudfront.net/static-content/cam.png.webp") no-repeat;
                text-indent: -9999px;
                height: 30px;
                width: 30px;
                overflow: hidden;
                position: absolute;
                max-width: 30px;
                padding: 0 !important;
                min-width: 10px;
                right: 45px;
                border-radius: 0;
                background-size: 20px;
                top: 45px;
                background-position: center;
                cursor: pointer;
            }
        }
        .user-detail {
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 0 15px 0;
            .inner {
                display: flex;
                align-items: center;
                .roundedImage {
                    height: 50px;
                    width: 50px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 15px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
                span {
                    display: flex;
                    &:first-child {
                        font-weight: 600;
                    }
                }
            }
            .time {
                font-size: 12px;
                margin-top: 3px;
            }
        }
        .user-detail-mod {
            border-bottom: 1px solid #e9e9e9;
            padding-bottom: 15px;
            margin-bottom: 0;
            align-items: center;
            flex-wrap: wrap;
        }
        .post-img {
            margin: 0 -20px;
            max-height: 350px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
        .post-detail {
            ul.action {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 0 -20px;
                li {
                    list-style: none;

                    a {
                        padding: 10px 20px;
                        display: inline-flex;
                        font-size: 12px;
                        @media (max-width: 480px) {
                            padding: 5px 10px;
                        }
                    }
                }
            }
            .action-links {
                padding: 0;
                display: flex;
                align-items: center;
                margin: 0 -20px;
                margin-top: 20px;
                li {
                    list-style: none;
                    width: 30%;
                    a {
                        padding: 10px 20px;
                        display: inline-flex;
                        font-size: 14px;
                        img {
                            margin-right: 10px;
                        }
                    }
                    @media (max-width: 480px) {
                        width: auto;
                        a {
                            font-size: 12px;
                            padding: 8px 15px;
                            img {
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .shared-post {
                background: #f3f3f3;
                padding: 15px 15px 0 15px;
                border-radius: 0 0 8px 8px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                overflow: hidden;
                @media (max-width: 767px) {
                    margin: 0 -20px;
                    padding: 8px 25px;
                    word-break: break-word;
                }
            }
        }
        .post-comment-area {
            display: flex;
            flex-direction: column;
            background: #f7f7f7;
            padding: 15px 18px 0;
            margin: 0 -20px;
            position: relative;
            top: 0px;
            .comment-area-inner {
                display: flex;
                .user-image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
                .comment-content-text {
                    background: #e4e4e4;
                    padding: 11px 17px;
                    border-radius: 8px;
                    word-break: break-word;
                    h4 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    p {
                        margin: 5px 0 0 0;
                    }
                }
                .comment-msg-socail-icon {
                    ul {
                        padding: 0;
                        margin: 20px 0;
                        li {
                            display: inline-block;
                            margin-right: 12px;
                            list-style: none;
                            .ml-2 {
                                margin-left: 5px;
                            }
                            img {
                                width: 18px;
                            }
                            .reply-container {
                                font-weight: 600;
                                cursor: pointer;
                                font-size: 12px;
                            }
                            span {
                                color: #707070;
                                font-size: 11px;
                                line-height: 10px;
                            }
                        }
                        .edit_trash_banner {
                            display: inline-flex;
                            float: right;
                            .trash {
                                img {
                                    width: 14px;
                                    height: auto;
                                }
                            }
                            .edit {
                                margin-right: 20px;
                                img {
                                    width: 14px;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
                .comment-content {
                    flex: 1;
                }
            }
        }
        .comments-div {
            background: #f7f7f7;
            padding: 15px 18px;
            margin: 0 -20px;
            position: relative;
            top: 0px;
            border-radius: 0 0 10px 10px;
            .comment-inner {
                display: flex;
                flex: 1;
                align-items: center;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
                input {
                    display: block;
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 19px;
                    border: 1px solid #e7e7e7;
                    border-radius: 8px;
                    padding: 3px 30px 3px 10px;
                    min-height: 19px;
                    margin: 0;
                    overflow: auto;
                    padding-right: 50px;
                    background-color: #fff;
                    outline: none;
                }
            }
        }
        .search-form-content {
            position: relative;
            input {
                background: #f3f3f3 0% 0% no-repeat padding-box;
                border-radius: 23px;
                border: 0;
                outline: none;
                padding: 8px 20px 8px 40px;
                display: inline-block;
                width: 100%;
                font-size: 13px;
            }
            img {
                position: absolute;
                top: 9px;
                left: 15px;
                max-width: 16px;
            }
        }
        .trending-card {
            font-size: 14px;
            color: #234155;
            label {
                font-weight: 600;
                margin-top: 20px;
                display: block;
                color: #234155;
            }
            ul {
                padding: 0;
                max-height: 150px;
                overflow-y: auto;
                li {
                    list-style: none;
                    margin-bottom: 20px;
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
        .st-block {
            text-align: center;
            padding: 0 20px;
            .title {
                font-weight: 600;
                margin-top: 20px;
                margin-bottom: 10px;
                color: #2e5266;
            }
            .btn {
                background-color: #2e5266;
                color: #fff;
                font-size: 12px;
                display: block;
                padding: 8px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
        .st-block2 {
            .title {
                color: #33656b;
            }
            .btn {
                background-color: #33656b;
            }
        }
        .st-block3 {
            .title {
                color: #857665;
            }
            .btn {
                background-color: #857665;
            }
        }
    }
    @media (max-width: 1024px) {
        // padding-top: 48px !important;
        .wrapper {
            .row {
                .col-md-3 {
                    display: none;
                }
                .col-md-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
    @media (max-width: 480px) {
        .wrapper {
            .comments-div,
            .card {
                border-radius: 0;
            }
            .card {
                padding: 10px 20px;
            }
            .row {
                .col-md-6 {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
            .post-detail {
                .action-links {
                    border-top: 1px solid #cccccc4a;
                    padding-top: 10px;
                    margin-top: 10px;
                    justify-content: space-between;
                }
            }
            .post-comment-area,
            .comments-div {
                top: 10px;
            }
        }
    }
}
.app-content,
.content {
    .wrapper {
        .user-detail {
            .roundedimg {
                margin-right: 15px;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #ccc;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .post-detail {
            .action-links {
                li {
                    a {
                        padding: 15px 20px;
                        display: flex;
                        font-size: 14px;
                        align-items: center;
                        justify-content: center;
                        transition: 0.2s linear;
                        min-height: 54px;
                        &:hover {
                            background-color: rgb(233, 236, 240);
                        }
                    }
                    list-style: none;
                    width: 33.3334%;
                }
            }
        }
        .epmty-post {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
                height: 220px;
                width: 300px;
            }
            .empty-post-content {
                width: 100%;
                text-align: center;
                .learn-more-btn {
                    margin: 0;
                    width: auto;
                    padding: 15px 25px;
                    border-radius: 7px;
                }
            }
        }
    }
}
