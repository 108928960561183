.sc-chat-window {
  width: 330px;
  height: calc(100% - 120px);
  max-height: 400px;
  position: fixed;
  right: 25px;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px 10px 0 0;
  // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  .sc-message-list {
    height: 80%;
    overflow-y: auto;
    background-color: white;
    background-size: 100%;
    padding: 40px 0px;
    .file-loader {
      border: 6px solid #e6e6e6;
      border-radius: 50%;
      border-top: 6px solid #234155;
      width: 35px;
      height: 35px;
      margin-right: 20px;
      -webkit-animation: spin 0.5s linear infinite; /* Safari */
      animation: spin 0.5s linear infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .sc-message--me {
    text-align: right;
  }
  .sc-message--them {
    text-align: left;
  }
}

.freshchat-widget-opened {
  .sc-chat-window.opened {
    right: 425px;
  }
}

.freshchat-widget-closed {
  .sc-chat-window.opened {
    right: 95px;
  }
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

@media (max-width: 767px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    background: #f2f2f2;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
    right: 0 !important;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
