.session-page {
  padding: 40px 0;
  color: #2f5266;
  h1 {
    text-transform: uppercase;
    font-size: 26px;
  }
  .inner-session {
    background: #f9f9f9;
    border-radius: 7px;
    opacity: 1;
    max-width: 1000px;
    margin: 20px auto;
    padding: 25px;
    min-height: 300px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 5px 0;
        img {
          margin-right: 10px;
        }
      }
    }
    table {
      width: 100%;
      display: block;

      tr {
        th {
          text-align: left;
        }
        td {
          width: auto;
          justify-content: space-between;
        }
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #2f5266;
    h1 {
      color: #fff;
    }
    .inner-session {
      h2 {
        text-align: center;
        margin-bottom: 40px;
      }
      table {
        overflow-x: auto;
        white-space: nowrap;
      }
    }
  }
}
