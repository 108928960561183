.education-page {
    padding: 40px 0;
    color: #2F5266;
    img {
        max-width: 100%;
    }
    .container-sm {
        max-width: 960px;
    }
    h1 {
        text-transform: uppercase;
        font-size: 26px;
    }
    .edu-list {
        background: #F9F9F9;
        padding: 20px;
        display: flex;
        margin-top: 20px;
        align-items: center;
        position: relative;
        transition: .3s linear;
        cursor: pointer;
        .inner-img {
            height: 120px;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
        }
        h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }
        p {
            margin: 8px 0 0 0;
        }
        .more-info {
            position: absolute;
            background-color: #E1BF42;
            top: 0;
            right: 0;
            bottom: 0;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            &:after {
                content: "";
                margin: 0 8px 0 0;
                height: 16px;
                width: 16px;
                display: block;
                border: solid #fff;
                border-width: 0 1.5px 1.5px 0;
                transform: rotate(-45deg);
            }
            &:hover {
                opacity: 1;
            }
        }
        &:hover {
            .more-info {
                opacity: 0.9;
            }
        }
    }
    @media (max-width: 768px) {
        background-color: #2F5266;
        h1 {
            color: #fff;
        }
        .edu-list {
            flex-direction: column;
            border-radius: 4px;
            overflow: hidden;
            h2 {
                text-align: center;
                margin-bottom: 15px;
            }
            .inner-img {
                height: 100px;
                width: 100%;
                min-width: 180px;
                max-width: 180px;
            }
            .more-info {
                opacity: 0;
                left: 0;
                width: auto;
                height: 50px;
                top: inherit;
            }
        }
    }
}