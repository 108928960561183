.bayan-banner {
  position: relative;
  .slick-arrow {
    display: none !important;
  }
  .byan-bg-img {
    .desktop_View {
      display: flex;
      flex-direction: row-reverse;
      .threemobiles {
        height: 30%;
        width: 423px;
        float: right;
        margin-right: 70px;
        z-index: 999;
        position: relative;
        margin-top: 20px;
        width: 570px;
        margin-top: -78px;
      }
      .downloadbuttons{
        height: 50px;
        width: 350px; 
        display: inline-flex;
        margin-top: 5px; 
      }
    }
  }
  .slick-dots {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    list-style: none;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 20px;
    li {
      button {
        background: #002f4f;
        border: 1px solid #ffffff;
        opacity: 0.55;
        height: 16px;
        max-height: 16px;
        width: 16px;
        max-width: 16px;
        display: inline-block;
        text-indent: -9999px;
        border-radius: 20px;
        margin: 0px;
        outline: none !important;
        cursor: pointer;
      }
    }
    .slick-active {
      button {
        background: #ffffff;
        opacity: 1;
      }
    }
  }
  .webinar-banner {
    p {
      color: #fff;
    }
    .text-holder {
      width: fit-content;
      top: 5% !important;
      .text-container {
        width: auto;
      }
      .threemobiles {
        width: 35%;
        float: right;
        z-index: 100;
        position: relative;
        margin: 0px;
        margin-right: 100px;
      }
    }
    @media screen and (max-width: 768px) {
      .text-holder {
        top: 0 !important;
      }
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      .text-container {
        height: auto !important;
      }
      h1 {
        span {
          margin-bottom: 20px;
          display: block;
        }
        br {
          display: none;
        }
      }
      h2 {
        margin: 15px 0;
        font-size: 16px !important;
        padding: 0 30px;
        &.american-islam-h2 {
          padding: 0 10px;
        }
      }
      .text-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100% !important;
        .mfirst-banner-img {
          height: 30%;
          margin-top: 20px;
        }
      }
      .bayan-button {
        margin: 20px 0;
      }

      .american-islam-desktop {
        display: none;
      }
    }
  }
  .byan-bg-img {
    display: none;
    position: relative;
    min-height: 440px;
    max-height: 440px;
    height: 440px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img.mob-img {
      display: none;
    }
    img.ramadan-2021 {
      object-fit: contain;
      // background-color: #616161; //ramadan
      background-color: #496678; //symposium
    }
    &.active {
      display: flex;
    }
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      @media screen and (max-width: 480px) {
        height: auto;
        bottom: 0;
        img.threemobiles {
          height: 30%;
          margin-top: 20px;
          width: 254px;
          margin-left: 100px;
          // width: 396px;
          margin-right: 37px;
        }
      }
      &.layer-1 {
        background-color: rgba(46, 82, 102, 0.3);
      }
      &.layer-2 {
        // background-color: rgba(171, 161, 150, 0.1);
        background-color: rgba(46, 82, 102, 0.5);
      }
      &.layer-15 {
        // background-color: rgba(171, 161, 150, 0.1);
        background-color: rgba(0, 9, 14, 0.5);
      }
      &.webinar-layer {
        // background-color: rgba(171, 161, 150, 0.1);
        background-color: rgba(46, 82, 102, 0.75);
      }
      &.layer-3 {
        background-color: rgba(117, 150, 154, 0.3);
      }
      &.layer-4 {
        background-color: rgba(48, 51, 54, 0.3);
      }
      &.layer-5 {
        background-color: rgba(151, 120, 87, 0.3);
      }
    }
    .text-holder:first-child {
      top: 7%;
    }
    .text-holder {
      position: absolute;
      top: 17%;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 880px;
      padding: 10px;
      .mfirst-banner-img {
        height: 30%;
        margin-top: 20px;
      }
      .text-container:first-child {
        margin: 0;
      }
      .text-container {
        margin: 25px 0;
        p {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 1px;
          font-weight: 300;
          color: #fff;
        }
        h1 {
          font-size: 42px;
          font-weight: 500;
          line-height: 55px;
          color: #ffffff;
        }
        h2 {
          font-size: 20px;
          line-height: 32px;
          text-transform: capitalize;
          color: #ffffff;
        }
        .leadrship_slider {
          color: #e1bf42;
          font-weight: bold;
        }
      }
      .first-banner-text-container {
        width: 70%;
        float: left;
      }
      .first-banner-img {
        width: 30%;
        float: right;
      }
      .mfirst-banner-img {
        height: 30%;
        margin-top: 20px;
      }
      .button-container {
        .apply-btn {
          width: 199px;
          height: 45px;
          font-size: 16px;
        }
        .mapply-btn {
          background-color: black;
          border-radius: 18px;
          height: 56px;
          width: 150px;
        }
        .learn-more-btn {
          width: 199px;
          height: 45px;
          font-size: 16px;
          margin-left: 15px;
        }
        .bayan-support-btn {
          width: 250px;
          height: 50px;
          font-size: 16px;
        }
      }
      .first-banner-button-container {
        width: 70%;
        float: left;
      }
    }
    .bayan-berkley-webinar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 1170px !important;
      .bayan-berkley-logos-container {
        display: flex;
        flex-direction: column;
        div {
          border-radius: 14px;
          margin: 10px 0;
          background: #ffffff;
          img {
            margin: 10px 10px;
          }
        }
      }
      .text-container {
        margin: 25px 20px;
      }
    }
  }
  .switch-button {
    position: absolute;
    left: 95%;
    top: 35%;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    .custom-dot {
      height: 18px;
      width: 18px;
      background-color: #002f4f;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid #c0c0c0;
      margin: 5px;
      cursor: pointer;
      &.active {
        background-color: #ffffff !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          margin-right: auto;
          height: 30%;
          margin-top: 320px;
          margin-left: auto;
          align-items: center;
          width:300px;;
        }
        .text-holder {
          .downloadbuttons {
            margin-top: -14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          float: right;
          margin: 0px;
          width: 444px;
          
        }
        .text-holder {
          .downloadbuttons {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          float: right;
          width: 400px;
          margin: -52px;
          margin-top: 40px;
        }
        .text-holder {
          .downloadbuttons {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          float: right;
          margin-top: 100px;
        }
        .text-holder {
          .downloadbuttons {
            margin-top: 40px;
            width:302px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 426px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .text-holder {
          .downloadbuttons {
            margin-top: 19px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .bayan-banner {
    .slick-dots {
      li {
        button {
          display: flex;
          padding: 0;
        }
      }
    }
    position: relative;
    .byan-bg-img {
      margin-top: 5px;
      min-height: 600px;
      max-height: 600px;
      height: 600px;
      align-items: flex-start;
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      .threemobiles {
        width: 35%;
        float: right;
        z-index: 100;
        position: relative;
        margin: 0px;
        margin-right: 100px;
        margin-top: 10px;
      }
      img.desk-img {
        display: none;
      }
      img.mob-img {
        display: block;
        height: 100vh;
      }
      .text-holder {
        top: 0;
        height: 80%;
        .text-container {
          margin: 0 15px 0;
          height: 83%;
        }
        .text-container {
          margin: 0 15px 0;
          height: 83%;
          p {
            text-align: center;
            font-size: 14px;
            margin-bottom: 40%;
          }
          .mb-15 {
            margin-bottom: 15% !important;
          }
          .mb-15:first-child {
            margin-bottom: 0 !important;
          }
          h1 {
            font-size: 30px;
            line-height: 40px;
          }
          h2 {
            font-size: 18px;
          }
        }
        .text-center-mobile {
          text-align: center;
          .text_sizing {
            font-size: medium;
          }
        }
        .american-islam-slide {
          p {
            margin-bottom: 5% !important;
          }
        }
        .button-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .learn-more-btn {
            margin: 0;
          }
        }
      }
    }
    .switch-button {
      left: 0;
      top: 0;
      bottom: 5%;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      .custom-dot {
        height: 22px;
        width: 22px;
      }
    }

    .first-banner-container {
      text-align: center;
      .first-banner-text-container {
        height: 55% !important;
        width: 100% !important;
        float: left !important;
        h1 {
          font-size: 25px !important;
          line-height: 30px !important;
        }
        h2 {
          font-size: 15px !important;
        }
      }
      .first-banner-img {
        width: 50% !important;
        height: 50% !important;
        float: unset !important;
        object-fit: contain;
      }
      .first-banner-button-container {
        width: 100% !important;
        float: left !important;
      }
    }
  }
}
@media screen and (max-width: 670px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          margin-right: auto;
          height: 250px;
          margin-top: 320px;
          align-items: center;
          margin-left: auto;
        }
        .text-holder {
          .downloadbuttons {
            margin-top: -14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .bayan-banner {
    .byan-bg-img {
      .desktop_View {
        .threemobiles {
          margin-right: auto;
          height: 250px;
          margin-top: 340px;
          margin-left: auto;
          width:300px;
        }
        .text-holder {
          .downloadbuttons {
            z-index: 9999;
            margin-top: 29px;
          }
        }
      }
    }
  }
}

.dark-blue {
  background-color: #083148 !important;
}
