.network-block {
    font-size: 14px;
    .conection-list {
        margin-top: 93px;
        @media (max-width: 1440px) {
            margin-top: 0;
        }
        ul {
            padding: 0;
            max-width: 155px;
            margin: 10px auto;
            li {
                list-style: none;
                
                a {
                    padding: 8px 16px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    margin: 5px 0;
                    img {
                        height: 16px;
                        width: 16px;
                    }
                    div {
                        min-width: 122px;
                        text-align: left;
                    }
                    @media (max-width: 480px) {
                        font-size: 12px !important;
                    }
                    @media (max-width: 375px) {
                        padding: 8px;
                    }
                    span {
                        height: 16px;
                        width: 16px;
                        margin-left: 5px;
                        background-color: #EF5350;
                        color: #fff;
                        display: inline-flex;
                        align-items: center;
                        border-radius: 50%;
                        font-size: 10px;
                        justify-content: center;
                        z-index: 22;
                    }
                }
            }
            li.active {
                a {
                    background-color: #2E5266;
                    color: #fff;
                    border-radius: 30px;
                }
            }
        }
    }
    .small-wrapper {
        max-width: 600px;
        margin: 0 auto;
    }
    .medium-wrapper {
        max-width: 800px;
        margin: 0 auto;
    }
    .my-conection {
        padding-left: 100px;
        // @media (min-width: 1025px) {
        //     max-width: 800px;
        // }
        h1 {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 5px;
        }
        .search-form {
            position: relative;
            margin-left: 15px;
            input {
                background: #fff;
                border-radius: 23px;
                border: 0;
                outline: none;
                padding: 10px 20px 10px 40px;
                min-width: 160px;
                display: inline-block;
                font-size: 12px;
            }
            img {
                position: absolute;
                top: 12px;
                left: 15px;
                max-width: 16px;
            }
        }
        .my-conection-sub {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            .my-conection-text {
                display: flex;
                align-items: center;
                p {
                    margin: 0;
                    padding-right: 25px;
                }
                a {
                    display: flex;
                    align-items: center;
                    span.down-arrow {
                        margin-left: 15px;
                        position: relative;
                        top: -1px;
                        height: 8px;
                        width: 8px;
                        display: block;
                        border: solid black;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        opacity: 0.5;
                        transition: .3s linear;
                        text-indent: -9999px;
                    }
                }
            }
            
        }
        .my-conection-sub.mobile-only {
            display: none;
        }
        .st-list {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            .st-in.st-left {
                flex: 1;
                display: flex;
                align-items: center;
                label {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    display: block;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                span {
                    height: 60px;
                    min-width: 60px;
                    display: inline-flex;
                    margin-right: 15px;
                    overflow: hidden;
                    border-radius: 50%;
                    cursor: pointer;
                    img {
                        object-fit: cover;
                    }
                }
            }
        }
        .st-in.st-right {
            .accept-request {
                display: inline-block;
                background: #2E5266;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin-right: 12px;
                text-align: center;
                padding: 10px;
                cursor: pointer;
            }
            .decline-request {
                display: inline-block;
                background: #2E5266;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                text-align: center;
                padding: 5px 0;
                cursor: pointer;
                color: #fff;
                font-size: 18px;
                font-weight: bold;
            }
            .show-connection-options {
                position: absolute;
                margin-top: -2px;
                width: 130px;
                background-color: #fff;
                padding: 15px;
                border-radius: 0 0 4px 4px;
                box-shadow: 0px 0px 6px #e7e7e7;
                z-index: 1;
                .cn-item {
                    cursor: pointer;
                }
            }
        }
        .st-list:last-child {
            margin-bottom: 0;
        }
    }
    .network-bkl {
        max-width: 700px;
    }

    @media (max-width: 1024px) {
        .my-conection {
            padding-left: 0;
        }
        .content .wrapper .row .col-sm-4 {
            flex: 0 0 40%;
            max-width: 40%
        }
        .content .wrapper .row .col-sm-6 {
            flex: 0 0 60%;
            max-width: 60%
        }
        .network-bkl {
            max-width: 100%;
        }
        .my-conection-sub.mobile-only {
            display: flex !important;
            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
    @media (max-width: 767px) {
        .my-conection-sub.mobile-only {
            display: flex !important;
            flex-direction: column-reverse;
            .search-form {
                margin-left: 0 !important;
                margin-bottom: 15px;
                input {
                    width: 100%;
                }
            }
            .my-conection-text {
                justify-content: space-between;
            }
        }
    }
    
    
}
.classes-block {
    .col-md-3a {
        flex: 0 0 25% ;
        max-width: 25% ;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
    }
    .col-md-6 {
        flex: 0 0 80% !important;
        max-width: 80% !important;
    }
    .card-vertical {
        position: relative;
        height: calc(100% - 20px);
        .st-left {
            span {
                margin-right: 0 !important;
            }
        }
        .close {
            position: absolute;
            right: 15px;
            height: 25px;
            width: 25px;
            top: 15px;
            border-radius: 50%;
            transition: .3s linear;
            &:before, 
            &:after {
                left: 11px;
                top: 6px;
            }
            &:hover {
                background-color:#2E5266;
                &:before, 
                &:after {
                    background-color: #fff;
                }
            }
        }
        .st-in.st-left,
        .st-list {
            flex-direction: column;
            align-items: center;
            text-align: center;
            label {
                margin-top: 20px;
            }
            span,
            label{
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .st-in.st-right {
            flex-direction: column;
            display: flex;
            margin-top: 10px;
            a {
                font-weight: 600;
                margin-top: 10px;
                cursor: pointer;
                padding: 6px 24px;
                border-radius: 30px;
                -moz-transition: .3s linear;
                -webkit-transition: .3s ease-out;
                transition: .3s linear;
                // @media (max-width: 480px) {
                //     padding: 6px 0px;
                // }
                &:hover {
                    // background-color: #2E5266;
                    background-color: #2e526696;
                    color: #fff;
                }
                img {
                    margin-right: 7px;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 0 15px;
        padding-top: 0;

        h1 {
            text-align: center;
            margin-bottom: 0 !important;
        }
        .col.col-md-6.col-sm-12 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .conection-list {
            display: flex !important;
            margin-bottom: 15px;
            ul {
                max-width: 100%;
                display: flex;
            }
        }
        .my-conection-sub {
            display: none !important;
        }
    }
    @media (max-width: 768px) {
        .col-md-3a {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
        .card {
            border-radius: 4px !important;
        }
    }
    @media (max-width: 767px) {
        padding: 0;
        
        .row {
            margin-left: -5px !important;
            margin-right: -5px !important;
        }
        .col-md-3a {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 5px !important;
            padding-right: 5px !important;
            .card {
                margin-bottom: 0px !important;
                height: 100%;
            }
        }
    }
    
}
.classes-page {
    .st-application {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        label {
            font-weight: 600;
            font-size: 18px;
            margin: 15px 0;
        }
    }
    .my-conection {
        .card {
            padding: 0 !important;
            overflow: hidden;
        }
    }
    .col-md-8 {
        flex: 0 0 66%;
        max-width: 66%;
    }
    .col-md-3a {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
    }
    .my-conection {
        h1 {
            margin-bottom: 20px;
        }
    }
    .conection-list {
        margin-top: 55px;
        ul {
            max-width: 190px !important;
            padding: 0;
            list-style: none;
        }
        //  &.my-classes {
        //     ul {
        //         max-width: 210px !important;
        //     }   
        // }
    }
    .mr-2 {
        margin-right: 7px;
    }
    .class-image {
        background-color: #ccc;
        width: 100%;
        height: 125px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .class-body {
        padding: 15px;
        text-align: left;
        label {
            font-size: 12px !important;
            margin: 0 !important;
            min-height: 36px;
        }
        p {
            font-size: 12px;
            margin: 5px 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .btn-in {
            border-radius: 3px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            padding: 8px;
            margin-top: 10px;
        }
        .btn-green {
            background: #65C08B;
        }
        .btn-yellow {
            background: #e1bf42;
        }
    }
    @media (max-width: 1024px) {
        h1 {
            text-align: center;
            margin-bottom: 0 !important;
        }
        .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .conection-list.mobile-only {
            display: block;
            margin-top: 0;
            ul {
                max-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                margin-bottom: 15px;
                li {
                    opacity: 0.3;
                    a {
                        margin: 0;
                        padding: 5px;
                        font-weight: 600;
                    }
                }
                li.selected {
                    opacity: 1;
                }
                
            }
        }
    }
    @media (max-width: 767px) {
        
        .col-md-3a,
        .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .card {
            border-radius: 6px !important;
        }
    }
}
