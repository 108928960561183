.scholarship-grant {
    // color: #ffffff;
    // background-color: #fff;
  h2 {
    letter-spacing: 1.28px;
    color: #234155;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
  }
  p {
    // font-size: 25px;
    font-size: 18px;
    font-weight: 450;
  }

  ul > li {
    // font-size: 20px;
    font-size: 15px;
    font-weight: 300;
  }
  margin: 40px 150px;
  @media (max-width: "1024px") {
    margin: 40px 100px;
  }
  @media (max-width: "680") {
    margin: 40px 80px;
  }
  @media (max-width: "480px") {
    margin: 40px 15px;
  }
}
