.post-row-container {
  display: flex;
  .post-box {
    float: left;
    width: 100%;
    .post-content {
      font-weight: 400 !important;
      .post-content-inner {
        float: left;
        width: 100%;
        border-radius: 6px 6px 0 0;
        .post-head {
          width: 100%;
          margin: 0 0 28px;
          .post-user {
            width: 64px;
            height: 64px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            margin: 0 9px 0 0;
            cursor: pointer;
            position: relative;
            margin-bottom: 20px;
          }
          .post-block {
            float: left;
            width: 100%;
          }
        }
      }
    }
  }
}
