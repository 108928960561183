.mobile-view {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    .container {
        div {
            background-color: #fff;
            margin-top: 20px;
            padding-bottom: 20px;
            margin-left: -15px;
            margin-right: -15px;
            h1 {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #2E5266;
                text-transform: capitalize;
                margin: 15px 0 0 0;
                padding: 0 15px;
            }
            p {
                margin: 4px 0 0 0;
                letter-spacing: 0px;
                color: #2E5266;
                font-size: 12px;
                padding: 0 15px;
            }
        }
    }
}