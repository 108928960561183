.MA-Scholarship {
  .scholarship-container {
    .scholarship-award {
      .award-icon {
        margin-top: 10px;
        height: 40px;
      }
      display: flex;
      h3 {
        margin-left: 10px;
      }
    }
    .scholarship-banner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 620px) {
        flex-direction: column;
      }
      p {
        flex: 80%;
        color: #ffffff;
        padding: 8px;
        text-align: center;
        font-size: 20px;
        @media (max-width: 780px) {
          font-size: 18px;
        }
      }
      background-color: #2f5266;

      img {
        margin-top: 0%;
        object-fit: contain;
        flex: 10%;
      }
      @media (max-width: 620px) {
        img {
          width: 100%;
          max-height: 180px;
          background-color: #000;
          object-fit: cover;
        }
      }
    }
    margin: 40px 150px;
    @media (max-width: 1024px) {
      margin: 40px 100px;
    }
    @media (max-width: 780px) {
      margin: 40px 35px;
    }
    @media (max-width: 480px) {
      margin: 40px 25px;
    }
    .scholar-info-container {
      display: flex;
      @media (max-width: 480px) {
        flex-direction: column;
      }
      .scholarship-form {
        flex: 65%;
      }
      .scholarhip-info {
        flex: 35%;
        margin-left: 40px;
        margin-top: 50px;

        @media (max-width: 780px) {
          margin-left: 10px;
        }
        @media (max-width: 480px) {
          margin-left: 0%;
          margin-top: 0%;
        }
      }
    }
  }
}
