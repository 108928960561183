.app-content {
  background-color: #f2f2f2;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  color: #2e5266;
  .wrapper {
    max-width: 1300px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 100%;
  }
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
  }
  .row {
    .col {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 20px;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .card {
      background: #ffffff;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      margin-top: 15px;
    }
    .left-pub-pan {
      flex: 0 0 60%;
      max-width: 60%;
      @media (max-width: 768px) {
        flex: 0 0 70%;
        max-width: 70%;
      }
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .back-profile {
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        .back-btn {
          margin: 8px;
          height: 9px;
          width: 9px;
          display: inline-block;
          border: solid #2e5266;
          border-width: 0 3px 3px 0;
          transform: rotate(135deg);
        }
      }
      input.title-field {
        background: #f3f3f3;
        border-radius: 6px;
        border: 0;
        outline: none;
        padding: 10px 20px;
        min-width: 160px;
        flex: 1;
        display: inline-block;
        width: 100%;
        font-size: 14px;
        margin-top: 15px;
        height: 40px;
      }
      .pub-img-cover {
        border: 1px dashed #2e5266;
        border-radius: 30px;
        background-color: #fff;
        height: 40px;
        display: flex;
        margin: 25px 0;
        position: relative;
        cursor: pointer;
        input {
          padding: 0;
          opacity: 0;
        }
        &:after {
          content: "Choose a cover image";
          position: absolute;
          left: 0;
          right: 0;
          padding: 8px;
          text-align: center;
        }
      }
      .se-toolbar,
      .sun-editor {
        border: 0;
        outline: 0;
        padding: 0;
        background-color: #fff;
      }
      .se-placeholder {
        padding: 25px;
      }
      .se-wrapper-inner {
        background: #f3f3f3;
        border-radius: 14px;
        min-height: 150px;
        padding: 25px;
      }
      .se-resizing-bar {
        display: none;
      }
      .tag-block {
        padding: 10px 25px;
        @media (max-width: 767px) {
          padding: 10px 0;
        }
        .pub-tags {
          label {
            font-weight: 600;
            margin-bottom: 5px;
            display: inline-block;
          }
          .tag-item {
            background-color: #fff;
            padding: 2px 10px;
            display: inline-flex;
            margin: 5px 5px 0 0;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
        .pub-tags-top {
          display: flex;
          align-items: flex-start;
          label {
            margin-bottom: 0;
            white-space: nowrap;
          }
          .tag-item {
            margin: 0 0 5px 5px;
            background-color: #2e5266;
            color: #fff;
          }
        }
      }
      .p-action-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;
        button {
          background: #2e5266;
          box-shadow: 0px 1px 6px #00000029;
          border: 1px solid #2e5266;
          color: #fff;
          display: inline-block;
          padding: 5px 20px;
          border-radius: 4px;
          margin-right: 10px;
          min-width: 100px;
          text-align: center;
          @media (max-width: 767px) {
            padding: 5px 10px;
            min-width: 85px;
          }
        }
        .disable {
          opacity: 0.5;
          pointer-events: none;
        }
        .yellow-btn {
          background-color: #e1bf42;
          border-color: #e1bf42;
        }
      }
      .publication-form {
        .preview-img {
          display: flex;
          align-items: center;
          padding: 20px;
          @media (max-width: 767px) {
            flex-wrap: wrap;
            .col-sm-6 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          img {
            max-height: 300px;
            max-width: 100%;
            object-fit: contain;
            background-color: #f3f3f3;
            border-radius: 10px;
          }
          .profile-action-btn {
            display: flex;
            .change {
              overflow: hidden;
              position: relative;
              color: #2e5266;
              margin-right: 15px;
              padding: 10px;
              font-weight: 600;
              cursor: pointer;
              input {
                opacity: 0;
                position: absolute;
                left: 0px;
                margin: 0;
                top: -12px;
                right: 0;
                bottom: 0;
                padding: 0;
                cursor: pointer;
              }
            }
            .remove {
              background-color: transparent;
              border: 0;
              color: #2e5266;
              font-weight: 600;
            }
          }
        }
      }
    }
    .preview-pan {
      flex: 0 0 25%;
      max-width: 25%;
      @media (max-width: 768px) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
        display: none;
      }
      .publication-form {
        position: sticky;
        top: 100px;
        .published-post {
          max-height: 400px;
          overflow-y: auto;
          .pub-card {
            background: #ffffff;
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 20px;
          }
          .overlay-horizontal {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #2e5266a1;
            display: flex;
            align-items: flex-end;
            opacity: 0;
            cursor: pointer;
            transition: 0.2s linear;
            .bottom-action {
              background-color: #fff;
              padding: 15px;
              text-align: center;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              .ac-btn {
                height: 26px;
                min-height: 26px;
                width: 26px;
                display: inline-block;
                background-color: #2e52661c;
                text-indent: -9999px;
                border-radius: 50%;
              }
              .edit {
                background: url("/icons/pencil.svg") no-repeat;
                background-size: 14px;
                background-position: center;
                background-color: #2e52661c;
              }
              .delete {
                background: url("/icons/delete.svg") no-repeat;
                background-size: 14px;
                background-position: center;
                background-color: #2e52661c;
              }
            }
          }
          &:hover {
            .overlay-horizontal {
              opacity: 1;
            }
          }
        }
        ul {
          margin: 20px 0;
          padding: 0;
          display: flex;
          list-style: none;
          li {
            flex: 1;
            a {
              display: block;
              text-align: center;
              opacity: 0.5;
              font-weight: 600;
              font-size: 14px;
            }
          }
          li.active {
            a {
              opacity: 1;
            }
          }
        }
        .inner-drafts {
          max-width: 200px;
          border-radius: 6px;
          overflow: hidden;
          display: block;
          background-color: #000;
        }
        .detail-drf {
          font-weight: 600;
          font-size: 12px;
          margin-bottom: 5px;
          word-break: break-all;
        }
        .gray-drf {
          font-size: 12px;
          opacity: 0.5;
          margin: 0;
        }
        .pub-text {
          margin: 0 auto;
          max-width: 180px;
          font-size: 14px;
          text-align: center;
        }
        .drafts-card {
          position: relative;
          overflow: hidden;
          .overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #2e5266a1;
            display: flex;
            align-items: flex-end;
            opacity: 0;
            cursor: pointer;
            transition: 0.2s linear;
            .bottom-action {
              background-color: #fff;
              padding: 15px;
              flex: 1;
              text-align: center;
              .ac-btn {
                height: 30px;
                width: 30px;
                display: inline-block;
                background-color: #2e526638;
                text-indent: -9999px;
                border-radius: 50%;
                margin: 5px;
              }
              .edit {
                background: url("/icons/pencil.svg") no-repeat;
                background-size: 16px;
                background-position: center;
                background-color: #2e526638;
              }
              .delete {
                background: url("/icons/delete.svg") no-repeat;
                background-size: 16px;
                background-position: center;
                background-color: #2e526638;
              }
            }
          }
          &:hover {
            .overlay {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .previw-modal {
    background-color: #234155cc !important;
    .modal-wrapper {
      max-width: 525px;
      margin: 15px auto;
      position: relative;
      padding: 0 15px;
      .modal_content {
        max-width: 100%;
        border: 0 !important;
        border-radius: 10px !important;
        position: static;
        padding-bottom: 0 !important;
      }
      .detail-drf-1 {
        word-break: break-word;
        margin-top: 0;
        padding: 0 15px;
      }
      .close-prv {
        position: absolute;
        right: -50%;
        top: 50%;
        background-color: transparent;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        @media (max-width: 768px) {
          right: 0;
          left: 0;
          text-align: center;
          top: -20px;
        }
      }
      .inner-drafts {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
          width: 100%;
          object-fit: contain;
          max-width: 100%;
          max-height: 250px;
          background-color: #000;
        }
      }
      .detail-prv {
        padding: 10px 15px 10px 15px;
        word-break: break-all;
        p {
          margin: 5px 0 0 0;
          font-size: 12px;
          &:first-child {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .m-20 {
        margin-left: -20px;
        margin-right: -20px;
      }
      .pr-header {
        padding: 15px;
        margin-top: 45px;
        h3 {
          text-align: center;
          color: #fff;
          font-weight: 400;
          margin-top: 10px;
        }
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          li {
            padding: 10px 15px;
            list-style: none;
            cursor: pointer;
            opacity: 0.6;
            font-weight: normal;
            font-size: 14px;
            color: #fff;
            &.active {
              opacity: 1;
            }
          }
        }
      }
      .footer-socail {
        padding: 10px 15px;
        background-color: #fff;
        margin-top: 10px;
        border-radius: 10px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .share {
          display: flex;
          align-items: center;
          .drop-share {
            position: relative;
            .dropdown-share {
              display: flex;
              align-items: center;
              font-size: 12px;
              margin-left: 10px;
              cursor: pointer;
              img {
                margin-right: 5px;
              }
              &:after {
                content: ">";
                font-size: 14px;
                margin-left: 5px;
                transform: rotate(90deg);
              }
            }
            .dropdown-share-more {
              position: absolute;
              background-color: #fff;
              min-width: 175px;
              border-radius: 6px;
              top: 40px;
              display: none;
              ul {
                padding: 5px 0;
                margin: 0;
                li {
                  list-style: none;
                  display: flex;
                  padding: 5px 10px;
                  font-size: 12px;
                  line-height: 12px;
                  cursor: pointer;
                  align-items: center;
                  span {
                    width: 20px;
                    height: 20px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  }
                  img {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
          .drop-share {
            position: relative;
            .dropdown-share {
              display: flex;
              align-items: center;
              font-size: 12px;
              margin-left: 10px;
              cursor: pointer;
              img {
                margin-right: 5px;
              }
              &:after {
                content: ">";
                font-size: 14px;
                margin-left: 5px;
                transform: rotate(90deg);
              }
            }
            .dropdown-share-more {
              position: absolute;
              background-color: #fff;
              min-width: 170px;
              border-radius: 6px;
              top: 40px;
              display: none;
              ul {
                padding: 5px 0;
                margin: 0;
                li {
                  list-style: none;
                  display: flex;
                  padding: 5px 10px;
                  font-size: 12px;
                  line-height: 12px;
                  cursor: pointer;
                  align-items: center;
                  img {
                    margin-right: 5px;
                  }
                }
              }
            }
            &.open {
              .dropdown-share-more {
                display: block;
              }
              .dropdown-share {
                &:after {
                  content: ">";
                  font-size: 14px;
                  margin-left: 5px;
                  transform: rotate(-90deg);
                }
              }
            }
          }
          ul.share-on {
            padding: 0;
            margin: 0 10px;
            display: flex;
            li {
              list-style: none;
              transition: 0.2s linear;
              img {
                width: 20px;
                margin: 0 3px;
                opacity: 0.5;
                transition: 0.2s linear;
              }
              &:hover {
                img {
                  opacity: 1;
                }
              }
            }
          }
          p {
            margin: 0;
            font-size: 11px;
            line-height: 12px;
          }
          .post-btn {
            background: #e1bf42;
            color: #fff;
            border-radius: 4px;
            display: inline-flex;
            box-shadow: 0px 1px 6px #00000029;
            border: 1px solid #e1bf42;
            padding: 5px 20px;
            font-size: 12px;
            font-weight: 600;
          }
          .disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}
