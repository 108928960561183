.gray-bg {
    background-color: #f2f4f6;
    min-height: calc(100vh - 74px);
    padding: 45px 0;
    .wrapper-setting {
        margin: 0 auto;
        max-width: 1170px;
        padding: 0 15px;
        .billing-subscription-area {
            h2 {
                font-size: 18px;
                font-weight: normal;
            }
             .btn-yellow {
                    background-color: #e1bf42 !important;
                    display: inline-flex;
                    color: #fff;
                    padding: 10px 30px;
                    margin-top: 10px;
                    border: none;
                    border-radius: 20px;
                }
            .non-subscription-area {
                p {
                    margin: 0;
                    font-weight: normal;
                    margin: 4px 0;
                    strong {
                        font-weight: 500;
                    }
                }
            }
            .inner-top-area {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3,
                p {
                    margin: 0;
                    font-weight: normal;
                }
            }
            .subscription {
                display: flex;
                text-align: left;
                margin: 10px 0;
                p {
                    margin: 4px 0;
                    font-weight: normal;
                    font-size: 14px;
                    strong {
                        font-weight: 500;
                    }
                }
                .sub-left {
                    width: 50%;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
            h3 {
                font-size: 14px;
                font-weight: 500px;
                margin: 15px 0;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                tr {
                    background-color: #efefef4f;
                    border-spacing: 0;
                    &:first-child {
                        background-color: #efefef;
                    }
                    td {
                        padding: 15px 20px;
                    }
                }
            }
        }
        .card {
            background: #ffffff;
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 20px;
        }
        .col-lg-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            @media (max-width: 1024px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 35%;
                flex: 0 0 35%;
                max-width: 35%;
            }
            @media (max-width: 767px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-lg-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 65%;
            flex: 0 0 55%;
            max-width: 55%;
            @media (max-width: 768px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 65%;
                flex: 0 0 65%;
                max-width: 65%;
            }
            @media (max-width: 767px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col {
            padding-left: 15px;
            padding-right: 15px;
        }
        .setting-left-card {
            h5 {
                text-align: center;
                margin: 0;
                padding: 0 0 15px 0;
                font-weight: 600;
            }
            ul {
                padding: 0;
                margin: 0 -20px;
                li {
                    list-style: none;
                    .inner-link {
                        min-height: 44px;
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        border-left: 3px solid transparent;
                        img {
                            width: 35px;
                            margin-right: 15px;
                        }
                    }
                    &.active {
                        .inner-link {
                            background: #f7f9fa;
                            border-left: 3px solid #2ac56c;
                            font-weight: 600;
                            color: #202020;
                        }
                    }
                }
            }
        }
        .setting-right-card {
            h1 {
                font-size: 18px;
                font-weight: 600;
                margin-top: 0;
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                    width: 35px;
                }
            }
            p {
                margin-top: 5px;
                font-size: 14px;
            }
            label {
                margin-bottom: 10px;
                display: block;
            }
            input {
                box-shadow: none;
                border: 1px solid #e7e7e7;
                border-radius: 5px;
                background: #fff;
                margin-top: 12px;
                margin-bottom: 19px;
                display: block;
                font-size: 0.875rem;
                margin: 0 0 1rem 0;
                padding: 0.5rem;
                height: 2.3125rem;
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
                min-height: 37px;
            }
            .textcenter {
                text-align: center;
            }
            .btn {
                background-color: #65c08b;
                display: inline-flex;
                color: #fff;
                padding: 10px 50px;
                margin-top: 10px;
                border: none;
                border-radius: 7px;
            }
        }
        .padded {
            padding: 37px 50px 37px 50px;
            @media (max-width: 480px) {
                padding: 25px;
            }
        }
    }
    .onoffswitch {
        position: relative;
        width: 66px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        margin: 0;
    }
    .onoffswitch-checkbox {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        &:checked {
            + {
                .onoffswitch-label {
                    .onoffswitch-inner {
                        margin-left: 0;
                    }
                    .onoffswitch-switch {
                        right: 0px;
                    }
                }
            }
        }
    }
    .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-radius: 20px;
        margin-bottom: 0 !important;
    }
    .onoffswitch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }
    .onoffswitch-switch {
        display: block;
        width: 18px;
        margin: 6px;
        background: #ffffff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 36px;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    .inline-rd {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            width: 70%;
            margin: 0 !important;
        }
    }
    hr {
        margin: 10px 0 !important;
    }
    .action-rd {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .delete-ac {
            font-weight: 600;
        }
    }
}

.delete-acct-feedback-modal {
    background-color: #234155cc !important;
    .modal-wrapper {
        max-width: 525px;
        margin: 15px auto;
        position: relative;
        padding: 0 15px;
        .modal_content {
            max-width: 100%;
            border: 0 !important;
            border-radius: 10px !important;
            position: static;
            padding-bottom: 0 !important;
        }
        .detail-drf-1 {
            word-break: break-word;
            margin-top: 0;
            padding: 0 15px;
        }
        .close-prv {
            position: absolute;
            right: -50%;
            top: 50%;
            background-color: transparent;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            @media (max-width: 768px) {
                right: 0;
                left: 0;
                text-align: center;
                top: -20px;
            }
        }
        .inner-drafts {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            img {
                width: 100%;
                object-fit: contain;
                max-width: 100%;
                max-height: 250px;
                background-color: #000;
            }
        }
        .detail-prv {
            padding: 10px 15px 10px 15px;
            word-break: break-all;
            p {
                margin: 5px 0 0 0;
                font-size: 12px;
                &:first-child {
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }
        .m-20 {
            margin-left: -20px;
            margin-right: -20px;
        }
        .pr-header {
            padding: 15px;
            margin-top: 45px;
            h3 {
                text-align: center;
                color: #fff;
                font-weight: 400;
                margin-top: 10px;
            }
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    padding: 10px 15px;
                    list-style: none;
                    cursor: pointer;
                    opacity: 0.6;
                    font-weight: normal;
                    font-size: 14px;
                    color: #fff;
                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
        .footer-socail {
            padding: 10px 15px;
            background-color: #fff;
            margin-top: 10px;
            border-radius: 10px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .share {
                display: flex;
                align-items: center;
                .drop-share {
                    position: relative;
                    .dropdown-share {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        margin-left: 10px;
                        cursor: pointer;
                        img {
                            margin-right: 5px;
                        }
                        &:after {
                            content: ">";
                            font-size: 14px;
                            margin-left: 5px;
                            transform: rotate(90deg);
                        }
                    }
                    .dropdown-share-more {
                        position: absolute;
                        background-color: #fff;
                        min-width: 175px;
                        border-radius: 6px;
                        top: 40px;
                        display: none;
                        ul {
                            padding: 5px 0;
                            margin: 0;
                            li {
                                list-style: none;
                                display: flex;
                                padding: 5px 10px;
                                font-size: 12px;
                                line-height: 12px;
                                cursor: pointer;
                                align-items: center;
                                span {
                                    width: 20px;
                                    height: 20px;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                img {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
                .drop-share {
                    position: relative;
                    .dropdown-share {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        margin-left: 10px;
                        cursor: pointer;
                        img {
                            margin-right: 5px;
                        }
                        &:after {
                            content: ">";
                            font-size: 14px;
                            margin-left: 5px;
                            transform: rotate(90deg);
                        }
                    }
                    .dropdown-share-more {
                        position: absolute;
                        background-color: #fff;
                        min-width: 170px;
                        border-radius: 6px;
                        top: 40px;
                        display: none;
                        ul {
                            padding: 5px 0;
                            margin: 0;
                            li {
                                list-style: none;
                                display: flex;
                                padding: 5px 10px;
                                font-size: 12px;
                                line-height: 12px;
                                cursor: pointer;
                                align-items: center;
                                img {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    &.open {
                        .dropdown-share-more {
                            display: block;
                        }
                        .dropdown-share {
                            &:after {
                                content: ">";
                                font-size: 14px;
                                margin-left: 5px;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
                ul.share-on {
                    padding: 0;
                    margin: 0 10px;
                    display: flex;
                    li {
                        list-style: none;
                        transition: 0.2s linear;
                        img {
                            width: 20px;
                            margin: 0 3px;
                            opacity: 0.5;
                            transition: 0.2s linear;
                        }
                        &:hover {
                            img {
                                opacity: 1;
                            }
                        }
                    }
                }
                p {
                    margin: 0;
                    font-size: 11px;
                    line-height: 12px;
                }
                .post-btn {
                    background: #e1bf42;
                    color: #fff;
                    border-radius: 4px;
                    display: inline-flex;
                    box-shadow: 0px 1px 6px #00000029;
                    border: 1px solid #e1bf42;
                    padding: 5px 20px;
                    font-size: 12px;
                    font-weight: 600;
                }
                .disable {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
}
.settings-modal {
    .close-prv {
        position: absolute;
        right: -50px;
        height: 24px;
        width: 24px;
        background-color: #fff;
        display: block;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        line-height: 12px;
        cursor: pointer;
        @media (max-width: 768px) {
            right: 10px;
            top: 10px;
        }
    }
    .modal_content {
        border: 0;
        border-radius: 10px;
        max-width: 460px;
    }
    .action-btn-rd {
        margin: 20px 0;
        margin-bottom: 0px;
        .btn-rd {
            padding: 8px 10px;
            border-radius: 30px;
            border: 0;
            min-width: 140px;
            margin: 10px;
            background-color: transparent;
            border: 1px solid #234155;
            color: #234155;
        }
        .btn-rd.active {
            background-color: red;
            color: #fff;
            border-color: red;
        }
    }
    font-size: 14px;
    .text-align-center {
        text-align: center;
    }
    .center-text {
        text-align: center;
    }
    h3 {
        margin: 0;
        font-size: 22px;
    }
    p {
        margin: 0;
        text-align: left;
    }
    textarea {
        width: 100%;
        min-height: 200px;
        margin: 10px 0;
        border: 1px solid #ccc;
        border-radius: 10px;
    }
}

.setting-on {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    content: "ON";
    padding-left: 10px;
    background-color: #2ac56c;
    color: #ffffff;
}

.setting-off {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    content: "OFF";
    padding-right: 10px;
    background-color: #eeeeee;
    color: #999999;
    text-align: right;
}
