.register-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    min-height: 60px;
    @media (max-width: 766px) {
            padding: 0;
        }
    .logo-area {
		position: relative;
		font-size: 32px;
		font-weight: 600;
		display: flex;
		align-items: center;
		letter-spacing: -3px;
		color: #2F5266;
		img {
			height: 100%;
            max-height: 64px;
            width: 60px;
			object-fit: contain;
			object-position: left;
		}
	}
}
.login-page {
    background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
    background-color: #234155;
    background-size: 100%;
    background-position: bottom;
    min-height: 100vh !important;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    font-size: 14px;
    overflow-y: auto;
    .login-wrapper {
        margin: 0 auto;
        width: 100%;
        .box {
            background-color:transparent;
            text-align: center;
            padding: 30px 20px;
            color: #fff;
            width: 100%;
            h1 {
                font-size: 16px;
            }
            .inner-box {
                max-width: 300px;
                padding: 0 15px;
                margin: auto;
                .field {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-bottom: 20px;
                    position: relative;
                    label {
                        margin-bottom: 4px;
                    }
                    input {
                        background: #F2F2F2;
                        border-radius: 5px;
                        padding: 6px 10px 4px 10px;
                        height: 34px;
                        outline: none;
                        border: 0
                    }
                    input[type=password] {
                        padding-right: 40px !important;
                    }
                    .eye {
                        background: url('https://d310ox1f4zno4b.cloudfront.net/static-content/open-eye.png.webp');
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: absolute;
                        top: 32px;
                        right: 15px;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    .fa-eye-slash {
                        background: url('https://d310ox1f4zno4b.cloudfront.net/static-content/hide.png.webp');
                        background-size: 100%;
                    }
                    
                }
                .field-check {
                    display: flex;
                    align-items: center;
                    input {
                        margin-right: 10px;
                    }
                }
                .login-btn {
                    background-color: #E1BF42;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 25px 0 10px 0;
                    height: 34px;
                    border-radius: 40px;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    } 
                }
                .login-btn.disabled {
                    opacity: 0.8;
                    pointer-events: none;
                }
                .action {
                    display: flex;
                    flex-direction: column;
                    a {
                        padding: 5px;
                    }
                }
            }
        }
    }
}
