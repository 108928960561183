
.classes-block {
    .col-md-3a {
        flex: 0 0 25% ;
        max-width: 25% ;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
    }
    .col-md-6 {
        flex: 0 0 80% !important;
        max-width: 80% !important;
    }
    .card-vertical {
        position: relative;
        height: calc(100% - 20px);
        .close {
            position: absolute;
            right: 15px;
            height: 25px;
            width: 25px;
            top: 15px;
            border-radius: 50%;
            transition: .3s linear;
            &:before, 
            &:after {
                left: 11px;
                top: 6px;
            }
            &:hover {
                background-color:#2E5266;
                &:before, 
                &:after {
                    background-color: #fff;
                }
            }
        }
        .st-in.st-left,
        .st-list {
            flex-direction: column;
            align-items: center;
            text-align: center;
            label {
                margin-top: 20px;
            }
        }
        .st-in.st-right {
            flex-direction: column;
            display: flex;
            margin-top: 10px;
            a {
                font-weight: 600;
                margin-top: 10px;
                cursor: pointer;
                padding: 6px 24px;
                border-radius: 30px;
                -moz-transition: .3s linear;
                -webkit-transition: .3s ease-out;
                transition: .3s linear;
                // @media (max-width: 480px) {
                //     padding: 6px 0px;
                // }
                &:hover {
                    background-color: #2E5266;
                    color: #fff;
                }
                img {
                    margin-right: 7px;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 0 15px;
        padding-top: 0;

        h1 {
            text-align: center;
            margin-bottom: 0 !important;
        }
        .col.col-md-6.col-sm-12 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .conection-list {
            display: flex !important;
            margin-bottom: 15px;
            ul {
                max-width: 100%;
                display: flex;
            }
        }
        .my-conection-sub {
            display: none !important;
        }
    }
    @media (max-width: 768px) {
        .col-md-3a {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
        .card {
            border-radius: 4px !important;
        }
    }
    @media (max-width: 767px) {
        padding: 0;
        
        .row {
            margin-left: -5px !important;
            margin-right: -5px !important;
        }
        .col-md-3a {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 5px !important;
            padding-right: 5px !important;
            .card {
                margin-bottom: 0px !important;
                height: 100%;
            }
        }
    }
    
}
.classes-page {
    .st-application {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        label {
            font-weight: 600;
            font-size: 18px;
            margin: 15px 0;
        }
    }
    .my-conection {
        .card {
            padding: 0 !important;
            overflow: hidden;
        }
    }
    .col-md-8 {
        flex: 0 0 66%;
        max-width: 66%;
    }
    .margin-auto {
        margin: auto;
        h1 {
            text-align: center;
        }
        .my-conection {
            padding-left: 0 !important;
        }
    }
    .col-md-3a {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
    }
    .my-conection {
        h1 {
            margin-bottom: 20px;
        }
    }
    .conection-list {
        margin-top: 55px;
        ul {
            max-width: 190px !important;
            padding: 0;
            list-style: none;
        }
        //  &.my-classes {
        //     ul {
        //         max-width: 210px !important;
        //     }   
        // }
    }
    .mr-2 {
        margin-right: 7px;
    }
    .class-image {
        background-color: #ccc;
        width: 100%;
        height: 125px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .class-body {
        padding: 15px;
        text-align: left;
        label {
            font-size: 12px !important;
            margin: 0 !important;
            min-height: 36px;
        }
        p {
            font-size: 12px;
            margin: 5px 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .btn-in {
            border-radius: 3px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            padding: 8px;
            margin-top: 10px;
            .hidden {
                display: none;
            }
        }
        .btn-green {
            background: #65C08B;
        }
        .btn-yellow {
            background: #e1bf42;
        }
    }
    @media (max-width: 1024px) {
        h1 {
            text-align: center;
            margin-bottom: 0 !important;
        }
        .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .conection-list.mobile-only {
            display: block;
            margin-top: 0;
            ul {
                max-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                margin-bottom: 15px;
                li {
                    opacity: 0.3;
                    a {
                        margin: 0;
                        padding: 5px;
                        font-weight: 600;
                    }
                }
                li.selected {
                    opacity: 1;
                }
                
            }
            .library-btns {
                width: 100% !important;
                max-width: 100% !important;
            }
        }
    }
    @media (max-width: 767px) {
        
        .col-md-3a,
        .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .card {
            border-radius: 6px !important;
        }
    }
}
