.page-close {
    display: none;
}
@media (max-width: 480px) {
    .page-close {
        position: fixed !important;
        right: 15px;
        height: 25px;
        width: 25px;
        top: 15px;
        border-radius: 50%;
        transition: .3s linear;
        background-color:#2E5266;
        background-color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:before {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 16px;
            width: 2px;
            background-color: #2F5266;
            border-radius: 4px;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 16px;
            border-radius: 4px;
            width: 2px;
            background-color: #2F5266;
            transform: rotate(-45deg);
        }
    }
}