.donation-page {
	.byan-bg-img {
		position: relative;
		display: flex;
		align-items: center;
		max-height: 300px;
		overflow: hidden;
		min-height: 300px;
		img {
			height: 100%;
			object-fit: cover;
			@media only screen and (min-width: 767px) {
				width: 100%;
			}
		}
		.text-holder {
			position: absolute;
			top: 0;
			color: #fff;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin: auto;
			padding: 15px;
			background-color: #0000003b;
			h1 {
				font-size: 34px;
				font-weight: 600;
				text-transform: capitalize;
				margin: 0;
			}
			p {
				margin: 0;
			}
		}
	}
	.wrapper-data {
		max-width: 1140px;
		margin: 0 auto;
		.main-data-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			border-top: 1px solid #eee;
			// margin-top: 20px;
			padding-top: 25px;
			padding-bottom: 30px;
			flex-wrap: wrap;
			p {
				margin: 5px 0;
				font-size: 14px;
				a {
					text-decoration: underline;
					color: #e1bf42;
				}
			}
			h1 {
				font-size: 24px;
				margin-top: 0;
			}
			h3 {
				margin: 0;
				font-size: 16px;
				font-weight: 500;
			}
			.seprator {
				display: flex;
				height: 1px;
				background-color: rgb(217, 217, 217);
				margin: 15px 0;
			}
			.right-content {
					display: flex;
					justify-content: space-between;
				}
			.right-content > span {
				margin-left: -25px;
				margin-top: -20px;
				display: flex;
				// align-items: center;
				// justify-content: center;
				img {
					min-width: 300px;
				}
			}
			.donation-form .section.recurring {
				padding-left: 0 !important;
			}
			.checkbox .label,
			.radio .label {
				margin-left: 10px;
			}
			.checkbox {
				margin-bottom: 10px;
			}
			textarea,
			select,
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="text"] {
				width: 100%;
				max-width: 500px;
				border: 1px solid #ccc;
				border-radius: 10px;
				padding: 7px 10px;
				margin: 5px 0 5px 0;
			}
			select {
				max-width: 478px;
			}
			.btn-submit-donation {
				border: 1px solid #2f5266;
				padding: 10px;
				border-radius: 20px;
				text-align: center;
				display: inline-block;
				min-width: 150px;
				transition: 0.3s linear;
				background-color: #fff;
				color: rgb(46, 82, 102);
				margin-top: 15px;
				&:hover {
					background-color: #e1bf42;
					border-color: transparent;
					color: #fff !important;
					text-decoration: none;
				}
			}
		}
		.col-content {
			position: relative;
			width: 100%;
			padding-right: 15px;
			padding-left: 15px;
		}
		.left-data-content {
			flex: 0 0 65%;
			max-width: 65%;
			@media only screen and (max-width: 767px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
			#donation-content {
				text-align: center;
				margin: 30px 0;
				#support-btn {
					color: rgb(46, 82, 102);
					border-radius: 5px;
					border-color: #fff;
					width: 220px;
					height: 50px;
					background-color: #e1bf42;
					opacity: 1;
					display: inline-block;
					text-align: center;
					padding: 13px;
					cursor: pointer;
				}
				// .loader-img {
				// 	img {
				// 		display: block;
				// 		margin: 20px auto 0 auto;
				// 	}
				// }
			}
		}
		.right-data-content {
			flex: 0 0 35%;
			// max-width: 35%;
			max-width: 65%;
			.questions-div {
				  border-bottom: 1px solid rgb(217, 217, 217);
			}
			@media only screen and (max-width: 767px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		.more-ways {
			margin-top: 10px;
			// .check-donation {
			// 	border-bottom: 1px solid rgb(217, 217, 217);
			// }
		}

		@media only screen and (max-width: 767px) {
			.right-content {
					flex-direction: column;
					align-items: flex-start;
					#donation-content {
						margin: 0px 0 30px 0;
					}
				}
		}
	}
}
