.profile-block {
  font-size: 14px;
  .userPostBlock {
    padding-bottom: 0px !important;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      padding-bottom: 10px !important;
    }
  }
  .profile-banner {
    background-color: #23415573;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    -moz-transition: 0.3s linear;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s linear;
    .cover-img {
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      align-items: stretch;
      // display: none;
    }
    .userimg-cover {
      border: 1px dashed #ffffff;
      border-radius: 24px;
      width: 100%;
      padding: 10px;
      position: absolute;
      color: #fff;
      max-width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      -moz-transition: 0.3s linear;
      -webkit-transition: 0.3s ease-out;
      transition: 0.3s linear;
      background-color: #00000029;
      img {
        margin: 0 10px;
      }
      input {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
    // &:hover {
    //     .userimg-cover {
    //         opacity: 1;
    //     }
    // }
  }
  .user-application {
    padding: 10px;
    background-color: #fff;
    @media only screen and (max-width: 767px) {
      padding: 20px 0;
      // background-color: #fff;
      margin-top: -260px;
      background-color: #23415573 !important;
    }
    .main-app {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-yellow {
        margin-left: 15px;
      }
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        .user-img {
          display: flex;
          margin: 0 !important;
          flex-direction: column;
          width: 100%;
          .ml-15 {
            margin-left: 0 !important;
            width: 100%;
            text-align: center;
            background-color: #f2f2f2;
            width: calc(100% + 30px);
            margin-top: 20px;
            padding-bottom: 10px;
          }
          h2 {
            margin: 10px !important;
            font-size: 16px !important;
            color: #2e5266;
          }
        }
        .btn {
          min-width: 130px;
        }
        .edit-mobile-des {
          background-color: #f2f2f2;
          flex: 1;
          display: flex;
          flex-direction: column;
          width: calc(100% + 30px);
          padding: 0px;
          margin-bottom: -20px;
          padding: 10px 0;
          .btn {
            margin: 5px auto !important;
            max-width: 200px;
            width: 100%;
          }
        }
      }
      .user-img {
        display: inline-flex;
        align-items: center;
        margin: -25px 0;
        .ml-15 {
          margin-left: 15px;
        }
        .profileImg,
        .userimg {
          height: 100px;
          min-width: 100px;
          max-width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          .userimg-cover {
            z-index: 1000;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #00000057;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            -moz-transition: 0.3s linear;
            -webkit-transition: 0.3s ease-out;
            transition: 0.3s linear;
            // &:hover {
            //     opacity: 1;
            // }
            input {
              position: absolute;
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            max-width: 100%;
          }
        }
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .about-card {
    margin-right: 50px;
    position: relative;
    .edit-btn {
      position: absolute;
      right: 15px;
      background: url(/icons/pencil.svg) no-repeat;
      background-size: 16px;
      background-position: center;
      background-color: #e8ebed;
      border: 0;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-indent: -9999px;
      opacity: 0;
      -moz-transition: 0.3s linear;
      -webkit-transition: 0.3s ease-out;
      transition: 0.3s linear;
    }
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
    p {
      margin: 10px 0;
      min-height: 140px;
    }
    // &:hover {
    //     .edit-btn {
    //         opacity: 1;
    //     }
    // }
    @media only screen and (max-width: 768px) {
      margin-right: 0;
      .edit-btn {
        opacity: 1;
        background-color: transparent !important;
        background-size: 12px;
        right: 7px;
        top: 7px;
      }

      p {
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .publications {
        height: 40px !important;
        background-color: transparent !important;
        border: 0 !important;
      }
      .btn {
        min-width: 120px !important;
        margin-top: 10px;
      }
    }
  }
  .publications-card {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: center;
    // .btn {
    //   // margin-top: 20px;
    // }
    .publications {
      align-items: center !important;
      justify-content: center !important;
      text-align: left;
      background: #e4e4e469;
      height: 300px;
      margin-top: 10px;
      display: block;
      align-items: center;
      justify-content: center;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      width: 90%;
      text-align: center;

      .loader {
        margin-top: 90px;
        height: 90px;
        max-width: 90px;
      }
      .btn {
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .row-card {
        .inner-drafts {
          img {
            width: 90%;
            min-height: 295px;
            min-width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .pub-slider {
      height: auto;
      width: 100%;
      .slick-prev {
        left: 10px;
      } 
      .slick-next {
        right: 10px;
      } 
      .inner-drafts {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          background-color: #000;
        }
      }
    }
  }
  .yourself-modal.about-modal {
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin-top: 0;
    }
    .detail-int {
      background: rgb(243, 243, 243);
      border-radius: 23px;
      border: 0px;
      outline: none;
      padding: 10px 20px;
      min-width: 160px;
      flex: 1 1 0%;
      display: inline-block;
      width: 100%;
      font-size: 14px;
      min-height: 150px;
    }
    .connect-btn {
      border: 1px solid #e1bf42;
      color: #fff !important;
      background-color: #e1bf42;
      display: inline-flex;
      padding: 6px;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      min-width: 150px;
      font-size: 13px;
      transition: all 0.3s linear 0s;
    }
  }
  @media only screen and (max-width: 767px) {
    .col-md-5,
    .col-md-7 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .mt-3 {
      margin-top: 0 !important;
    }
    .col-md-5 {
      display: flex;
      .about-card {
        margin-right: 3%;
      }
      .card {
        flex: 0 0 48.5% !important;
        max-width: 48.5% !important;
        margin-bottom: 0 !important;
      }
    }
    .mt-3.mobile-profile {
        .col-md-5 {
          flex-direction: column;
        }
        .publications-card {
              // margin-bottom: 100px !important;
              .pub-slider {
                height: auto !important;
              }
        }
      }
  }
  @media only screen and (max-width: 480px) {
    .col-md-5 {
      .card {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 30px !important;
      }
    }
  }
}

//temp changes

.row-card {
  margin-left: 80px;
}
