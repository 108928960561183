.bayan-media {
  padding: 40px 0;
  //   @media screen and (max-width: 767px) {
  //     .media-container {
  //       .slick-dots {
  //         li {
  //           button {
  //             display: flex;
  //           }
  //         }
  //       }
  //       position: relative;
  //       .byan-bg-img {
  //         min-height: 600px;
  //         max-height: 600px;
  //         height: 600px;
  //         align-items: flex-start;
  //         border-bottom-left-radius: 35px;
  //         border-bottom-right-radius: 35px;
  //         img.desk-img {
  //           display: none;
  //         }
  //         img.mob-img {
  //           display: block;
  //           height: 100vh;
  //         }
  //         .text-holder {
  //           top: 0;
  //           height: 80%;
  //           .text-container {
  //             margin: 0 15px 0;
  //             height: 83%;
  //             p {
  //               text-align: center;
  //               font-size: 14px;
  //               margin-bottom: 40%;
  //             }
  //             h1 {
  //               font-size: 30px;
  //               line-height: 40px;
  //             }
  //             h2 {
  //               font-size: 18px;
  //             }
  //           }
  //           .button-container {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             width: 100%;
  //             .learn-more-btn {
  //               margin: 0;
  //             }
  //           }
  //         }
  //       }
  //       .switch-button {
  //         left: 0;
  //         top: 0;
  //         bottom: 5%;
  //         flex-direction: row;
  //         align-items: flex-end;
  //         justify-content: center;
  //         .custom-dot {
  //           height: 22px;
  //           width: 22px;
  //         }
  //       }
  //     }
  //   }

  .media-container {
    max-width: 1140px;
    margin: 0 auto;
    width: 100%;

    position: relative;
    .slick-arrow {
      display: none !important;
    }
    .slick-dots {
      //   position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      list-style: none;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin: 0 20px;
      li {
        button {
          background: #002f4f;
          border: 1px solid #ffffff;
          opacity: 0.55;
          height: 16px;
          max-height: 16px;
          width: 16px;
          max-width: 16px;
          display: inline-block;
          text-indent: -9999px;
          border-radius: 20px;
          margin: 5px;
          outline: none !important;
          cursor: pointer;
        }
      }
      .slick-active {
        button {
          background: #ffffff;
          opacity: 1;
        }
      }
    }
    .webinar-banner {
      p {
        color: #fff;
      }
      .text-holder {
        top: 5% !important;
      }
      @media screen and (max-width: 768px) {
        .text-holder {
          top: 0 !important;
        }
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        .text-container {
          height: auto !important;
        }
        h1 {
          span {
            margin-bottom: 20px;
            display: block;
          }
          br {
            display: none;
          }
        }
        h2 {
          margin: 15px 0;
          font-size: 16px !important;
          padding: 0 30px;
        }
        .text-holder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100% !important;
        }
        .bayan-button {
          margin: 20px 0;
        }
      }
    }
    .byan-bg-img {
      display: none;
      position: relative;
      min-height: 440px;
      max-height: 440px;
      height: 440px;
      width: 100%;
      // background-color: rgba(46, 82, 102, 0.5);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-fit: scale-down;
      }
      img.mob-img {
        display: none;
      }
      &.active {
        display: flex;
      }
      .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        @media screen and (max-width: 480px) {
          height: auto;
          bottom: 0;
        }
        &.layer-1 {
          background-color: rgba(46, 82, 102, 0.3);
        }
        &.layer-2 {
          // background-color: rgba(171, 161, 150, 0.1);
          background-color: rgba(46, 82, 102, 0.5);
        }
        &.webinar-layer {
          // background-color: rgba(171, 161, 150, 0.1);
          background-color: rgba(46, 82, 102, 0.75);
        }
        &.layer-3 {
          background-color: rgba(117, 150, 154, 0.3);
        }
        &.layer-4 {
          background-color: rgba(48, 51, 54, 0.3);
        }
        &.layer-5 {
          background-color: rgba(151, 120, 87, 0.3);
        }
      }
      .text-holder {
        position: absolute;
        top: 17%;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 880px;
        padding: 10px;
        .text-container {
          //   margin: 25px 0;
          p {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 1px;
            font-weight: 300;
            color: #fff;
          }
          h1 {
            font-size: 42px;
            font-weight: 500;
            line-height: 55px;
            color: #ffffff;
          }
          h2 {
            font-size: 20px;
            line-height: 32px;
            text-transform: capitalize;
            color: #ffffff;
          }
        }
        .button-container {
          .apply-btn {
            width: 199px;
            height: 45px;
            font-size: 16px;
          }
          .learn-more-btn {
            width: 199px;
            height: 45px;
            font-size: 16px;
            margin-left: 15px;
          }
          .bayan-support-btn {
            width: 250px;
            height: 50px;
            font-size: 16px;
          }
        }
      }
    }
    .switch-button {
      position: absolute;
      left: 95%;
      top: 35%;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      .custom-dot {
        height: 18px;
        width: 18px;
        background-color: #002f4f;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid #c0c0c0;
        margin: 5px;
        cursor: pointer;
        &.active {
          background-color: #ffffff !important;
        }
      }
    }
  }
  .inner-media {
    display: flex;
    max-width: 960px;
    margin: 40px auto;
    @media only screen and (max-width: 769px) {
      flex-direction: column;
      .right-detail,
      span {
        width: 100% !important;
      }
      .right-detail {
        height: auto !important;
      }
      .button-container {
        display: inherit !important;
        .left {
          .bayan-button {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    &.blue-text {
      color: #2e5266 !important;
    }
    span {
      background: #707070;
      border: 1px solid #707070;
      border-radius: 10px;
      min-width: 300px;
      height: 382px;
      // height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      position: relative;
      overflow: hidden;
      @media (max-width: 780px) {
        .podcast-image {
          display: flex;
          justify-content: center;
        }
      }
      .podcast-image {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-around;
        @media (max-width: 780px) {
          flex-direction: column;
          img {
            margin-bottom: 20px;
          }
        }
        @media (max-width: 480px) {
          flex-direction: column;
        }
        img {
          width: 185px;
          border-radius: 10px;
          @media (max-width: 480px) {
            width: 130px;
            margin: 0 10px;
            margin-bottom: 15px;
          }
        }
      }
    }
    h3 {
      opacity: 1;
      font-size: 25px;
      line-height: 35px;
      font-weight: 500;
      margin: 10px 0;
    }
    p {
      font-size: 20px;
      letter-spacing: 0px;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 0;
    .headingF {
      font-size: 27px !important;
    }
    .inner-media {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0;
      }
      p {
        display: none;
      }
    }
  }
}
.bayan-media-blue {
  background-color: #2f5266;
  h1 {
    color: #fff;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      font-size: 20px;
      font-weight: 400;
      margin: 0 10px;
      display: inline-flex;
      align-items: center;
      &:before {
        content: "";
        border-width: 0 2px 2px 0;
        transform: rotate(135deg);
        height: 10px;
        width: 10px;
        display: inline-block;
        border-color: #fff;
        border-style: solid;
        margin-right: 5px;
      }
    }
  }
  h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .inner-media {
    &.blue-text {
      color: #2e5266 !important;
    }
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    // height: auto;
    min-height: 300px;
    align-items: center;
    span {
      border: 0;
      width: 50%;
      border-radius: 0;
      // height: 100%;
      height: 370px;
      margin: 0;
    }
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    .mob-img {
      height: 100% !important;
      width: 100% !important;
      max-width: 100%;
      object-fit: cover;
    }
    p {
      font-size: 16px;
    }
    .right-detail {
      width: 50%;
      padding: 30px 50px;
      .left {
        grid-area: left;
      }
      .right {
        grid-area: right;
      }
      .button-container {
        display: grid;
        grid-template-areas: "left right";
        column-gap: 20px;
        padding: 5px;
        .left {
          margin-top: 20px;
        }
        .bayan-button {
          background-color: #e1bf42;
          height: 45px;
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  #social-media {
    max-width: 1024px;
    margin: 0 auto;
    .inner-sm {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      .iner-detail {
        padding: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        p {
          color: #333333;
        }
      }
      .socail-icon {
        margin-top: 60px;
        flex: 1;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
        img {
          width: 30px;
        }
      }
    }
  }
  .bayan-claremount-b {
    margin: 200px 0 100px 0;
    h2 {
      margin-bottom: 0;
    }
    p {
      color: #fff;
    }
  }
}
.mobile-event {
  @media only screen and (max-width: 767px) {
    background-color: #e4e4e4;
    padding: 10px 0 0 0 !important;
    .media-container {
      padding: 0;
      background-color: transparent !important;
    }
    h1 {
      flex-direction: column;
      color: #2f5266;
      a {
        &:before {
          border-color: #2f5266;
        }
      }
    }
    h2 {
      color: #2f5266;
      margin: 25px 0;
    }
    // padding: 20px 0;
    .headingF {
      font-size: 27px !important;
    }
    .inner-media {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      text-align: left;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      p {
        display: block;
      }
      .right-detail,
      span {
        width: 100%;
        height: 260px;
      }
      .right-detail {
        padding: 15px;
      }
    }
    .col-3 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px !important;
      min-height: 400px;
      .inner-sm {
        border-radius: 0 !important;
      }
    }
    .bayan-claremount-b {
      display: none;
    }
  }
}
