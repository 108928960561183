.terms-page {
    min-height: calc(100vh - 80px);
    background-color: #F2F2F2;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    .termscard {
        background-color: #fff;
        padding: 20px;
        margin-top: 20px;
        border-radius: 6px;
        .terms-heading {
            font-size: 18px;
            font-weight: 600;
            border-bottom: 1px solid #ddd;
            padding-bottom: 12px;
            margin-bottom: 12px;
        }
        h2 {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 8px;
        }
        p {
            margin: 10px 0;
        }
        ol {
            padding-left: 15px;
            margin: 0;
            li {
                margin: 8px 0;
            }
        }
    }
}