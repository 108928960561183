// #react-joyride-step-0 > div {
//   display: inline-block !important;
//   max-width: 800px;
//   left: 10px !important;
//   top: 17% !important;
//   right: 10px !important;
//   margin: auto !important;
// }
.react-joyride__tooltip {
  h4 {
    text-align: left !important;
    padding: 0 10px !important;
    font-weight: bold !important;
  }
  div {
    text-align: left !important;
  }
  button {
    font-weight: bold !important;
  }
}

.ctt-wrapper {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 500px;
  display: flex;
  .animate-down{
    animation: fadeInDown .5s ease-in both;
  }
  .animate-up{
    animation: fadeUpIn .5s ease-in both;
  }
  .animate-left{
    animation: fadeInLeft .5s ease-in both;
  }
  .animate-right{
    animation: fadeInRight .5s ease-in both;
  }
  @keyframes fadeInLeft {
   from {
     opacity: 0;
     transform: translateX(-20px);
   }
   to {
     opacity: 1;
     transform: translateX(0);
   }
  }  
  @keyframes fadeInRight {
   from {
    opacity: 0;
    transform: translateX(20px);
   }
   to {
    opacity: 1;
    transform: translateX(0);
   }
  }  
  @keyframes fadeInDown {
    from {
     transform: translate3d(0, -100%, 0);
    }
    to {
     transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeUpIn {
    from {
     transform: translate3d(0, 100%, 0);
    }
    to {
     transform: translate3d(0, 0, 0);
    }
  }
  .ctt-left-sec {
    width: 365px;
    background: #F1F1F1;
    border-radius: 8px 0 0 8px;
    padding-top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      width: 250px;
    }
    /*height: 100%;*/
    img {
      //width: 300px;
      /*height: 300px;*/
    }
  }
  .ctt-right-sec {
    width: 50%;
    padding: 20px 30px;
    .steps-progress {
      text-align: center;
      margin-bottom: 100px;
      margin-top: 15px;
      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
      .cttp-steps {
        cursor: pointer;
        margin-right: 10px;
        background-color: #DADADA;
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
      }
      .cttp-steps-a {
        background-color: #2F5266!important;
      }
    }
    .content-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .content-desc {
      margin-bottom: 100px;
    }
    .ctt-next-wrapper {
      text-align: center;
      position: absolute;
      bottom: 50px;
      right: 70px;
      .step-btn {
        display: inline-block;
        background: #2F5266;
        color: #fff;
        border-radius: 20px;
        padding: 4px 40px;
        cursor: pointer;
        font-weight: bold;
      }
      .back-btn {
        background-color: #2F5266!important;
        margin-right: 12px;
      }
    }
  }
}
.plexuss-tutorials-btn {
  display: inline-block;
  position: absolute;
  right: 23%;
  margin-top: 45px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 100%;
  background: #fff;
  color: #000;
  z-index: 1;
  &:hover {
    background: #000;
    color: #fff;
  }
}
.plexuss-tutorials-banner {
  background: #000;
  color: #fff;
  width: 380px;
  position: absolute;
  padding: 15px 24px;
  right: 23%;
  margin-top: 110px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 10px 30px #00000029;

  .pt-banner-label {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .pt-banner-desc {
    font-size: 16px;
    margin-bottom: 20px; 
  }
  .pt-banner-btns-wrapper {
    margin-bottom: 20px;
    .pt-watch-video {
      display: inline-block;
      background: #2F5266;
      padding: 6px 18px;
      border-radius: 30px;
      font-weight: bold;
      cursor: pointer;
    }
    .pt-start-tour {
      display: inline-block;
      background: #2F5266;
      padding: 6px 18px;
      border-radius: 30px;
      font-weight: bold;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}
.social_dashbord_banner{
  width: 80%;
  margin-top: 60px;
  .gif-loader-cont{
    position: absolute;
    left: 52%;
    top: 50%;
    img{
      width: 55px;
    }
  }
  .post-loader{
    left: 44%;
    top: 50%;
    position: relative;
    img{
      width: 55px;
    }
  }
  .gif-loader{
    left: 44%;
    position: relative;
    img{
      width: 55px;
    }
  }
}
.add-cllgs-empty-state{
  width: 100%;
  text-align: center;
  display: flex;
  margin-bottom: 50px;
}

@media all and(max-width: 1300px){
  .profile_parent{
    margin-left: 0 !important;
  }
  .custom-row #main-content{
    width: 100%;
  }
}
@media (max-width: 1023px) and (min-width: 678px){
  .custom-row {
    padding: 0;
  }
  .profile_parent{
    margin-left: 5px;
  }
  .social_dashbord_banner{
    .gif-loader-cont{
    position: absolute;
    left: 48%;
    top: 33%;
    }
  }
}

@media screen and (max-width: 1000px){
  .skill-modal-wrapper{
    padding: 15px;
    .skill-modal-header{
      padding: 0px;
      margin: 5px 0 5px 50px;
      p{
        margin: 0;
      }
    }
    .skill-modal-body{
      padding: 25px 5px;
    }
  }

  .slick-dots{
    li{
      width: 10px!important;
      height: 35px!important;
      margin: 5px;
    }
  }

}


@media screen and (max-width: 767px){
  .mbl_none {
    display: none;
  }
  .__floater__open {
    background: #fff;
    border-radius: 10px;
  }
  .edit-college-container{
    .button-container{
      float: none;
      margin-top: 15px;
    }
  }
  .ctt-wrapper {
    display: block;
    text-align: center;
    min-width: 320px;
    max-width: 700px;
    .ctt-left-sec {
      width: 100%;
      background: #fff;
      padding: 16px 6px 0;
      border-radius: 8px 8px 0 0;
      text-align: center;
      img {
        max-height: 185px;
      }
    }
    .ctt-right-sec {
      width: 100%;
      padding: 2px 15px 30px;
      .animated{
        opacity: 1!important;
      }
      .steps-progress {
        margin-bottom: 10px;
        .cttp-steps {
          font-size: 12px;
        }
      }
      .content-desc {
        margin-bottom: 30px;
        font-size: 14px;
      }
      .ctt-next-wrapper {
        right: 0px;
        left: 0px;
        bottom: 20px;
        .step-btn {
          position: unset;
          display: inline-block;
        }
      }
    }
  }
  .social_dashbord_banner{
    width: 100%;
    margin: 0;
    margin-top: 40px;
    .gif-loader-cont{
    position: absolute;
    left: 44%;
    top: 33%;
    }
  }
  .plexuss-tutorials-btn {
    right: 6%;
  }
  .plexuss-tutorials-banner {
    background: #000;
    color: #fff;
    width: 94%;
    position: absolute;
    padding: 15px 24px;
    right: unset;
    margin: 0 3%;
    margin-top: 92px;
    font-size: 13px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 10px 30px #00000029;
    .pt-banner-desc {
      font-size: 13px;
    }
  }
  .custom-row{
    padding: 0;
    margin: 0;
    #main-content{
      padding: 0;
      float: none;
      margin: 0 auto;
      overflow-x: hidden;
      // overflow-y: scroll;
      // ::-webkit-scrollbar {
      //   width: 0px;
      //   background: transparent;
      // }
      // -webkit-overflow-scrolling: touch;
    }
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}
