.admin-header .wrapper .inner-header {
  .menu {
    margin-right: 20px;
    // margin-right: 0;
    img {
      width: 30px;
    }
    position: relative;
    font-size: 20px;
    font-weight: 400;
    align-items: center;
    letter-spacing: -2px;
    color: #2f5266;
  }
}

.left-side-menu {
  .menu-body {
    .menu {
      margin-right: 0 !important;
      #donation-content {
        text-align: center;
        margin: 25px 0;
        margin-bottom: 0;
        #support-btn {
          border-radius: 50px;
          border-color: #fff;
          width: 220px;
          height: 43px;
          background-color: #e1bf42;
          opacity: 1;
          display: inline-block;
          text-align: center;
          padding: 13px;
          cursor: pointer;
          width: 93%;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}
