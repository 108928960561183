.bayan-approach {
    padding: 45px 0;
    .heading {
        font-size: 40px;
        font-weight: 600;
        color: #2F5266;
    }
    .body-text {
        color: #2F5266;
        max-width: 960px;
        margin: auto;
    }
    .btn-container {
        text-align: center;
        a {
            background-color: #E1BF42;
            color: #fff !important;
            display: inline-block;
            padding: 12px 30px;
            border-radius: 40px;
            font-weight: 500;
            margin: 15px 0;
        }
    }
}