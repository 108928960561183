.footer-nav {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    right: 0;
    left: 0;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.14);
    z-index: 555;
    ul {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            flex: 1;
            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                padding: 15px 10px;
                max-width: 50px;
                margin: 0 auto;
                .navimg {
                    width: 40px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0.5;
                    margin-bottom: 7px;
                    .msg-unread-count {
                        position: absolute;
                        margin-top: -14px;
                        margin-left: 32px;
                        background: #EF5350;
                        color: #fff;
                        font-size: 10px;
                        border-radius: 50%;
                        padding: 2px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                    }
                }
                span.text {
                    opacity: 0;
                }
            }
            &:nth-child(4),
            &:nth-child(2) {
                .navimg {
                    img {
                        position: relative;
                        top: 3px;
                    }
                }
            }
            &:last-child {
                .navimg {
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    opacity: 0.6;
                    margin-bottom: 5px;
                    position: relative;
                    top: 3px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        li.active {
            .text,
            .navimg {
                opacity: 1;
            }
        }
    }
    @media (min-width: 1025px) {
        display: none;
    }
}
