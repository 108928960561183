.register-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    min-height: 60px;
    @media (max-width: 766px) {
            padding: 0;
        }
    .logo-area {
		position: relative;
		font-size: 32px;
		font-weight: 600;
		display: flex;
		align-items: center;
		letter-spacing: -3px;
		color: #2F5266;
		img {
			height: 100%;
            max-height: 64px;
            width: 60px;
			object-fit: contain;
			object-position: left;
		}
	}
}
.login-page {
    background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
    background-color: #234155;
    background-size: 100%;
    background-position: bottom;
    min-height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow-y: auto;
    .login-wrapper {
        margin: 0 auto;
        width: 100%;
        .box {
            background-color:transparent;
            text-align: center;
            padding: 30px 20px;
            color: #fff;
            width: 100%;
            p {
                max-width: 600px;
                margin: auto;
            }
            h1 {
                font-size: 18px;
                display: block !important;
            }
            // .back {
            //     content: "";
            //     border-width: 0 2px 2px 0;
            //     transform: rotate(135deg);
            //     height: 10px;
            //     width: 10px;
            //     display: inline-block;
            //     border-color: #fff;
            //     border-style: solid;
            //     margin-right: 5px;
            //     position: absolute;
            //     left: 25px;
            //     top: 37px;
            //     cursor: pointer;
            // }
            .back {
                content: "";
                border-width: 0 2px 2px 0;
                transform: rotate(135deg);
                height: 10px;
                width: 10px;
                display: inline-block;
                border-color: #fff;
                border-style: solid;
                margin-right: 5px;
                position: absolute;
                left: 40%;
                top: 37px;
                cursor: pointer;
                @media (max-width: 766px) {
                    left: 36px;
                }
            }
            #text {
                position: absolute;
                left: 41%;
                top: 32px;
                cursor: pointer;
                @media (max-width: 766px) {
                    left: 50px;
                }
            }
            .inner-box {
                max-width: 300px;
                padding: 0 15px;
                margin: auto;
                margin-top: 0 !important;
                .field {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-bottom: 20px;
                    position: relative;
                    label {
                        margin-bottom: 4px;
                    }
                    input {
                        background: #F2F2F2;
                        border-radius: 5px;
                        padding: 6px 10px 4px 10px;
                        height: 34px;
                        outline: none;
                        border: 0
                    }
                    input[type=password] {
                        padding-right: 40px !important;
                    }
                    .eye {
                        background: url('https://d310ox1f4zno4b.cloudfront.net/static-content/open-eye.png.webp');
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: absolute;
                        top: 32px;
                        right: 15px;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    .fa-eye-slash {
                        background: url('https://d310ox1f4zno4b.cloudfront.net/static-content/hide.png.webp');
                        background-size: 100%;
                    }
                    
                }
                .field-check {
                    display: flex;
                    align-items: center;
                    input {
                        margin-right: 10px;
                    }
                }
                .login-btn {
                    background-color: #E1BF42;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 25px 0 10px 0;
                    height: 34px;
                    border-radius: 40px;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    } 
                }
                .login-btn.disabled {
                    opacity: 0.8;
                    pointer-events: none;
                }
                .action {
                    display: flex;
                    flex-direction: column;
                    a {
                        padding: 5px;
                    }
                }
            }
        }
        .white-login {
            background-color: #fff !important;
            height: 100vh;
            .v-lgo {
                display: none;
            }
            h1 {
                color:#2f5266;
                display: block !important;
                padding: 0 0 25px 0;
                max-width: 240px;
                margin: 0 auto;
                text-align: center;
            }
            .field {
                background-color:#2f5266;
                border-radius: 6px;
                padding: 20px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.password-field {
                    background-color: #d1cfc8;
                }
            }
        }
    }
}
