.register-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    min-height: 60px;
    @media (max-width: 766px) {
        padding: 0;
    }
    .logo-area {
        position: relative;
        font-size: 32px;
        font-weight: 600;
        display: flex;
        align-items: center;
        letter-spacing: -3px;
        color: #2f5266;
        img {
            height: 100%;
            max-height: 64px;
            width: 60px;
            object-fit: contain;
            object-position: left;
        }
    }
    @media (max-width: 480px) {
        background-color: #234155;
        min-height: 70px;
        position: relative;
        a {
            display: inline-flex;
            max-width: 61px;
            max-height: 61px;
            overflow: hidden;
            position: relative;
            top: 35px;
            img {
                max-width: initial !important;
                width: 166px;
            }
        }
    }
}
.register {
    background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #8fadbdc7;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow-y: auto;
    @media (max-width: 480px) {
        background-color: #234155 !important;
        padding-top: 40px !important;
    }
    .wrapper {
        margin: 20px auto;
        max-width: 420px;
        width: 100%;
        &.payment-wrapper {
            max-width: 1140px !important;
            padding: 0 15px;
            .payment-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                margin-bottom: 20px;
                .backbtn {
                    font-size: 16px !important;
                    margin-bottom: 0 !important;
                    &:before {
                        top: 0;
                        height: 10px !important;
                        width: 10px !important;
                        border-color: #fff !important;
                    }
                }
                .skip-sub-btn {
                    cursor: pointer;
                }
            }
            .loader-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
        }

        @media (max-width: 480px) {
            margin: 0;
            max-width: 100%;
            .box {
                background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
                background-color: #234155;
                background-size: 100%;
                background-position: bottom;
                padding: 30px 20px 60px 20px;
                height: calc(100vh - 70px);
                border-radius: 0 !important;
            }
        }
        .react-tel-input .country-list .country {
            padding: 7px 9px;
            display: flex;
            color: #333;
            align-items: center;
        }
        .box {
            background-color: #234155;
            padding: 30px 20px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            width: 100%;
            @media (max-width: 480px) {
                padding: 10px 0px;
            }
            h1 {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 15px;
            }
            p {
                max-width: 600px;
                margin: auto;
            }
            .inner-box {
                max-width: 400px;
                padding: 0 12px;
                margin: auto;
                .react-tel-input {
                    margin-bottom: 20px;
                    .form-control {
                        width: 100%;
                    }
                }
                .select-field {
                    background-color: #fff;
                    border: 0;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    text-align: left;
                    padding-right: 25px;
                    span {
                        padding-left: 10px;
                        font-size: 12px;
                    }
                    .react-dropdown-select-input {
                        // display: none;
                        flex: 1;
                    }
                    .react-dropdown-select-dropdown-handle {
                        position: absolute;
                        right: 10px;
                    }
                    .react-dropdown-select-no-data {
                        color: #013855 !important;
                    }
                }
                .field {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-bottom: 20px;
                    position: relative;
                    label {
                        margin-bottom: 4px;
                    }
                    input {
                        background: #f2f2f2;
                        border-radius: 5px;
                        padding: 6px 10px 4px 10px;
                        height: 34px;
                        outline: none;
                        border: 0;
                        width: 100%;
                    }
                    input[type="password"] {
                        padding-right: 20px !important;
                    }
                    .eye {
                        background: url("https://bayan.s3-us-west-2.amazonaws.com/static-content/open-eye.png.webp");
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: absolute;
                        top: 8px;
                        right: 15px;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    .fa-eye-slash {
                        background: url("https://bayan.s3-us-west-2.amazonaws.com/static-content/hide.png.webp");
                        background-size: 100%;
                    }
                }
                .field-check {
                    label {
                        font-size: 12px;
                        text-align: left;
                        &.checkbox-w {
                            a {
                                color: #e1bf42;
                            }
                            .checkmark {
                                top: 0 !important;
                            }
                        }
                    }
                    display: flex;
                    align-items: center;
                    &.checkbox {
                        padding: 10px 2px 0px 5px;
                    }
                    input {
                        margin-right: 10px;
                    }
                    a {
                        font-weight: 600;
                    }
                }
                .action {
                    display: flex;
                    flex-direction: column;
                    a {
                        padding: 5px;
                    }
                }
            }
        }
    }
}
.login-btn {
    background-color: #e1bf42;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 10px 0;
    height: 34px;
    border-radius: 40px;
    cursor: pointer;
}
.login-btn.disabled {
    opacity: 0.8;
    pointer-events: none;
}
.profile-selection {
    .login-btn {
        max-width: 300px;
        margin: 0 auto;
    }
}
.register-steps {
    background-color: #2e5266ed;
    align-items: flex-start;
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 20px;
    .wrapper {
        max-width: 240px;
    }
    .backbtn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        .back-arrow {
            margin: 8px 10px;
            height: 8px;
            width: 8px;
            display: inline-block;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(135deg);
        }
        &:before {
            content: "";
            border-style: none;
        }
    }
    .i-am {
        margin: 40px 0;
        font-weight: 600;
        font-size: 16px;
    }
    .h-info {
        margin: 15px 0 0 0;
        font-weight: 600;
        font-size: 16px;
    }
    .select-me {
        p {
            margin-bottom: 0px;
        }
    }
    .select-me.dropdown-select {
        position: relative;
        .select-me {
            display: block;
            text-align: center;
            border: 1px solid #fff;
            padding: 6px 25px;
            border-radius: 6px;
            cursor: pointer;
        }
        ul {
            background-color: #fff;
            color: #2f5266;
            width: 100%;
            position: absolute;
            top: 35px;
            margin: 0;
            left: 0;
            right: 0;
            max-width: 100%;
            border-radius: 0 0 6px 6px;
            display: none;
            li {
                padding: 10px 45px;
                &:hover {
                    font-weight: 600;
                }
            }
        }
    }
    .select-me.dropdown-select.open {
        .select-me {
            background-color: #fff;
            border-radius: 6px 6px 0 0;
            color: #2f5266;
            font-weight: 600;
        }
        ul {
            display: block;
        }
    }
    .selected {
        font-weight: 600;
    }
    ul {
        padding: 0;
        margin: 10px auto;
        max-width: 150px;
        li {
            list-style: none;
            text-align: left;
            padding: 4px;
            cursor: pointer;
        }
    }
    .listactive {
        max-width: 100%;
        font-size: 12px;
        // @media (max-width: 480px) {
        //    max-width: 480px;
        // }
        li {
            border: 1px solid #ffffff;
            padding: 8px;
            text-align: center;
            margin: 10px 0;
            border-radius: 4px;
        }
        li.active {
            background-color: #fff;
            color: #2e5266ed;
        }
    }
}
.register-steps .wrapper.wrapper-md {
    max-width: 260px;
}
.register-steps .wrapper.wrapper-lg {
    max-width: 350px;
}
.skip-btn {
    margin-top: 100px;
    cursor: pointer;
}
.image-area {
    margin-top: 25px;
}
.topic-block {
    h1 {
        font-size: 16px;
        font-weight: 600;
        max-width: 200px;
        margin: auto;
    }
    .choose {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        margin-top: 30px;
        .inner {
            width: 33.33%;
            padding: 6px;
            cursor: pointer;
            div {
                padding: 10px;
                border: 1px solid #fff;
                border-radius: 4px;
                height: 94px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .selected {
            div {
                background-color: #fff;
                color: #2e5266ed;
            }
        }
    }
    .leader {
        margin-top: 30px;
    }
}

.vertical-m {
    margin: 20px 0;
    h2 {
        font-size: 18px;
    }
    p {
        text-decoration: underline;
        cursor: pointer;
    }
}
.field-single {
    display: block;
    border-radius: 4px;
    padding: 8px 10px;
    width: 100%;
    margin: auto;
    border: 0;
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.register-hidden {
    display: none !important;
}

/////////////////////////////////////

.register-modal{
    .wrapper{
        .box{
            .inner-box{
                margin-bottom: 10px;
            }
            .field {
                display: flex;
                flex-direction: column;
                text-align: left;
                margin-bottom: 20px;
                position: relative;
                label {
                    margin-bottom: 4px;
                }
                input {
                    background: #f2f2f2;
                    border-radius: 5px;
                    padding: 6px 10px 4px 10px;
                    height: 34px;
                    outline: none;
                    border: 0;
                    width: 100%;
                }
                input[type="password"] {
                    padding-right: 20px !important;
                }
                .eye {
                    background: url("https://bayan.s3-us-west-2.amazonaws.com/static-content/open-eye.png.webp");
                    background-size: 100%;
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    position: absolute;
                    top: 8px;
                    right: 15px;
                    opacity: 0.5;
                    cursor: pointer;
                }
                .fa-eye-slash {
                    background: url("https://bayan.s3-us-west-2.amazonaws.com/static-content/hide.png.webp");
                    background-size: 100%;
                }
            }
        }
    }
}

.show-password-modal{
    background-color: #2e5266ed;
    align-items: center !important;
    flex-direction: column !important;
    text-align: center;
    font-size: 14px;
    justify-content: center !important;
    color: #fff;
    padding: 20px;
    p{
        margin: 0;
    }
    .wrapper{
        .box{
            position: relative;
            margin-top: 60px;
            .i-am{
                margin: 0px 0;
                font-weight: 600;
                font-size: 16px;
            
            }
            .req-info-img{
                position: absolute;
                top: -44px;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 250px;
            }
            .no-thanks{
                cursor: pointer;
            }
        }
    }
}
