$colorWhite: #fff;
$colorBlack: #000;
.degree-page {
  color: #2e5266;
  padding-top: 0;
  padding-bottom: 30px;
  p {
    font-size: 14px;
  }
  h1 {
    margin: 20px 0;
  }
  img {
    max-width: 100%;
  }
  .back-page {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    display: none;
    a {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      font-weight: 500;
      &:after {
        content: "";
        border-width: 0 2px 2px 0;
        transform: rotate(135deg);
        height: 8px;
        width: 8px;
        display: inline-block;
        border-color: #2f5266;
        border-style: solid;
        margin-right: 5px;
      }
    }
    @media (max-width: 480px) {
      display: flex;
    }
  }
  .choose-degree-block {
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .inner-block {
      width: 160px;
      height: 160px;
      margin: 10px;
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      p {
        background-color: #23415580;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: 0;
        color: #fff;
        font-weight: 600;
        padding: 25px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      @media (max-width: 1024px) {
        width: 130px;
        height: 130px;
        margin: 4px;
        p {
          font-size: 15px;
        }
      }
    }
  }
  .choose-degree-block-two {
    img {
      opacity: 0.2;
    }
    .inner-block.active {
      img {
        opacity: 1;
      }
    }
  }
  .islamic-degree-sub-header {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      height: 230px;
      object-fit: cover;
      width: 100%;
      object-position: 0;
    }
    p {
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #2e526669;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 600;
      span {
        font-weight: 400;
        margin: 0 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .back-btn {
          margin: 8px;
          height: 10px;
          width: 10px;
          display: inline-block;
          border: solid #fff;
          border-width: 0 3px 3px 0;
          transform: rotate(135deg);
          // position: relative;
          // top: 3px;
        }
      }
    }
    @media (max-width: 1024px) {
      margin-top: 30px;
      img {
        height: 200px;
        object-fit: cover;
        max-width: initial;
      }
    }
    @media (max-width: 480px) {
      height: 200px;
      p {
        flex-direction: column;
        text-align: center;
      }
    }
  }
  .degree-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
    h1 {
      font-size: 14px;
      font-weight: 600;
      flex: 1;
    }
    .choose-degree-block {
      margin: 0;
      max-width: inherit;
      .inner-block {
        width: 100px;
        height: 100px;
        display: flex;
        p {
          font-size: 12px;
        }
      }
    }
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
  .row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 15px;
      padding-left: 15px;
      @media (max-width: 1024px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .col-8 {
      flex: 0 0 60%;
      max-width: 60%;
      margin: auto;
      padding-right: 15px;
      padding-left: 15px;
      @media (max-width: 1024px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .my-4 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .yellow-btn {
    margin: 25px 0;
  }
  .blue-btn {
    margin-left: 10px;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 22px;
    }
    .yellow-btn {
      margin: 10px 0;
      display: block;
    }
    .blue-btn {
      margin-left: 0px;
      display: block;
    }
  }
}
.degree-page-new {
  .container-small {
    max-width: 780px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }
  ul {
    padding: 0;
    margin: 40px 0;
    li {
      list-style: none;
      b,
      a {
        padding: 8px 15px;
        display: block;
      }
      &:focus,
      &:active {
        a {
          background-color: #e1bf42;
          color: rgb(255, 255, 255);
          font-weight: 600;
          text-align: center;
          border-radius: 30px;
        }
      }
      &.active {
        a {
          background-color: #e1bf42;
          color: rgb(255, 255, 255);
          font-weight: 600;
          text-align: center;
          border-radius: 30px;
        }
      }
    }
  }
  .main-nini {
    position: relative;
    max-width: 1500px;
    margin: 30px auto;
    .left-side-nini {
      position: fixed;
      left: 0;
      padding: 0 20px;
      background-color: #fff;
      @media (max-width: 1024px) {
        position: relative;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          li {
            padding: 10px;
          }
        }
      }
    }
  }
  .fullwide {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    margin-bottom: 24px;
  }
  .margin-top-10{
    margin-top: 10px;
  }
  .backbtn:before {
    content: "";
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
    height: 13px;
    width: 13px;
    display: inline-block;
    border-color: #234155;
    border-style: solid;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
  .degree-page .row .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  h2 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    margin: 20px 0;
  }
  h5 {
    font-weight: 700;
    font-size: 14px;
    a {
      color: #e1bf42;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .accordians .accordian .accordion-question {
    font-weight: 500;
  }
}

////////////////////////////////////////////////////////
/////////styling chaplaincy certificate page////////////
///////////////////////////////////////////////////////

.chaplaincy_certificate-lcontainer {
  margin: 40px 150px;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h1 {
    font-weight: 500;
    margin: 10px 0;
  }
  h2 {
    font-weight: 500;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  ul {
    li {
      font-size: 15px;
    }
  }
  @media (max-width: "1024px") {
    margin: 40px 100px;
  }
  @media (max-width: "768px") {
    margin: 40px 80px;
  }
  @media (max-width: "480px") {
    margin: 40px 15px;
    h2 {
      font-weight: 400;
    }
    p {
      font-size: 16px;
      font-weight: 400;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}
