@mixin userImg {
  height: 36px;
  width: 36px;
  min-width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  margin-right: 15px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.post-img {
  margin: 0 -20px;
  max-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.sharing-modal {
  display: contens;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.article-style,
.shared-post {
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 767px) {
    margin: 0 -20px;
    padding: 8px 20px;
  }
}

.article-style,
.shared-post {
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
}

post-detail .article-style,
.post-detail .shared-post {
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
}

/////////////////

.post-detail .shared-post {
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
}

.yourself-modal {
  background-color: #23415582 !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5555 !important;
  overflow-y: auto;
  .card {
    margin-bottom: 0 !important;
    padding: 10px 0 !important;
  }
  .modal_content {
    z-index: 55;
    border: 0;
    margin-bottom: 20px;
  }
}
.modal_content {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 30px;
  right: 30px;
  max-width: 750px;
  margin: auto;
  width: auto;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.sharing-modal {
  display: contens;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.post-detail {
  .action-links li a:hover {
    background-color: #e9ecf0;
  }
  .action-links li a img {
    margin-right: 10px;
  }
}
.post-detail {
  .update-links-to-bold {
    margin-left: 15px;
  }
}

@media (max-width: 480) {
  .user-detail .roundedImage {
    margin-left: 10px;
  }
}

.user-detail-mod {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
  margin-bottom: 0;
  align-items: center;
  flex-wrap: wrap;
  .roundedimg {
    position: static;
  }
}

.card {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

// .yourself {
//   display: flex;
//   align-items: center;
//   .roundedimg {
//     height: 50px;
//     width: 50px;
//     border-radius: 50%;
//     overflow: hidden;
//     display: flex;
//     // align-items: center;
//     justify-content: center;
//   }
//   .links {
//     display: flex;
//     align-items: center;
//     margin: 0 5px;
//     cursor: pointer;
//     // position: absolute;
//     position: relative;
//     right: -5px;
//     top: 0;
//     img {
//       margin-right: 10px;
//     }
//     // .down-arrow {
//     //     margin-left: 10px;
//     //     position: relative;
//     //     top: -1px;
//     //     height: 8px;
//     //     width: 8px;
//     //     display: block;
//     //     border: solid black;
//     //     border-width: 0 2px 2px 0;
//     //     transform: rotate(45deg);
//     //     opacity: 0.5;
//     //     transition: .3s linear;
//     //     text-indent: -9999px;
//     //     &:hover {
//     //         background-color: #eee;
//     //     }
//     // }
//     .down-arrow {
//       margin-left: 5px;
//       display: inline-flex;
//       align-items: center;
//       justify-content: center;
//       height: 20px;
//       width: 20px;
//       border-radius: 50%;
//       transition: 0.3s linear;
//       &:hover {
//         background-color: #eee;
//       }
//       &:after {
//         content: ">";
//         display: block;
//         color: #234155;
//         transform: rotate(90deg);
//         opacity: 0.8;
//         transition: 0.3s linear;
//         font-size: 18px;
//       }
//     }
//     .profile-dropdown {
//       position: absolute;
//       top: 29px;
//       background-color: #fff;
//       right: -10px;
//       left: 0;
//       padding: 15px;
//       border-radius: 0 0 4px 4px;
//       box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.05);
//       z-index: 554;
//     }
//   }
//   .links.open {
//     .down-arrow {
//       transform: rotate(-135deg);
//     }
//   }
//   textarea,
//   input {
//     background: #f3f3f3;
//     border-radius: 23px;
//     border: 0;
//     outline: none;
//     padding: 10px 20px;
//     min-width: 160px;
//     flex: 1;
//     margin-left: 15px;
//     display: inline-block;
//     width: 100%;
//     font-size: 14px;
//   }
//   textarea {
//     min-height: 200px;
//     margin: 0;
//     padding-top: 20px;
//     padding-right: 45px;
//     font-size: 14px;
//   }
//   .file-input {
//     background: url("https://d310ox1f4zno4b.cloudfront.net/static-content/cam.png.webp")
//       no-repeat;
//     text-indent: -9999px;
//     height: 30px;
//     width: 30px;
//     overflow: hidden;
//     position: absolute;
//     max-width: 30px;
//     padding: 0 !important;
//     min-width: 10px;
//     right: 45px;
//     border-radius: 0;
//     background-size: 20px;
//     top: 45px;
//     background-position: center;
//     cursor: pointer;
//   }
// }

.user-detail {
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 15px 0;
  .roundedImage {
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .inner {
    display: flex;
    align-items: center;
    margin-left: 15px;
    span {
      display: flex;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &:first-child {
        font-weight: 600;
      }
    }
  }
  .roundedimg {
    margin-right: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .time {
    font-size: 12px;
    margin-top: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pub-post-page {
  background-color: #f2f2f2;
  padding: 15px;
  color: #013855;
  .pub-wrapper {
    margin: 0 auto;
    max-width: 750px;
    .pub-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      .pub-back {
        display: flex;

        img {
          margin-right: 10px;
        }
      }
    }
    .pub-card {
      background-color: #fff;
      border-radius: 15px;
      overflow: hidden;
      .pub-img {
        display: flex;
        align-items: center;
        // justify-content: center;
        // object-fit: cover;
        img {
          width: 800px;
          object-fit: cover;
        }
      }
      .pub-wrapper-sm {
        max-width: 650px;
        margin: 15px auto;
        width: 100%;
        .pub-tags {
          margin-left: 10px;
        }
        .post-detail {
          ul.action {
            list-style: none;
            padding: 0;
            margin-left: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 -10px;
          }
          ul.action {
            li {
              a {
                padding: 10px 20px;
                display: inline-flex;
                font-size: 12px;
              }
            }
          }
          .action-links {
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0 -20px;
            margin-top: 20px;
          }
          .action-links li {
            list-style: none;
            width: 33.3334%;
          }
          .action-links li a {
            padding: 15px 20px;
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            transition: 0.2s linear;
            min-height: 54px;
          }
        }
        .pub-user {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-left: 10px;
          .pub-user-img {
            height: 66px;
            width: 66px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            background-color: #ccc;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .pub-detail {
            padding-left: 15px;
            .pub-title {
              letter-spacing: 3.8px;
              color: #234155;
              font-weight: 700;
            }
            .pub-date {
              font-size: 10px;
              font-weight: 600;
              margin-top: 6 px;
            }
          }
        }
        .pub-tags-top {
          .tag-item {
            background-color: #fff;
            padding: 2px 10px;
            display: inline-flex;
            margin: 5px 5px 0 0;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }

        .pub-post-detail {
          margin-top: 20px;
          margin-left: 20px;

          h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            margin: 0;
          }
          p {
            font-size: 14px;
            line-height: 30px;
          }
        }
      }
    }
    .pub-say-somthing {
      background: #f7f7f7;
      border-radius: 5px;
      padding: 20px;
      display: flex;

      align-items: center;
      margin-top: 15px;
      .pub-sm-user-img {
        @include userImg;
      }
      input {
        flex: 1;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 8px;
        opacity: 1;
        width: 40rem;
        margin-left: 10px;
        padding: 9px;
        outline: none;
        &:focus {
          border-color: #ccc;
        }
      }
    }
    .pub-comments {
      background: #fff;
      border-radius: 5px;
      padding: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      .pub-user-comment {
        display: flex;
        font-size: 11px;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        .pub-comment {
          margin-top: 10px;
        }
        .pub-user-comment-img {
          @include userImg;
        }
        .comment-text {
          margin-top: 8px;
        }
        .comment-share-like {
          margin-top: 8px;
          .pub-icon {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            img {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 812px) {
  .pub-post-page .pub-wrapper {
    .pub-comments .pub-say-somthing .comments-div .comment-inner {
      input {
        width: 30rem;
      }
    }
  }
}
@media (max-width: 660px) {
  .pub-post-page .pub-wrapper {
    .pub-comments .pub-say-somthing .comments-div .comment-inner {
      input {
        width: 20rem;
      }
    }
  }
}
@media (max-width: 510px) {
  .pub-post-page .pub-wrapper {
    .pub-comments .pub-say-somthing .comments-div .comment-inner {
      input {
        width: 18rem;
      }
    }
  }
}
@media (max-width: 480px) {
  .pub-post-page .pub-wrapper {
    .pub-comments .pub-say-somthing .comments-div .comment-inner {
      input {
        width: 75%;
      }
    }
    .pub-card .pub-wrapper-sm .post-detail {
      .action-links {
        border-top: 1px solid #cccccc4a;
        padding-top: 10px;
        margin-top: 10px;
        justify-content: space-between;
        li {
          list-style: none;
          width: 33.3334%;

          a {
            font-size: 12px;
            padding: 8px 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .pub-post-page
    .pub-wrapper
    .pub-card
    .pub-wrapper-sm
    .post-detail
    .action-links
    li
    a {
    font-size: 14px;
    padding: 4px 4px;
  }
}
@media (max-width: 408) {
  .pub-post-page .pub-wrapper .pub-say-somthing {
    input {
      width: 4rem;
      margin-left: 10px;
      padding: 9px;
      outline: none;
    }
  }
}
.link-title {
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 2px;
  margin-left: 15px;
}

////////////////////////////////////////////////////////////////////
//                   Article design                               //
////////////////////////////////////////////////////////////////////
// .content {
//   min-height: calc(100vh - 80px);
//   background-color: #f2f2f2;
//   padding-bottom: 20px;
//   display: flex;
//   flex-direction: column;
//   .wrapper {
//     max-width: 1366px;
//     padding-left: 15px;
//     padding-right: 15px;
//     margin: 0 auto;
//     width: 100%;
//     .mini-container {
//       max-width: 640px;
//       margin: 0 auto;
//       &.posts {
//         .userPostBlock {
//           padding-bottom: 0;
//           overflow: hidden;
//         }
//       }
//     }
//     .topFixed {
//       position: sticky;
//       top: 100px;
//       .progress-percentage {
//         width: 48px;
//       }
//     }
//     .row {
//       display: flex;
//       margin-right: -15px;
//       margin-left: -15px;
//       .col {
//         width: 100%;
//         padding-left: 15px;
//         padding-right: 15px;
//         margin-top: 20px;
//       }
//       .col-md-3 {
//         flex: 0 0 20%;
//         max-width: 20%;
//       }
//       .col-md-6 {
//         flex: 0 0 60%;
//         max-width: 60%;
//       }
//       .col-md-9 {
//         flex: 0 0 80%;
//         max-width: 80%;
//       }
//       .col-md-5 {
//         flex: 0 0 40%;
//         max-width: 40%;
//       }
//       .col-md-7 {
//         flex: 0 0 60%;
//         max-width: 60%;
//       }
//     }
//     .card {
//       padding: 20px;
//       border-radius: 10px;
//       margin-bottom: 20px;
//     }
//     .profile {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       .roundedimg {
//         height: 60px;
//         width: 60px;
//         border-radius: 50%;
//         overflow: hidden;
//         display: flex;
//         // align-items: center;
//         justify-content: center;
//       }
//       .flex-progress {
//         display: flex;
//         align-items: center;
//         width: 100%;
//         .profile-detail {
//           margin-left: 15px;
//           p {
//             margin: 0;
//           }
//         }
//       }
//       .uploading {
//         width: 100%;
//         li {
//           text-align: left;
//           a {
//             text-decoration: none;
//             display: flex;
//             align-items: center;
//             padding: 5px 0;
//             position: relative;
//             padding-left: 40px;
//             &.text-decoration {
//               text-decoration: underline;
//             }
//             .check {
//               height: 22px;
//               width: 22px;
//               border: 3px solid #234155;
//               display: inline-block;
//               position: absolute;
//               border-radius: 50%;
//               top: 4px;
//               left: 0;
//               opacity: 0.2;
//               &:after {
//                 content: "";
//                 transform: rotate(45deg);
//                 height: 11px;
//                 width: 7px;
//                 border-bottom: 3px solid #234155;
//                 border-right: 3px solid #234155;
//                 position: absolute;
//                 left: 5px;
//                 top: 1px;
//               }
//             }
//           }
//         }
//       }
//       img {
//         // margin: 10px 0;
//       }
//       label {
//         margin-top: 10px;
//         font-weight: 600;
//         font-size: 16px;
//         text-align: center;
//       }
//       ul {
//         padding: 0;
//         text-align: center;
//         margin-top: 25px;
//         margin-bottom: 0;
//         li {
//           list-style: none;
//           .btn {
//             color: #013855;
//             border: 1px solid #013855;
//             display: inline-flex;
//             padding: 6px;
//             align-items: center;
//             justify-content: center;
//             border-radius: 30px;
//             min-width: 150px;
//             font-weight: 600;
//             font-size: 13px;
//             margin-bottom: 15px;
//             transition: 0.3s linear;
//             &:hover {
//               background-color: #e1bf42;
//               border-color: #e1bf42;
//               color: #fff;
//             }
//           }
//           .btn.btn-yellow {
//             background-color: #e1bf42;
//             border-color: #e1bf42;
//             color: #fff;
//           }
//         }
//       }
//     }
//     .yourself {
//       display: flex;
//       align-items: center;
//       .roundedimg {
//         height: 50px;
//         width: 50px;
//         border-radius: 50%;
//         overflow: hidden;
//         display: flex;
//         // align-items: center;
//         justify-content: center;
//       }
//       .links {
//         display: flex;
//         align-items: center;
//         margin: 0 5px;
//         cursor: pointer;
//         // position: absolute;
//         position: relative;
//         right: -5px;
//         top: 0;
//         img {
//           margin-right: 10px;
//         }
//         // .down-arrow {
//         //     margin-left: 10px;
//         //     position: relative;
//         //     top: -1px;
//         //     height: 8px;
//         //     width: 8px;
//         //     display: block;
//         //     border: solid black;
//         //     border-width: 0 2px 2px 0;
//         //     transform: rotate(45deg);
//         //     opacity: 0.5;
//         //     transition: .3s linear;
//         //     text-indent: -9999px;
//         //     &:hover {
//         //         background-color: #eee;
//         //     }
//         // }
//         .down-arrow {
//           margin-left: 5px;
//           display: inline-flex;
//           align-items: center;
//           justify-content: center;
//           height: 20px;
//           width: 20px;
//           border-radius: 50%;
//           transition: 0.3s linear;
//           &:hover {
//             background-color: #eee;
//           }
//           &:after {
//             content: ">";
//             display: block;
//             color: #234155;
//             transform: rotate(90deg);
//             opacity: 0.8;
//             transition: 0.3s linear;
//             font-size: 18px;
//           }
//         }
//         .profile-dropdown {
//           position: absolute;
//           top: 29px;
//           background-color: #fff;
//           right: -10px;
//           left: 0;
//           padding: 15px;
//           border-radius: 0 0 4px 4px;
//           box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.05);
//           z-index: 554;
//         }
//       }
//       .links.open {
//         .down-arrow {
//           transform: rotate(-135deg);
//         }
//       }
//       textarea,
//       input {
//         background: #f3f3f3;
//         border-radius: 23px;
//         border: 0;
//         outline: none;
//         padding: 10px 20px;
//         min-width: 160px;
//         flex: 1;
//         margin-left: 15px;
//         display: inline-block;
//         width: 100%;
//         font-size: 14px;
//       }
//       textarea {
//         min-height: 200px;
//         margin: 0;
//         padding-top: 20px;
//         padding-right: 45px;
//         font-size: 14px;
//       }
//       .file-input {
//         background: url("https://d310ox1f4zno4b.cloudfront.net/static-content/cam.png.webp")
//           no-repeat;
//         text-indent: -9999px;
//         height: 30px;
//         width: 30px;
//         overflow: hidden;
//         position: absolute;
//         max-width: 30px;
//         padding: 0 !important;
//         min-width: 10px;
//         right: 45px;
//         border-radius: 0;
//         background-size: 20px;
//         top: 45px;
//         background-position: center;
//         cursor: pointer;
//       }
//     }
//     .user-detail {
//       justify-content: space-between;
//       align-items: flex-start;
//       margin: 0 0 15px 0;
//       .roundedImage {
//         height: 50px;
//         width: 50px;
//         overflow: hidden;
//         border-radius: 50%;
//         margin-right: 15px;
//         img {
//           height: 100%;
//           width: 100%;
//           object-fit: cover;
//         }
//       }
//       .inner {
//         display: flex;
//         align-items: center;
//         span {
//           display: flex;
//           cursor: pointer;
//           &:hover {
//             text-decoration: underline;
//           }
//           &:first-child {
//             font-weight: 600;
//           }
//         }
//       }
//       .roundedimg {
//         margin-right: 15px;
//         height: 50px;
//         width: 50px;
//         border-radius: 50%;
//         overflow: hidden;
//         background-color: #ccc;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//       .time {
//         font-size: 12px;
//         margin-top: 3px;
//         &:hover {
//           text-decoration: underline;
//         }
//       }
//     }
//     .user-detail-mod {
//       border-bottom: 1px solid #e9e9e9;
//       padding-bottom: 15px;
//       margin-bottom: 0;
//       align-items: center;
//       flex-wrap: wrap;
//       .roundedimg {
//         position: static;
//       }
//     }
//     .post-img {
//       margin: 0 -20px;
//       max-height: 350px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       overflow: hidden;
//       img {
//         max-width: 100%;
//         max-height: 100%;
//         object-fit: cover;
//       }
//     }

//     .post-detail {
//       .react_tinylink_card {
//         border-radius: 10px;
//         margin: 10px 0;
//         max-width: 100%;
//       }
//       ul.action {
//         padding: 0;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         margin: 0 0;
//         li {
//           list-style: none;

//           a {
//             padding: 10px 20px;
//             display: inline-flex;
//             font-size: 12px;
//             @media (max-width: 480px) {
//               padding: 5px 10px;
//             }
//           }
//         }
//       }
//       .action-links {
//         padding: 0;
//         display: flex;
//         list-style: none;
//         align-items: center;
//         margin: 0 -20px;
//         margin-top: 20px;
//         justify-content: space-between;
//         li {
//           list-style: none;
//           width: 33.3334%;
//           &:nth-child(2) {
//             text-align: center;
//           }
//           &:last-child {
//             text-align: right;
//           }
//           a {
//             padding: 15px 20px;
//             display: flex;
//             font-size: 14px;
//             align-items: center;
//             justify-content: center;
//             transition: 0.2s linear;
//             min-height: 55px;
//             img {
//               margin-right: 10px;
//             }
//             &:hover {
//               background-color: #e9ecf0;
//             }
//           }
//           @media (max-width: 480px) {
//             width: auto;
//             a {
//               font-size: 12px;
//               padding: 8px 15px;
//               background-color: #fff !important;
//               img {
//                 width: 20px;
//               }
//             }
//           }
//         }
//       }
//       .action-links li a:hover {
//         background-color: #e9ecf0;
//       }
//       .article-style,
//       .shared-post {
//         background: #f3f3f3;
//         padding: 15px 15px 0 15px;
//         border-radius: 0 0 8px 8px;
//         display: flex;
//         flex-wrap: wrap;
//         flex-direction: column;
//         overflow: hidden;
//         @media (max-width: 767px) {
//           margin: 0 -20px;
//           padding: 8px 20px;
//         }
//       }
//       .update-links-to-bold {
//         a {
//           // font-weight: 550;
//           color: #2ba6cb;
//         }
//       }
//       .link-title {
//         font-size: 16px;
//         font-weight: 550;
//         margin-bottom: 2px;

//       }
//       .action-links li a:hover {
//         background-color: #e9ecf0;
//       }
//     }
//     .post-comment-area {
//       display: flex;
//       flex-direction: column;
//       background: #f7f7f7;
//       padding: 15px 18px 0;
//       margin: 0 -20px;
//       position: relative;
//       top: 0px;
//       .comment-area-inner {
//         display: flex;
//         .user-image {
//           width: 30px;
//           height: 30px;
//           border-radius: 50%;
//           margin-right: 15px;
//         }
//         .comment-content-text {
//           background: #e4e4e4;
//           padding: 11px 17px;
//           border-radius: 8px;
//           word-break: break-word;
//           h4 {
//             margin: 0;
//             font-size: 16px;
//             font-weight: 600;
//           }
//           p {
//             margin: 5px 0 0 0;
//           }
//         }
//         .comment-msg-socail-icon {
//           ul {
//             padding: 0;
//             margin: 20px 0;
//             li {
//               display: inline-block;
//               margin-right: 12px;
//               list-style: none;
//               .ml-2 {
//                 margin-left: 5px;
//               }
//               img {
//                 width: 18px;
//               }
//               .reply-container {
//                 font-weight: 600;
//                 cursor: pointer;
//                 font-size: 12px;
//               }
//               span {
//                 color: #707070;
//                 font-size: 11px;
//                 line-height: 10px;
//               }
//             }
//             .edit_trash_banner {
//               display: inline-flex;
//               float: right;
//               .trash {
//                 img {
//                   width: 14px;
//                   height: auto;
//                 }
//               }
//               .edit {
//                 margin-right: 20px;
//                 img {
//                   width: 14px;
//                   height: auto;
//                 }
//               }
//             }
//           }
//         }
//         .comment-content {
//           flex: 1;
//         }
//       }
//     }
//     .comments-div {
//       background: #f7f7f7;
//       padding: 15px 18px;
//       margin: 0 -20px;
//       position: relative;
//       top: 0px;
//       border-radius: 0 0 10px 10px;
//       .comment-inner {
//         display: flex;
//         flex: 1;
//         align-items: center;
//         img {
//           width: 30px;
//           height: 30px;
//           border-radius: 50%;
//           margin-right: 15px;
//         }
//         input {
//           display: block;
//           width: 100%;
//           height: auto;
//           box-sizing: border-box;
//           font-size: 14px;
//           line-height: 19px;
//           border: 1px solid #e7e7e7;
//           border-radius: 8px;
//           padding: 3px 30px 3px 10px;
//           min-height: 19px;
//           margin: 0;
//           overflow: auto;
//           padding-right: 50px;
//           background-color: #fff;
//           outline: none;
//         }
//       }
//     }
//     .search-form-content {
//       position: relative;
//       input {
//         background: #f3f3f3 0% 0% no-repeat padding-box;
//         border-radius: 23px;
//         border: 0;
//         outline: none;
//         padding: 8px 20px 8px 40px;
//         display: inline-block;
//         width: 100%;
//         font-size: 13px;
//       }
//       img {
//         position: absolute;
//         top: 9px;
//         left: 15px;
//         max-width: 16px;
//       }
//     }
//     .trending-card {
//       font-size: 14px;
//       color: #234155;
//       min-height: 300px;
//       label {
//         font-weight: 600;
//         margin-top: 20px;
//         display: block;
//         color: #234155;
//       }
//       ul {
//         padding: 0;
//         max-height: 150px;
//         overflow-y: auto;
//         li {
//           cursor: pointer;
//           list-style: none;
//           margin-bottom: 20px;
//           strong {
//             font-weight: 600;
//           }
//         }
//       }
//     }
//     .st-block {
//       text-align: center;
//       padding: 0 20px;
//       .title {
//         font-weight: 600;
//         margin-top: 20px;
//         margin-bottom: 10px;
//         color: #7092be;
//       }
//       .btn {
//         background-color: #7092be;
//         border-color: #7092be;
//         color: #fff;
//         font-size: 12px;
//         display: block;
//         padding: 8px;
//         border-radius: 20px;
//         cursor: pointer;
//       }
//     }
//     .st-block2 {
//       .title {
//         color: #33656b;
//       }
//       .btn {
//         background-color: #33656b;
//       }
//     }
//     .st-block3 {
//       .title {
//         color: #33656b;
//       }
//       .btn {
//         background-color: #33656b;
//       }
//     }
//     .mob-only {
//       display: none;
//     }
//     .pursue {
//       padding: 20px;

//       h3 {
//         font-weight: 600;
//         font-size: 14px;
//       }
//       .pur-img {
//         margin: 15px 0;
//         img {
//           width: 100%;
//         }
//       }
//       .btn {
//         width: 100%;
//         margin-bottom: 10px;
//       }
//       @media (max-width: 1024px) {
//         &.mob-only {
//           display: block;
//         }
//         text-align: center;
//         padding: 0 0 20px 0;
//         .btn {
//           width: auto;
//           min-width: 240px;
//         }
//       }
//     }
//   }
//   @media (max-width: 1024px) {
//     // padding-top: 48px !important;
//     .wrapper {
//       .row {
//         .col-md-3 {
//           display: none;
//         }
//         .col-md-6 {
//           flex: 0 0 100%;
//           max-width: 100%;
//         }
//       }
//     }
//   }
//   @media (max-width: 480px) {
//     .wrapper {
//       .comments-div,
//       .card {
//         border-radius: 0;
//       }
//       .card {
//         padding: 10px 20px;
//       }
//       .row {
//         .col-md-6 {
//           padding-left: 0 !important;
//           padding-right: 0 !important;
//         }
//       }
//       .post-detail {
//         .action-links {
//           border-top: 1px solid #cccccc4a;
//           padding-top: 10px;
//           margin-top: 10px;
//           justify-content: space-between;
//         }
//       }
//       .post-comment-area,
//       .comments-div {
//         top: 10px;
//       }
//     }
//   }

//   @media (max-width: 360px) {
//     .wrapper {
//       .card.card-vertical {
//         padding: 10px 5px;
//       }
//       .my-conection {
//         .card {
//           padding: 10px 5px !important;
//         }
//       }
//     }
//   }
// }

// // Network-page

// // .network-block {
// //     font-size: 14px;
// //     .conection-list {
// //         ul {
// //             padding: 0;
// //             max-width: 155px;
// //             margin: 10px auto;
// //             li {
// //                 list-style: none;

// //                 a {
// //                     padding: 8px 16px;
// //                     display: flex;
// //                     align-items: center;
// //                     margin: 5px 0;
// //                     @media (max-width: 480px) {
// //                         font-size: 12px !important;
// //                     }
// //                     @media (max-width: 375px) {
// //                         padding: 8px;
// //                     }
// //                     span {
// //                         height: 16px;
// //                         width: 16px;
// //                         margin-left: 5px;
// //                         background-color: #EF5350;
// //                         color: #fff;
// //                         display: inline-flex;
// //                         align-items: center;
// //                         border-radius: 50%;
// //                         font-size: 10px;
// //                         justify-content: center;
// //                         z-index: 22;
// //                     }
// //                 }
// //             }
// //             li.active {
// //                 a {
// //                     background-color: #2E5266;
// //                     color: #fff;
// //                     border-radius: 30px;
// //                     text-align: center;
// //                     justify-content: center;
// //                 }
// //             }
// //         }
// //     }
// //     .small-wrapper {
// //         max-width: 600px;
// //         margin: 0 auto;
// //     }
// //     .medium-wrapper {
// //         max-width: 800px;
// //         margin: 0 auto;
// //     }
// //     .my-conection {
// //         padding-left: 100px;
// //         h1 {
// //             font-size: 24px;
// //             margin-top: 0;
// //             margin-bottom: 5px;
// //         }
// //         .search-form {
// //             position: relative;
// //             margin-left: 15px;
// //             input {
// //                 background: #fff;
// //                 border-radius: 23px;
// //                 border: 0;
// //                 outline: none;
// //                 padding: 10px 20px 10px 40px;
// //                 min-width: 160px;
// //                 display: inline-block;
// //                 font-size: 12px;
// //             }
// //             img {
// //                 position: absolute;
// //                 top: 12px;
// //                 left: 15px;
// //                 max-width: 16px;
// //             }
// //         }
// //         .my-conection-sub {
// //             display: flex;
// //             justify-content: space-between;
// //             margin-bottom: 15px;
// //             .my-conection-text {
// //                 display: flex;
// //                 align-items: center;
// //                 p {
// //                     margin: 0;
// //                     padding-right: 25px;
// //                 }
// //                 a {
// //                     display: flex;
// //                     align-items: center;
// //                     span.down-arrow {
// //                         margin-left: 15px;
// //                         position: relative;
// //                         top: -1px;
// //                         height: 8px;
// //                         width: 8px;
// //                         display: block;
// //                         border: solid black;
// //                         border-width: 0 2px 2px 0;
// //                         transform: rotate(45deg);
// //                         opacity: 0.5;
// //                         transition: .3s linear;
// //                         text-indent: -9999px;
// //                     }
// //                 }
// //             }

// //         }
// //         .my-conection-sub.mobile-only {
// //             display: none;
// //         }
// //         .st-list {
// //             display: flex;
// //             align-items: center;
// //             margin-bottom: 25px;
// //             .st-in.st-left {
// //                 flex: 1;
// //                 display: flex;
// //                 align-items: center;
// //                 label {
// //                     font-size: 16px;
// //                     font-weight: 600;
// //                     margin-bottom: 4px;
// //                     display: block;
// //                 }
// //                 span {
// //                     height: 60px;
// //                     min-width: 60px;
// //                     display: inline-flex;
// //                     margin-right: 15px;
// //                 }
// //             }
// //         }
// //         .st-list:last-child {
// //             margin-bottom: 0;
// //         }
// //     }

// //     @media (max-width: 1024px) {
// //         .my-conection {
// //             padding-left: 0;
// //         }
// //         .content .wrapper .row .col-sm-4 {
// //             flex: 0 0 40%;
// //             max-width: 40%
// //         }
// //         .content .wrapper .row .col-sm-6 {
// //             flex: 0 0 60%;
// //             max-width: 60%
// //         }
// //         .my-conection-sub.mobile-only {
// //             display: flex !important;
// //             @media (max-width: 480px) {
// //                 font-size: 12px;
// //             }
// //         }
// //     }
// //     @media (max-width: 767px) {
// //         .my-conection-sub.mobile-only {
// //             display: flex !important;
// //             flex-direction: column-reverse;
// //             .search-form {
// //                 margin-left: 0 !important;
// //                 margin-bottom: 15px;
// //                 input {
// //                     width: 100%;
// //                 }
// //             }
// //             .my-conection-text {
// //                 justify-content: space-between;
// //             }
// //         }
// //     }

// // }

// // Classes Page

// // .classes-block {
// //     .col-md-3a {
// //         flex: 0 0 25% ;
// //         max-width: 25% ;
// //         padding-left: 15px;
// //         padding-right: 15px;
// //         margin-bottom: 10px;
// //     }
// //     .col-md-6 {
// //         flex: 0 0 80% !important;
// //         max-width: 80% !important;
// //     }
// //     .card-vertical {
// //         position: relative;
// //         .close {
// //             position: absolute;
// //             right: 25px;
// //         }
// //         .st-in.st-left,
// //         .st-list {
// //             flex-direction: column;
// //             align-items: center;
// //             text-align: center;
// //             label {
// //                 margin-top: 20px;
// //             }
// //         }
// //         .st-in.st-right {
// //             flex-direction: column;
// //             display: flex;
// //             margin-top: 10px;
// //             a {
// //                 font-weight: 600;
// //                 margin-top: 10px;
// //                 cursor: pointer;
// //                 img {
// //                     margin-right: 7px;
// //                 }
// //             }
// //         }
// //     }
// //     @media (max-width: 1024px) {
// //         padding: 0 15px;
// //         padding-top: 0;

// //         h1 {
// //             text-align: center;
// //             margin-bottom: 0 !important;
// //         }
// //         .col.col-md-6.col-sm-12 {
// //             flex: 0 0 100% !important;
// //             max-width: 100% !important;
// //         }
// //         .conection-list {
// //             display: flex !important;
// //             margin-bottom: 15px;
// //             ul {
// //                 max-width: 100%;
// //                 display: flex;
// //             }
// //         }
// //         .my-conection-sub {
// //             display: none !important;
// //         }
// //     }
// //     @media (max-width: 768px) {
// //         .col-md-3a {
// //             flex: 0 0 33.33%;
// //             max-width: 33.33%;
// //         }
// //         .card {
// //             border-radius: 4px !important;
// //         }
// //     }
// //     @media (max-width: 480px) {
// //         padding: 0;
// //         .row {
// //             margin-left: -5px !important;
// //             margin-right: -5px !important;
// //         }
// //         .col-md-3a {
// //             flex: 0 0 50%;
// //             max-width: 50%;
// //             padding-left: 5px !important;
// //             padding-right: 5px !important;
// //             .card {
// //                 margin-bottom: 0px !important;
// //             }
// //         }
// //     }

// // }

// // Profile

// // .profile-block {
// //     font-size: 14px;
// //     .profile-banner {
// //         background-color: #23415573;
// //         height: 260px;
// //         display: flex;
// //         align-items: center;
// //         justify-content: center;
// //         padding: 15px;
// //         .userimg-cover {
// //             border: 1px dashed #FFFFFF;
// //             border-radius: 24px;
// //             width: 100%;
// //             padding: 10px;
// //             position: relative;
// //             color: #fff;
// //             max-width: 600px;
// //             display: flex;
// //             align-items: center;
// //             justify-content: center;
// //             cursor: pointer;
// //             img {
// //                 margin: 0 10px;
// //             }
// //             input {
// //                 position: absolute;
// //                 opacity: 0;
// //                 left: 0;
// //                 right: 0;
// //                 top: 0;
// //                 bottom: 0;
// //                 cursor: pointer;
// //             }
// //         }
// //     }
// //     .user-application {
// //         padding: 10px;
// //         background-color: #fff;
// //         .main-app {
// //             display: flex;
// //             justify-content: space-between;
// //             align-items: center;
// //             .user-img {
// //                 display: inline-flex;
// //                 align-items: center;
// //                 margin: -25px 0;
// //                 .userimg {
// //                     height: 100px;
// //                     width: 100px;
// //                     display: flex;
// //                     align-items: center;
// //                     justify-content: center;
// //                     border: 2px solid #fff;
// //                     border-radius: 50%;
// //                     overflow: hidden;
// //                     img {
// //                         max-width: 100%;
// //                     }
// //                 }
// //                 h2 {
// //                     margin: 0;
// //                     font-weight: 600;
// //                     font-size: 18px;
// //                     margin-left: 15px;
// //                 }
// //             }
// //         }
// //     }
// //     .about-card {
// //         margin-right: 50px;
// //         h2 {
// //             font-weight: 600;
// //             font-size: 16px;
// //             margin: 0;
// //         }
// //         p {
// //             margin: 10px 0;
// //             min-height: 140px;
// //         }
// //     }
// //     .publications-card {
// //         flex-direction: column;
// //         align-items: flex-start !important;
// //         justify-content: flex-start !important;

// //         .publications {
// //             align-items: center !important;
// //             justify-content: center !important;
// //             text-align: left;
// //             background: #e4e4e469;
// //             height: 160px;
// //             margin-top: 10px;
// //             display: flex;
// //             align-items: center;
// //             justify-content: center;
// //             border: 1px solid #F2F2F2;
// //             border-radius: 4px;
// //             width: 100%;
// //         }
// //     }
// // }

// // classes-page

// // .classes-page {
// //     .st-application {
// //         display: flex;
// //         flex-direction: column;
// //         align-items: center;
// //         text-align: center;
// //         label {
// //             font-weight: 600;
// //             font-size: 18px;
// //             margin: 15px 0;
// //         }
// //     }
// //     .my-conection {
// //         .card {
// //             padding: 0 !important;
// //             overflow: hidden;
// //         }
// //     }
// //     .col-md-8 {
// //         flex: 0 0 66%;
// //         max-width: 66%;
// //     }
// //     .col-md-3a {
// //         flex: 0 0 33.33%;
// //         max-width: 33.33%;
// //         padding-left: 15px;
// //         padding-right: 15px;
// //         margin-bottom: 10px;
// //     }
// //     .my-conection {
// //         h1 {
// //             margin-bottom: 20px;
// //         }
// //     }
// //     .conection-list {
// //         margin-top: 55px;
// //         ul {
// //             max-width: 190px;
// //         }
// //     }
// //     .mr-2 {
// //         margin-right: 7px;
// //     }
// //     .class-image {
// //         background-color: #ccc;
// //         width: 100%;
// //         height: 125px;
// //         overflow: hidden;
// //         display: flex;
// //         align-items: center;
// //         justify-content: center;
// //     }
// //     .class-body {
// //         padding: 15px;
// //         text-align: left;
// //         label {
// //             font-size: 12px !important;
// //             margin: 0 !important;
// //         }
// //         p {
// //             font-size: 12px;
// //             margin: 5px 0;
// //             display: -webkit-box;
// //             -webkit-line-clamp: 3;
// //             -webkit-box-orient: vertical;
// //             overflow: hidden;
// //         }
// //         .btn-in {
// //             border-radius: 3px;
// //             color: #fff;
// //             display: flex;
// //             align-items: center;
// //             justify-content: center;
// //             font-size: 13px;
// //             padding: 8px;
// //             margin-top: 10px;
// //         }
// //         .btn-green {
// //             background: #65C08B;
// //         }
// //         .btn-purple {
// //             background: #7E3E98;
// //         }
// //     }
// //     @media (max-width: 1024px) {
// //         h1 {
// //             text-align: center;
// //             margin-bottom: 0 !important;
// //         }
// //         .col-md-8 {
// //             flex: 0 0 100%;
// //             max-width: 100%;
// //         }
// //         .conection-list.mobile-only {
// //             display: block;
// //             margin-top: 0;
// //             ul {
// //                 max-width: 100%;
// //                 display: flex;
// //                 align-items: center;
// //                 justify-content: center;
// //                 margin-top: 5px;
// //                 margin-bottom: 15px;
// //                 li {
// //                     opacity: 0.3;
// //                     a {
// //                         margin: 0;
// //                         padding: 5px;
// //                         font-weight: 600;
// //                     }
// //                 }
// //                 li.selected {
// //                     opacity: 1;
// //                 }

// //             }
// //         }
// //     }
// //     @media (max-width: 767px) {

// //         .col-md-3a,
// //         .col-md-8 {
// //             flex: 0 0 100%;
// //             max-width: 100%;
// //         }
// //         .card {
// //             border-radius: 6px !important;
// //         }
// //     }
// // }

// .sharing-modal {
//   display: contens;
//   position: fixed;
//   z-index: 1;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.25);
// }
// .mobile-only {
//   display: none;
// }
