@media (max-width: 1024px) {
  .overflow-hidden {
    overflow: hidden;
  }
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5555;
    padding: 5px 15px;
    background-color: #fff;
    box-shadow: 0px 3px 25px #0000001a !important;
    min-height: 69px;
    .notif-unread-count {
      position: absolute;
      margin-top: -28px;
      margin-left: 12px;
      background: #ef5350;
      color: #fff;
      font-size: 10px;
      border-radius: 50%;
      padding: 2px;
      width: 18px;
      height: 18px;
      text-align: center;
    }
    .logo-mob {
      img {
        width: 50px;
      }
      position: relative;
      font-size: 28px;
      font-weight: 500;
      display: flex;
      align-items: center;
      letter-spacing: -2px;
      color: #2f5266;
    }
  }
  .side-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    z-index: 55555;
    right: 0;
    transform: translate(100%);
    transition: 0.3s linear;
    .menu-bg {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #d1d1d1b8;
    }
    .left-side-menu {
      width: 100%;
      min-height: 100vh;
      max-width: 300px;
      background-color: #fff;
      position: relative;
      z-index: 222;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
        }
      }
      .menu-header {
        padding: 15px 15px 0 15px;
        height: 85px;
        p {
          font-size: 10px;
          letter-spacing: 2px;
        }
        .close-icon {
          position: absolute;
          right: 25px;
          top: 25px;
          width: 16px;
          height: 16px;
          opacity: 1;
          &:before {
            position: absolute;
            left: 15px;
            content: " ";
            height: 16px;
            width: 2px;
            background-color: #2f5266;
            border-radius: 4px;
            transform: rotate(45deg);
          }
          &:after {
            position: absolute;
            left: 15px;
            content: " ";
            height: 16px;
            border-radius: 4px;
            width: 2px;
            background-color: #2f5266;
            transform: rotate(-45deg);
          }
        }
      }
      .menu-body {
        flex: 1;
        padding: 15px;
        font-size: 14px;
        overflow-y: auto;
        max-height: calc(100vh - 170px);
        .user-mobile-info {
          display: flex;
          margin-bottom: 15px;
          margin-top: 15px;
          .roundedimg {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          h4 {
            font-weight: 600;
            font-size: 16px;
            padding-left: 10px;
          }
        }
        .menu {
          li {
            a {
              padding: 10px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              border-bottom: 1px solid #cccccc4a;
              &:after {
                content: "";
                background: url(https://d310ox1f4zno4b.cloudfront.net/static-content/arrow.png.webp)
                  no-repeat center;
                background-size: 10px;
                height: 14px;
                width: 14px;
                display: inline-block;
                transform: rotate(-90deg);
              }
            }
            &:last-child {
              a {
                border-bottom: 0;
              }
            }
            &:first-child {
              a {
                &:after {
                  // display: none;
                }
              }
            }
          }
        }
        .menu2 {
          li {
            display: flex;
            border-bottom: 1px solid #cccccc4a;
            align-items: center;
            a {
              flex: 1;
              border-bottom: 0;
              padding-left: 5px !important;
              &:after {
                display: none;
              }
            }
            img {
              width: 30px;
              height: 30px;
              display: none;
            }
          }
        }
        .action-btns {
          padding: 10px;
          li {
            a {
              border: 1px solid #2f5266;
              color: #2f5266;
              padding: 10px;
              border-radius: 40px;
              text-align: center;
              display: block;
              margin: 15px 0;
            }
            .active {
              background-color: #e1bf42;
              border-color: #e1bf42;
              color: #fff;
            }
          }
        }
        .already-text {
          font-size: 14px;
          text-align: center;
          margin: -5px 0 0 0;
        }
      }
      .menu-footer {
        text-align: center;
        padding: 10px;
        height: 85px;
        h3 {
          text-transform: uppercase;
          text-align: center;
          font-size: 12px;
          margin: 0;
        }
        p {
          margin: 4px 0;
          font-size: 12px;
        }
      }
      .menu-mobile-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #fff;
        transform: translate(-100%);
        transition: 0.3s linear;
        .menu-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          padding: 15px !important;
          .backarrow {
            img {
              transform: rotate(90deg);
            }
          }
          label {
            font-weight: 600;
          }
          .close-icon {
            position: relative;
            top: 0;
            right: 10px;
          }
        }
        .menu-body {
          max-height: calc(100vh - 60px);
          height: 100%;
          padding-top: 0;
          padding-bottom: 0;
          .menu-block {
            border-bottom: 1px solid #cccccc45;
            padding: 15px 0;
            label {
              font-weight: 600;
            }
            ul {
              padding: 5px 0;
              li {
                a {
                  display: block;
                  padding: 5px 0;
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
          }
          .menu-socail-block {
            ul {
              display: flex;
              li {
                margin-right: 10px;
                img {
                  width: 36px;
                }
              }
            }
          }
          .start-blk {
            h3 {
              font-size: 16px;
              text-align: center;
            }
            ul {
              li {
                text-align: center;
                a {
                  background-color: rgb(225, 191, 66);
                  color: #fff;
                  padding: 10px 35px;
                  display: inline-block;
                  border-radius: 30px;
                }
              }
            }
          }
        }
      }
      .menu-mobile-block.open {
        transform: translate(0);
      }
    }
  }
  .side-menu.open {
    transform: translate(0);
  }
}
