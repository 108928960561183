.bayan-header {
	padding: 6px 0;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 5555;
	box-shadow: 0px 3px 6px #0000000a;
	.w3-btn.open {
		i {
			transform: rotate(180deg);
		}
	}
	.container {
		max-width: 1500px;
		margin: 0 auto;
		padding: 0 15px;
	}
	.nav {
		display: flex;
		align-items: center;
	}
	.inner-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo-area {
		position: relative;
		font-size: 32px;
		font-weight: 600;
		display: flex;
		align-items: center;
		letter-spacing: -3px;
		color: #2f5266;
		max-width: 160px;
		img {
			height: 100%;
			max-height: 64px;
			object-fit: contain;
			object-position: left;
		}
	}
	.nav-area {
		margin: 10px 15px;
		position: relative;

		.w3-dropdown-click {
			background-color: transparent;
			position: static;
			.w3-btn {
				outline: unset;
				color: #2f5266 !important;
				margin: 0 6px;
				-moz-transition: 0.3s linear;
				-webkit-transition: 0.3s ease-out;
				transition: 0.3s linear;
				padding: 6px 11px;
				&:hover {
					box-shadow: 0px 5px 15px #00000022 !important;
				}
				.uniCode {
					background: url("https://d310ox1f4zno4b.cloudfront.net/static-content/arrow.png.webp") no-repeat center;
					background-size: 8px;
					height: 12px;
					width: 12px;
					margin-left: 4px;
					display: inline-block;
				}
			}
			.w3-dropdown-content {
				border-radius: 5px;
				left: 0;
				.dropdown-area {
					display: flex;
					width: 800px;
					margin: 30px 10px;
					.col {
						flex: 0 0 33.33%;
						max-width: 33.33%;
						padding: 0 15px;
						border-right: 1px solid #eee;
						h3 {
							font-size: 16px;
							text-align: center;
							color: #2f5266;
							margin: 20px 0;
						}
						.education-program-sub-headings {
							.top-edu {
								display: flex;
								img {
									max-width: 30px;
								}
							}
						}
						.admission-buttons-align{
							width: 320px;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}
						.btn-request-info{
							color: white;
						}
						.bottom-edu {
							margin-top: 10px;
							div {
								display: flex;
								align-items: center;
								font-size: 12px;
							}
							img {
								max-width: 24px;
								margin: 0 5px;
							}
							a:link {
								text-decoration: none;
							}
						}
						.degree-program-button {
							border: 1px solid #2f5266;
							padding: 10px;
							border-radius: 20px;
							text-align: center;
							display: inline-block;
							min-width: 150px;
							transition: 0.3s linear;
							&:hover {
								background-color: #e1bf42;
								border-color: transparent;
								color: #fff !important;
								text-decoration: none;
							}
						}
						a {
							font-size: 14px;
							padding: 4px;
							display: block;
							&:hover {
								text-decoration: underline;
							}
						}
						&:last-child {
							border-right: 0;
						}
					}
					.education-program-heading,
					.session-program-heading,
					.degree-program-heading {
						text-align: left;
						a {
							font-weight: 500;
							font-size: 16px;
							margin-bottom: 5px;
							display: block;
						}
						h3 {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	.login-area {
		display: flex;
		position: relative;
		.login-btn {
			font-weight: 400;
			letter-spacing: 1.1px;
		}
		.register-btn {
			margin-left: 15px;
			font-weight: 500;
			letter-spacing: 1.3px;
			&.primary {
				display: flex;
				align-items: center;
				margin-right: 10px;
				&:hover {
					// color: rgb(225, 191, 66) !important;
					color: #e1bf42db !important;
					text-decoration: underline;
				}
			}
			&.secondary {
				&:hover {
					// color: rgb(46, 82, 102) !important;
				}
			}
		}
		.bayan-donate-btn1 {
			border-radius: 43px;
			border: none;
			width: 100px;
			height: 40px;
			color: #fff;
			background-color: #2f5266;
			opacity: 1;
			display: inline-block;
			text-align: center;
			padding: 9px;
			cursor: pointer;
			font-weight: 500;
			&:hover {
				background-color: #2f5266b3 !important;
			}
		}
		.login-block {
			.login-dropdown {
				position: absolute;
				min-height: 200px;
				width: 320px;
				background-color: #2f5266;
				top: 48px;
				right: 0;
				border-radius: 0 0 8px 8px;
				display: none;
				max-height: 442px;
				overflow: hidden;
			}
			.login-dropdown-open {
				display: block;
			}
			.login-page {
				min-height: auto !important;
				.login-btn {
					margin-top: 10px !important;
				}
				.box {
					padding: 20px 0 10px 0;
					h1,
					& > img {
						display: none;
					}
				}
				.reverse {
					display: flex;
					flex-direction: column-reverse;
				}
			}
		}
	}
	.bayan-donation-btn-head {
		.bayan-donate-btn {
			border-radius: 6px;
			border: none;
			width: 105px;
			height: 40px;
			color: #fff;
			background-color: #fff;
			border: 1px solid #2f5266;
			color: #2f5266;
			opacity: 1;
			display: inline-block;
			text-align: center;
			padding: 9px;
			cursor: pointer;
			font-weight: 500;
			// &:hover {
			// 	background-color: #e1bf42db !important;
			// }
		}
		.bayan-apply-btn {
			border-radius: 6px;
			border: none;
			width: 105px;
			height: 40px;
			color: #fff;
			background-color: #2f5266;
			opacity: 1;
			display: inline-block;
			text-align: center;
			padding: 9px;
			cursor: pointer;
			font-weight: 500;
			margin-left: 10px;
		}
		.bayan-donate-btn1 {
			width: 120px;
			height: 48px;
			color: #fff;
			background-color: #fff;
			opacity: 1;
			display: inline-block;
			text-align: center;
			padding: 12px;
			cursor: pointer;
			border: 1px solid rgb(39, 151, 255);
			color: rgb(39, 151, 255);
			border-radius: 7px;
			font-weight: 500;
		}
	}
}
.login-btn-1 {
	&:hover {
		text-decoration: underline;
	}
}
