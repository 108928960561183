.main-container {
  background-color: #f2f2f2;
  margin: 0%;
  padding: 0px 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    padding: 0px 70px;
  }
  @media screen and (max-width: 480px) {
    margin: 0;
    padding: 0;
  }
  .headings {
    color: #234155;
    text-align: center;
    margin-top: 60px;
    font: normal normal bold 32px/48px Poppins;
    @media screen and (max-width: 480px) {
      margin-top: 30px;
    }
  }
  .leader-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px 0;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin: 20px 20px;
    }

    .image-container {
      flex: 10%;
      width: 274px;
      height: 274px;
      @media screen and (max-width: 480px) {
        margin-bottom: 20px;
        align-self: center;
        width: 189px;
        height: 189px;
      }
      img {
        border-radius: 10px;
        @media screen and (min-width: 1024px) {
          max-width: 274px;
        }
      }
    }
    .discription {
      flex: 40%;
      text-align: left;
      color: #234155;
      opacity: 1;
      .link {
        &:hover {
          text-decoration: underline;
        }
      }
      h3 {
        margin: 0 0;
        font: normal normal 600 32px/28px Poppins;
      }
      h6 {
        font: normal normal medium 20px/30px Poppins;
      }
      p {
        font: normal normal normal 18px/27px Poppins;
      }
      @media screen and (max-width: 1024px) {
        h3 {
          font: normal normal 600 22px/28px Poppins;
        }
        margin-left: 70px;
      }
      @media screen and (max-width: 800px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
        margin-left: 0;
        h6 {
          text-align: center;
        }
        h3 {
          font: normal normal 600 22px/33px Poppins;
          text-align: center;
        }
        p {
          font: normal normal normal 16px/25px Poppins;
        }
      }
    }
  }
}

////////////////////////////////////////

.backbtn {
  font-size: 24px;
  margin-bottom: 10px;
  &:before {
    content: "";
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
    height: 13px;
    width: 13px;
    display: inline-block;
    border-color: #234155;
    border-style: solid;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
}
.leader {
  .container {
    margin-top: 30px;
    .dr-new {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 580px) {
        flex-direction: column;
      }

      .left {
        max-width: 200px;
        padding: 0 30px 0 0;
        display: flex;
        flex-direction: column;
        img {
          border-radius: 6px;
        }
        h5 {
          font-weight: 600;
        }
      }
      .right {
        margin-top: 32px;
        font-size: 14px;
        @media (max-width: 480px) {
          margin-top: 0;
        }
        flex: 1;
        h2 {
          font-weight: 600;
        }
        ul {
          padding-left: 15px;
        }
      }
    }
  }
}
