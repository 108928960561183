.bayan-faculty {
    padding: 40px 0;
    img {
        max-width: 100%;
    }
    .fac-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: grayscale(100%);
        opacity: 0.3;
        display: inline-block;
        max-width: 120px;
        -moz-transition: .3s linear;
        -webkit-transition: .3s ease-out;
        transition: .3s linear;
        max-height: 50px;
        object-fit: contain;
      }
      span {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        max-height: 50px;
        margin: 10px;
        &:hover {
          img {
            filter: none;
            opacity: 1;
          }
        }
        &:nth-child(1) {
          img {
            max-height: 70px;
          }
        }
        &:nth-child(3) {
          img {
            max-height: 30px;
          }
        }
        &:nth-child(5) {
          max-height: 45px;
        }
      }
    }
    .inner-faculty {
      display: flex;
      // margin: 20px -10px;
      width: 100%;
      .slick-prev {
        left: -10px !important;
      }
      .slick-next {
        right: -10px !important;
      }
      .slick-slide {
        border-radius: 10px;
        position: relative;
        margin: 15px;
        
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          background-color: #000;
        }
        .name {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 18px;
            background-color: #00000059;
        }
      }
    }
  .see-all {
    a {
      padding: 12px 15px;
      color: #fff;
      background-color: #E1BF42;
      border-radius: 30px;
      width: max-content;
      text-align: center;
    }
  }
  .faculty-btn {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .bayan-faculty .inner-faculty {
    margin: 20px 0 !important;
  }
  .bayan-faculty .fac-logos {
    flex-wrap: wrap;
  }
  .bayan-faculty .fac-logos span {
    margin: 10px 0 0 0!important;
  }
  .bayan-faculty .fac-logos span img {
    max-width: 90px;
    object-fit: scale-down;
  }
  .bayan-faculty .fac-logos span:nth-child(3) img {
    max-height: 25px;
  }
  .bayan-faculty .see-all {
    a {
      background-color: #2E5266;
      width: 100%;
    }
  }
  .bayan-faculty .faculty-btn {
    margin-top: 0;
  }
}
