.bayan-sub-banner {
	padding: 15px 0;
	display: flex;
	align-items: center;
	color: #fff;
	margin: 0 -15px;
	.byan-sub-banner-holder {
		display: flex;
		width: 33.33%;
		align-items: center;
		padding: 0 15px;
		.icon-container {
			position: relative;
			height: 70px;
			width: 70px;
			min-width: 70px;
			background-color: #ffffff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				position: absolute;
				object-fit: scale-down;
			}
		}
		.text-holder {
			margin-left: 10px;
			font-size: 12px;
		}
	}
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
		.bayan-sub-banner {
			.byan-sub-banner-holder {
			.text-holder {
				.text-container {
					p {
						font-size: 13px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.bayan-sub-banner {
		height: 325px;
		min-height: 325px;
		flex-direction: column;
		.byan-sub-banner-holder {
			flex-basis: 100%;
			height: 70px;
			max-height: 70px;
			justify-content: center;
			.text-holder {
				width: 70%;
			}
		}
	}
}
