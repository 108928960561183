.bayan-classes {
    padding: 40px 0;
    color: #fff;
    img {
        max-width: 100%;
    }
    h1 {
        color: #fff;
    }
    .our-class {
        display: flex;
        max-width: 960px;
        margin: 20px auto;
        margin-bottom: 20px;
        
        .inner-block {
            width: calc(100% - 30px) !important;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            // box-shadow: 0px 3px 6px #00000029;
            outline: none !important;
            
            .detail {
                padding: 25px 15px;
                .btn-in {
                    .hidden {
                        display:none;
                    }
                }
                p {
                    min-height: 62px;
                }
            }
            h2 {
                color: #303336;
                font-size: 16px;
                font-weight: 600;
                margin: 0 0 10px 0;
                min-height: 45px;
            }
            p {
                font-size: 14px;
                color: #7B8591;
                margin: 0;
                min-height: 15px;
                    /* min-height: 105px; */
            }
        }
    }
}
.bayan-online {
    background-color: #f9f9f9;
    .headingF {
        font-size: 40px;
        font-weight: 600;
        color: #2F5266;
    }
    p.class-text {
        color: #2F5266;
        max-width: 960px;
        margin: auto;
    }
    .slick-slider {
        .slick-prev {
            left: -25px;
        }
        .slick-next {
            right: 8px;
        }
    }
    .see-all {
        text-align: center;
        margin-top: 20px;
        a {
            background-color: #E1BF42;
            color: #fff !important;
            display: inline-block;
            padding: 12px 30px;
            border-radius: 40px;
            font-weight: 500;
            margin: 15px 0;
        }
    }
}
@media (max-width: 1024px) {
    .bayan-classes .our-class .slick-slider .slick-prev {
        left: -30px !important;
    }
}
@media (max-width: 768px) {
    .bayan-classes .our-class .slick-slider .slick-prev,
    .bayan-classes .our-class .slick-slider .slick-next {
        display: none !important;
    }
    .bayan-classes .our-class .slick-slider .slick-prev {
        left: 0px !important;
    }
    .bayan-online .slick-slider .slick-next {
        right: 25px !important;
    }
}
@media (max-width: 767px) {
    .bayan-classes .our-class .slick-slider .slick-prev,
    .bayan-classes .our-class .slick-slider .slick-next {
        display: block !important;
    }
    .bayan-classes .our-class .inner-block {
        max-width: 70%;
        margin: 0 15px;
    }
    .bayan-online .slick-slider .slick-next {
        right: 0px !important;
    }
}

.btn-in {
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 8px;
    margin-top: 10px;
}
.btn-green {
    background: #65C08B;
}
.btn-yellow {
    background: #e1bf42;
}

.class-description{
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 5px 0px; 
    overflow: hidden;
}
