@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


/*Media Query Breakpoints Ends*/

$min: min-width;
$max: max-width;
$small: 480px;
$small-plus: 560px;
$medium: 768px;
$large: 999px;

/*theme-font-family*/

$theme-font-lato: Lato;

/*Text colors*/

$colorWhite: #fff;
$colorBlack: #000;
.bayan-popup {
  .bayan-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .bayan-popup-container-holder {
      position: relative;
      .back-link-holder {
        position: absolute;
        top: 5px;
        right: 5px;
        left: auto;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: red;
      }
    }
    @media (max-width: 768px) {
      padding: 15px;
      .bayan-popup-container-holder {
        width: 100%;
        iframe {
          width: 100%;
          height: 348px;
        }
      }
    }
  }
}

.popup-back-link {
  width: 1.5em;
  height: 1.5em;
  max-width: 13px;
  max-height: 13px;
  display: block;
  vertical-align: middle;
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  &:after,
  &:before {
    background: $colorWhite;
    content: "";
    height: 2px;
    max-width: calc(13px * 1.414);
    position: absolute;
    top: 0;
    transition: 0.05s ease-in-out background-color;
    width: calc(1.5em * 1.414);
  }
  &:before {
    left: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
  }
  &:after {
    right: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
  }
}
.webinar-video-iframe {
  width: 600px;
  height: 400px;
}