.bayan-seminar {
  .seminar-container {
    .fullwide {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      margin-bottom: 24px;
    }
    .margin-top-10{
      margin-top: 10px;
    }
    .backbtn:before {
      content: "";
      border-width: 0 2px 2px 0;
      transform: rotate(135deg);
      height: 13px;
      width: 13px;
      display: inline-block;
      border-color: #234155;
      border-style: solid;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
    .top-container {
      display: flex;
      justify-content: space-between;
      .left-section {
      }
      .right-section {
        width: 275px;
        height: 235px;
        max-width: 275px;
        max-height: 235px;
      }
    }
    .books-container {
      display: flex;
      align-items: center;
      .text-area {
        width: 40%;
        .custom-h4 {
          // line-height: 1px;
          margin: 0px 0 0 0;
          padding: 10px 0 0 0;
        }
      }
      .img-area {
        width: 70px;
        height: 80px;
        margin-left: 50px;
        // &.left-space {
        //   margin-left: 150px;
        // }
      }
    }
    .seminar-headings {
      color: #2e5266;
    }
    .seminar-accordians {
      margin-top: 10px;
    }
    .seminar-table {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      overflow-x: auto;
    }

    .seminar-table th,
    .seminar-table td {
      border: 1px solid #ddd;
      padding: 18px;
    }
    .seminar-table th {
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: left;
      background: #2e5266 0% 0% no-repeat padding-box;
      border-radius: 5px 5px 0px 0px;

      color: white;
    }
    .seminar-table td {
      background: #9eb1bb40;

      padding: 10px;
    }
    margin: 40px 150px;
    @media (max-width: "1024px") {
      margin: 40px 100px;
    }
    @media (max-width: "780px") {
      margin: 40px 80px;
    }
    @media (max-width: "480px") {
      margin: 40px 15px;

      .books-container {
        .text-area {
          width: 70%;
        }
      }
    }
  }
}
