.download-app-page {
  padding-bottom: 50px;
  .inner {
    text-align: center;
    font-family: Poppins, sans-serif;
  }

  .inner h2 {
    letter-spacing: 0px;
    color: #2E5266;
    text-transform: capitalize;
    font-size: 36px;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 26px;
    }
  }
  .inner p {
    letter-spacing: 0px;
    color: #2E5266;
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .inner ul {
    display: inline-flex;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #fff;
    padding: 5px;
    @media (max-width: 767px) {
      width: 130%;
      overflow: auto;
      position: relative;
      left: -15%;
    }
  }

  .inner ul li {
    list-style: none;
    padding: 5px;
    display: flex;
  }
  .inner ul li img {
    width: 180px;
  }
  
  img {
    max-width: 100%;
  }

  .download-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
  }
  .note-text {
    margin: 0 auto;
    text-align: center;
    max-width: 320px;
    color: #2E5266;
  }

  .enter-phone {
    display: flex;
    align-items: flex-end;
    margin: 20px auto;
    max-width: 360px;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 10px;
    }
     input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 2px;
      border: 0;
      padding: 10px;
      flex: 1;
    }
     label {
      color: #2E5266;
      margin-bottom: 10px;
    }
     a {
      background: #E1BF42 0% 0% no-repeat padding-box;
      border-radius: 22px;
      opacity: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      color: #fff !important;
      text-decoration: none;
    }
  }



}







