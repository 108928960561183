.admissions-page {
    background: url("https://d310ox1f4zno4b.cloudfront.net/static-content/admissions-bg.png.webp");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0;
    font-size: 14px;
    .container {
        max-width: 768px;
    }
    .accordion-question {
        font-weight: 500;
    }
    .rsvp-body-text {
        padding: 20px !important;
        background-color: #fff;
        h1 {
            letter-spacing: 1.28px;
            color: #234155;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
        }
        .admin-links {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .in-link {
                margin-right: 20px;
                width: 70%;
                p {
                    font-weight: 600;
                    margin: 8px 0;
                    font-size: 14px;
                    span {
                        display: inline-block;
                        margin-right: 10px;
                        color: #2e5266;
                    }
                    a {
                        font-weight: 600;
                        margin-right: 10px;
                        color: #e1bf42;
                        cursor: pointer;
                    }
                }
                @media (max-width: 768px) {
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        .apply-btn {
            background-color: #e1bf42;
            color: #fff;
            display: inline-block;
            padding: 10px 25px 6px 25px;
            border-radius: 30px;
            cursor: pointer;
            white-space: nowrap;
            margin: 15px 0px;
            text-align: center;
        }
        .request-btn{
            margin-left: 30px;
            background-color: #2f5266;
        }
        @media (max-width: 768px) {
            .request-btn{
                margin-left: 0px;
                background-color: #2f5266;
            }
        }
    }
    .gray-admin-block {
        background-color: rgb(246, 246, 246);
        padding: 15px;
        margin: 20px 0;
    }
}

$colorWhite: #fff;
