.app-content {
    padding-bottom: 0 !important;
    min-height: auto !important;
    .top-footer-new-page {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: url(/icons/community.svg) no-repeat;
    // background-position: right 70px;
    // background-size: 500px;
    background-color: #ECEFF1;
    h1 {
        text-align: left;
        margin-bottom: 0;
    }
    p {
        color: #2F5266;
        font-weight: 400;
        font-size: 20px;
        margin-top: 5px;
    }
    input {
        background-color: #fff;
        border: 0;
        padding: 10px;
        min-width: 300px;
        border-radius: 2px;
    }
    .login-btn {
        background-color: #E1BF42;
        color: #fff;
        display: inline-block;
        padding: 7px 20px;
        border-radius: 2px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 40px;
        margin-left: 10px;
    }
    .mt2 {
        margin-top: 20px;
    }
}

}
