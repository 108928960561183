.footer  {
	background-color: #ECEFF1;
	color: #fff;
	width: 100%;
	padding: 0;
	.top-footer {
		padding-bottom: 40px;
		display: block;
		background: url('https://bayan.s3-us-west-2.amazonaws.com/static-content/community.svg') no-repeat;
		background-position: right 25%;
		background-size: 500px;
		ul {
			padding: 0;
			text-align: center;
			li {
				list-style: none;
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 20px;
				span {
					display: block;
					color: #2E5266;
					font-weight: 600;
					margin: 10px;
					text-align: center;
				}
				a {
					display: flex;
					min-width: 240px;
					align-items: center;
					justify-content: center;
					text-align: center;
					font-size: 13px;
				}
			}
			.alumni {
				span {
					color: #2E5266;
				}
				a {
					background-color: #2E5266;
				}

			}
			.chat {
				span {
					color: #33656B;
				}
				a {
					background-color: #33656B;
				}
			}
			.trending {
				span {
					color: #857665;
				}
				a {
					background-color: #857665;
				}
			}
		}
	}
	.top-footer-new {
		padding: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/community.svg) no-repeat;
    	background-position: right 30px;
    	background-size: 500px;
		h1 {
			text-align: left;
			margin-bottom: 0;
		}
		p {
			color: #2F5266;
			font-weight: 400;
			font-size: 20px;
			margin-top: 5px;
		}
		input {
			background-color: #fff;
			border: 0;
			padding: 10px;
			min-width: 300px;
			border-radius: 2px;
		}
		.login-btn {
			background-color: #E1BF42;
			color: #fff;
			display: inline-block;
			padding: 7px 20px;
			border-radius: 2px;
			margin-top: 10px;
			font-size: 14px;
			font-weight: 600;
			border-radius: 40px;
			margin-left: 10px;
		}
	}
	.bottom-footer {
		background-color: #fff;
		padding: 30px;
		text-align: center;
		span {
			display: block;
			color: #2F5266;
			font-size: 12px;
			font-weight: 500;
		}
		a,
		p,
		.footerTitle {
			letter-spacing: 3.99px;
			color: #2F5266;
			font-size: 12px;
			font-weight: 500;
			margin-top: 20px;
			@media (max-width: 768px) {
				margin-top: 10px;
			}
		}
		a,
		p {
			letter-spacing: 0.68px;
			margin: 30px 0 0 0;
		}
		.second-last {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			.left {
				text-align: left;
				margin-right: 50px;
			}
			.bottom-edu {
				margin-left: 50px;
				.contact-section {
					  display: flex;
						align-items: center;
						justify-content: flex-start;
						img {
							    margin: 3px 8px 0 0;
						}
						.fit-content {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							margin: 0;
						}
				}
			}
		}
	}
	.logo-area {
		position: relative;
		font-size: 32px;
		font-weight: 600;
		display: flex;
		align-items: center;
		letter-spacing: -3px;
		color: #2F5266;
		span {
			font-size: 32px;
			img {
			height: 100%;
			max-height: 64px;
			object-fit: contain;
			object-position: left;
			}
		}
	}
}
@media (max-width: 768px) {
	.footer .top-footer {
		background: inherit;
	}
	.footer .top-footer ul li a,
	.footer .top-footer ul li {
		width: 100%;
	}

	.logo-area {
		justify-content: center;
	}

	.bottom-footer {
		.second-last {
			display: flex;
			align-items: center !important;
			justify-content: center;
			flex-direction: column;
			.left {
				text-align: center !important;
				margin-right: 0 !important;
			}
			.bottom-edu {
				margin-left: 0 !important;
				margin-top: 30px;
				.contact-section {
					  display: flex;
						align-items: center !important;
						justify-content: center !important;
						img {
							    margin: 7px 8px 0 0 !important;
						}
				}
			}
		}
	}
}
@media (max-width: 480px) {
	.footer .top-footer-new input {
		width: 100% !important;
	}
	.footer .top-footer-new .login-btn {
		width: 100% !important;
		text-align: center;
		margin-left: 0;
	}
}
