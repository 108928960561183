.about-page {
  background: #fff !important;
  img {
    max-width: 100% !important;
  }
  .about-detail {
    max-width: 800px;
    margin: 30px auto;
    text-align: center;
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin: 20px auto;
      max-width: 600px;
    }
    h2 {
      margin: 20px auto;
      max-width: 600px;
    }
    p, ul {
      text-align: left;
      margin-bottom: 20px;
      color: #2e5266;
    }
    .bayan-mission {
      img {
        max-width: 60px;
      }
    }
    .vision {
      max-width: 450px;
      margin: auto;
      h3 {
        letter-spacing: 5.92px;
        color: #2e5266;
        opacity: 0.49;
        font-weight: 400;
        margin-top: 40px;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
        color: #2e5266;
      }
    }
    .sm-text {
      font-size: 19px;
    }
    .mini-text {
      font-size: 10px;
      font-style: italic;
      text-align: center;
    }
    .graph-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        margin: 0 15px;
        .box {
          height: 10px;
          width: 10px;
          display: inline-block;
          background-color: #ccc;
          margin-right: 5px;
        }
        .blue-box {
          background-color: #628cbb;
        }
        .yellow-box {
          background-color: #d1bf4e;
        }
      }
    }
  }
  .stats-detail-block {
    display: flex;
    max-width: 960px;
    margin: 80px auto;
    .col50 {
      padding: 0 35px;
      &:first-child {
        border-right: 1px solid #234155;
      }
      .in-date {
        margin-bottom: 50px;
        h2 {
          color: #234155;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 40px;
          line-height: 40px;
          margin-top: 0;
        }
        h3 {
          color: #e1bf42;
          text-transform: capitalize;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 600;
          margin: 20px 0 0 0;
        }
        p {
          font-size: 14px;
          margin-top: 0;
        }
      }
    }
    .col-left {
      h2 {
        text-align: right;
      }
    }
    .col-right {
      h2 {
        margin-top: 40px !important;
      }
    }
  }
  .accreditation-block {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    img {
      margin-top: 15px;
    }
    p {
      text-align: left;
      font-size: 14px;
    }
  }
  .partner-schools-block {
    padding: 50px 0;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    p {
      text-align: left;
    }
    .dflex {
      display: flex;
      margin: 40px 0;
      .leftimg {
        min-width: 120px;
      }
      .leftdetail {
        padding-left: 20px;
        h2 {
          text-align: left;
          color: #e1bf42;
          font-size: 20px;
          line-height: 22px;
          text-transform: uppercase;
          font-weight: 600;
          margin: 0;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
  #stats {
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    background-color: #2f5266;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    .headingF-sm {
      color: #2f5266;
    }
    .about-detail {
      margin-top: 0px;
    }
    .mob-white-blk {
      background-color: #fff;
      padding: 20px;
      border-radius: 4px;
      margin-bottom: 20px;
      .sm-img {
        max-width: 60px;
      }
      #stats {
        margin-top: 10px;
      }
    }
    #stats-detail {
      display: none;
    }
    #partner-schools {
      padding: 0 !important;
    }
    .dflex {
      flex-direction: column;
      margin: 10px 0 !important;
      .leftimg {
        margin-bottom: 20px;
      }
      .leftdetail {
        padding-left: 0 !important;
      }
    }
  }
}

.profile-page-header {
  display: flex;
  justify-content: space-between;
}

.student-body-profile-content {
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
  width: 100%;
  column-gap: 5%;
  .student-body-profile-left {
    overflow-wrap: break-word;
    width: 30%;
  }

  .student-body-profile-right {
    width: 70%;
  }
  .profile-page-content-h2 {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    color: #e1bf42;
  }

  .profile-page-content-para {
    font-size: 18px;
    font-weight: 500;
    text-align: center !important;
    justify-content: center;
  }
}

.profile-page-header-h1 {
  font-weight: 600;
  font-size: 30px;
}

@media (max-width: 767px) {
  .profile-page-header-image {
    display: none;
  }
  .student-body-profile-content {
    flex-direction: column;
    .student-body-profile-left {
      width: 100%;
    }
    .student-body-profile-right {
      width: 100%;
    }
  }
}
