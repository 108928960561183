.layout {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  .content {
    flex: 1;
    padding-top: 74px;
  }
  @media (max-width: 1024px) {
    .content {
      padding-top: 68px;
    }
  }
}

iframe#XCURMUKD {
    position: fixed;
    right: -71px;
    bottom: 186px;
    transform: rotate( -90deg);
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
}
