#sc-launcher {
  z-index: 1;
}
.sc-header {
  background: #234155;
  min-height: 60px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
  .sc-header--img {
    background: #fff;
    border-radius: 50%;
    align-self: center;
    padding: 0;
    width: 45px;
    cursor: pointer;
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  
  .sc-header--team-name {
    align-self: center;
    padding: 10px;
    flex: 1;
    user-select: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    word-break: break-all;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
  
  .sc-header--close-button {
    width: 30px;
    align-self: center;
    height: 30px;
    margin-right: 0;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background: #4e7894;
    }
    img {
      width: 100%;
      height: 100%;
      padding: 8px;
      box-sizing: border-box;
    }
  }
  .sc-header--back-button {
    display: none;
  }
}

@media (max-width: 767px) {
  #sc-launcher {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .sc-header {
    background: #fff;
    color: #234155;
    border-radius: 0;
    box-shadow: 0px 0px 6px #2f2f2f;
    .sc-header--close-button {
      // display: none;
      visibility: hidden;
    }
    .sc-header--back-button {
      display: block;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      transition: .3s linear;
      &::after {
        content: ">";
        display: block;
        color: #234155;
        transform: rotate(180deg);
        opacity: 1;
        transition: .3s linear;
        font-size: 30px;
      }
    }
    .sc-header--team-name {
      text-align: center;
    }
  }
}
