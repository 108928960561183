.preview-modal{
  width: 100%;
  height: 100%;
  margin:0 auto;
  display:table;
  position: fixed;
  left: 0;
  right:0;
  top: 50%; 
  z-index: 999;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  transform:translateY(-50%);
  z-index: 100000;
  .profile-preview-wrapper {
      margin: 20px auto;
      max-width: 420px !important;
      width: 100%;
  }
}