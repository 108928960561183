.notif-list-container,
.mobile-inbox-container {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001c;
    position: absolute;
    top: 80px;
    right: -7%;
    z-index: 0;
    border-radius: 8px;
    padding: 14px 0;
    width: 290px;
    height: 450px;
    transition: transform 0.25s ease-out;
    transform: scaleY(1);
    transform-origin: top;
    // overflow: hidden;
    &:after {
        content: "";
        height: 20px;
        width: 20px;
        background-color: #fff;
        position: absolute;
        top: -10px;
        display: block;
        right: 12%;
        transform: rotate(45deg);
        -moz-transition: 0.3s linear;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s linear;
    }
    .list-header {
        padding: 0 10px 10px;
        .header-label {
            color: #234155;
            font-size: 16px;
            font-weight: 600;
        }
        .search-msgs {
            position: relative;
            margin: 5px 0;
            img {
                position: absolute;
                top: 14px;
                left: 15px;
                max-width: 14px;
            }
            input {
                background: #f3f3f3 0% 0% no-repeat padding-box;
                border-radius: 23px;
                border: 0;
                outline: none;
                width: 270px;
                padding: 6px 20px 6px 40px;
                margin: 5px 0;
                display: inline-block;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        .back-btn {
            display: flex;
            .back-arrow {
                margin: 8px 10px;
                height: 8px;
                width: 8px;
                display: inline-block;
                border: solid #234155;
                border-width: 0 2px 2px 0;
                transform: rotate(135deg);
            }
        }
    }
    .notif-list {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 0.5px solid #e6e6e6;
        max-height: 340px;
        overflow-y: auto;
        .unread {
            background-color: #faf4df !important;
            font-weight: 600;
        }
        .nitem {
            padding: 14px 10px;
            border-bottom: 0.5px solid #e6e6e6;
            -moz-transition: 0.2s linear;
            -webkit-transition: 0.2s ease-out;
            transition: 0.2s linear;
            position: relative;
            cursor: pointer;
            &:hover {
                background-color: #faf4df;
            }
            .ntime {
                display: block;
                margin: -10px 0 0 0;
                font-size: 10px;
                float: right;
                position: absolute;
                right: 12px;
            }
            .ncontent {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
                .nimage {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #e6e6e6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    img {
                        vertical-align: middle;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .ntext {
                    width: 75%;
                    word-break: break-all;
                    white-space: nowrap;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    .mname {
                        // font-weight: 600;
                    }
                }
                .unread-count {
                    padding: 2px 7px;
                    background-color: #ff7270;
                    color: #fff;
                    border-radius: 50%;
                    align-items: center;
                    text-align: center;
                    font-weight: 800;
                    font-size: 10px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
.notif-list-right {
    &:after {
        right: 29%;
    }
}
@media (max-width: 767px) {
    .notif-list-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        .list-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 10px;
        }
        .notif-list {
            max-height: 100%;
            .nitem {
                padding: 18px 10px;
            }
        }
    }
    .mobile-inbox-container {
        background: #fff;
        box-shadow: unset;
        position: absolute;
        top: 76px;
        right: unset;
        z-index: 1;
        border-radius: 8px;
        padding: 0;
        width: 100%;
        height: 100%;
        transition: unset;
        transform: unset;
        transform-origin: unset;
        border-radius: 0;
        .notif-list {
            max-height: 100%;
            .nitem {
                padding: 18px 10px;
                // background-image: linear-gradient(180deg, #e6e6e6, #fff);
                .ntime {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: right;
                    margin: -22px 0 0;
                    .next-arrow {
                        width: 20px;
                        border-radius: 50%;
                        transition: 0.3s linear;
                        &::after {
                            content: ">";
                            display: block;
                            color: #234155;
                            transform: rotate(0deg);
                            opacity: 1;
                            transition: 0.3s linear;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }
}
