.arabic-page {
  .arabic-page-container {
    display: flex;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  .left-section {
    margin-top: 40px;
    flex: 30%;
    max-width: 300px;
    padding: 20px;
    @media (max-width: 480px) {
      padding: 0%;
    }
    @media (min-width: 780px) {
      ul {
        position: sticky;
        top: 100px;
      }
    }

    ul li a {
      padding: 8px 5px;
      display: block;
    }
    ul li.active a {
      background-color: #e1bf42;
      color: white;
      font-weight: 600;
      text-align: center;
      border-radius: 30px;
      min-width: 180px;
    }
    @media (max-width: 800px) {
      padding: 40px 40px 0 40px;
      position: relative;
      max-width: 1500px;
      margin: 10px auto;
    }
    @media (max-width: 480px) {
      padding-left: 0%;
      padding-right: 0%;
    }
  }
  .right-section {
    // flex: 70%;
    padding: 40px;
    max-width: 800px;
    @media (max-width: 480px) {
      padding-top: 0%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .dated {
      .starting-date,
      .ending-date {
        img {
          width: 15px;
          height: 20px;
          margin-top: 10px;
        }
        h2 {
          font: normal normal normal 32px/48px Poppins;
        }
        p {
          line-height: 0;
          margin-left: 10px;
        }
      }
    }
    .page-heading {
      text-align: center;
      font: normal normal 600 45px/51px Poppins;
      color: #2f5266;
      @media (max-width: 480px) {
        font: normal normal 400 25px/31px Poppins;
      }
    }

    .levels-offered {
      a {
        color: #e1bf42;
        text-decoration: underline;
      }
      .levels-heading {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 12px;
      }
      .semesters {
        p {
          font-size: 18px;
          color: #2f5266;
          line-height: 0.5;
          @media (max-width: 480px) {
            line-height: 1.5;
          }
        }
      }
      .description {
        p {
          font-size: 18px;
        }
      }
      .levels-table {
        border-collapse: collapse;
        overflow-x: auto;
      }

      .levels-table th,
      .levels-table td {
        border: 1px solid #ddd;
        padding: 18px;
      }
      .levels-table th {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: left;
        background-color: #2e5266;
        border-radius: 5px 5px 0px 0px;

        color: white;
      }
      .levels-table td {
        background: #9eb1bb40;

        padding: 10px;
      }
    }
    .register-today {
      .register-heading {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 12px;
      }
      .register-table {
        border-collapse: collapse;
        overflow-x: auto;
      }
      .register-table th,
      .register-table td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      .register-table th {
        padding-top: 0px;
        padding-bottom: 0px;

        text-align: left;
        background: #2e5266 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;

        color: white;
      }
      .register-table td {
        background: #9eb1bb40;
        padding: 20px;
      }
    }
    .general-schedule {
      .general-heading {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
    .learn {
      .learn-heading {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
  }
}
