.welcome-modal {
    .modal_content {
        max-width: 600px;
        padding: 30px 50px 5px 50px;
        @media (max-width: 480px) {
            padding: 15px 0px;
            left: 10px;
            right: 10px;
        }
        .vertical-scroll {
            text-align: center;
            max-height: 500px;
            .welcome-header {
                display: inline-flex;
                align-items: center;
                margin-bottom: 10px;
                span {
                    margin-right: 15px;
                    @media (max-width: 480px) {
                        img {
                            max-width: 50px;
                        }
                    }
                }
                div {
                    text-align: left;
                    h2 {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 0;
                    }
                    p {
                        font-size: 16px;
                        margin: 0;
                        opacity: 0.4;
                    }
                    @media (max-width: 480px) {
                        h2 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
                
            }
            .card {
                padding: 0;
                border-bottom: 0;
                .user-detail-mod {
                    border-bottom: 0;
                    justify-content: center;
                    .inner {
                        width: 100%;
                        iframe {
                            height: 280px;
                        }
                    }
                    .ok-bt {
                        font-weight: 600;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}