.bayan-steps-wrapper {
  .send-btn {
    border: 1px solid #013855;
    border-radius: 22px;
    opacity: 1;
    color: #013855;
    margin-top: 15px;
    display: inline-block;
    padding: 5px 20px;
    background-color: #fff !important;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
    .col {
      padding-left: 10px;
      padding-right: 10px;
    }
    .col-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-4 {
      flex: 0 0 33.3334%;
      max-width: 33.3334%;
    }
  }
  .inline-f-b {
    display: flex;
  }
  ol {
    padding-left: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }
}

.bayan-progress-wrapper {
  font-size: 14px;
  color: #234155;
  .progress-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .progress-header {
      display: inline-flex;
      margin-bottom: 25px;
      .progress-percentage {
        width: 50px;
        margin: 0 20px 0 0;

        .CircularProgressbar {
          vertical-align: middle;
          width: 100%;
          .CircularProgressbar-trail {
            stroke: #d6d6d6;
          }
          .CircularProgressbar-path {
            stroke: #013855 !important;
            stroke-linecap: round;
            transition: stroke-dashoffset 0.5s ease 0s;
          }
          .CircularProgressbar-text {
            font-size: 24px;
            dominant-baseline: middle;
            fill: #013855 !important;
            text-anchor: middle;
          }
        }
      }
      .progress-text {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          color: #234155;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .app-sections-label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #234155;
    }
    .disable-click {
      pointer-events: none !important;
    }
    .progress-list-items {
      margin-bottom: 6px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      .check-mark-wrapper {
        width: 18px;
        height: 18px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        .check-mark {
          border-radius: 50%;
          padding: 2px;
          color: #fff;
          background: url('https://d310ox1f4zno4b.cloudfront.net/static-content/done.png.webp')
            no-repeat;
          background-size: 10px;
          background-position: center;
          background-color: #e4e4e4;
          font-size: 14px;
          min-width: 18px;
          height: 18px;
          display: inline-block;
        }
        .selected-check-mark {
          background-color: #013855 !important;
        }
      }
      .list-disable {
        color: #808080 !important;
        cursor: pointer !important;
        pointer-events: none !important;
      }
      .list-label {
        display: inline-block;
        cursor: pointer;
        margin-left: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: 766px) {
      display: none;
    }
  }
  .review-app-wrapper {
    text-align: center;
    .review-app-button {
      width: 70%;
      margin: auto;
      border-radius: 6px;
      border: 1px solid #013855;
      padding: 12px 0;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        background: #013855;
        color: #fff;
      }
    }
    .review-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

// RD design
.application-wrapper {
  .connect-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chat-img {
      position: relative;
      border-radius: 50%;
      .company-icon {
        position: absolute;
        bottom: -5px;
        right: -5px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
    p {
      color: #234155;
      font-weight: 600;
      max-width: 120px;
    }
    .chat {
      background: #013855;
      box-shadow: 0px 3px 6px #00000029;
      height: 40px;
      width: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .form-action {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 15px 0;
    flex-wrap: wrap;
    max-width: 560px;
    .back-btn {
      padding: 10px 0;
      display: inline-flex;
      justify-content: center;

      @media (max-width: 766px) {
        width: 100%;
      }
    }
    .btn {
      padding: 10px 20px;
      background-color: #013855;
      color: #fff;
      border-radius: 25px;
      min-width: 140px;
      text-align: center;
      margin-left: 15px;
      font-weight: 600;
      display: inline-flex;
    }
    .btn.active {
      background-color: #e1bf42;
      border-color: #e1bf42;
    }
    @media (max-width: 766px) {
      width: 100%;
      a {
        margin: 5px 0;
      }
    }
  }
  .card {
    box-shadow: 0px 3px 6px #00000029;
  }
  .input-group {
    margin-bottom: 25px;
    .field-label {
      font-weight: 600;
    }
    select,
    input {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 4px;
      border: 0;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      outline: none;
      font-size: 14px;
    }
    textarea {
      border: 1px solid #d9d9da;
      border-radius: 2px;
      width: 100%;
      padding: 15px;
      min-height: 100px;
      margin-top: 20px;
    }
    .react-tel-input input {
      padding-left: 48px !important;
    }
    .drag-block {
      background: #2542560a 0% 0% no-repeat padding-box;
      border: 2px dashed #234155;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      margin-top: 20px;
      font-weight: 500;
      flex-direction: column;
      text-align: center;
      span {
        margin: 5px 0;
      }
    }
    .select-date {
      max-width: 100px;
      padding: 10px;
      margin-right: 15px;
      @media (max-width: 766px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
.app-layout {
  font-size: 14px;
  .application-content {
    min-height: calc(100vh - 80px);
    background-color: #f2f2f2;
    padding-bottom: 20px;
    // display: flex;
    flex-direction: column;
    // @media (max-width: 1024px) {
    //     padding-top: 46px;
    // }
    .card {
      background: #ffffff;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    .wrapper-md {
      max-width: 1024px !important;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
    }
    .app-info {
      border-bottom: 1px solid #00000012;
      padding: 10px 0;
      margin: 20px 0;
    }
    .column {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 20px;
    }
    .column.large-8 {
      flex: 0 0 66%;
      max-width: 66%;
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .column.large-4 {
      flex: 0 0 34%;
      max-width: 34%;
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .sub-heading {
      color: #234155;
      font-weight: 600;
    }
    .review-application-btn {
      border: 1px solid #013855;
      border-radius: 22px;
      color: #013855;
      display: block;
      background-color: #fff;
      padding: 10px;
      width: 100%;
      margin: 10px auto;
      max-width: 200px;
      margin-top: 20px;
      cursor: pointer;
      @media (max-width: 766px) {
        max-width: 100%;
      }
    }
  }
}
.residency {
  .form-content {
    min-height: 410px;
    max-width: 560px;
    .input-group {
      select {
        max-width: 100%;
      }
      .field-label {
        font-weight: 400;
      }
      .inline-radio {
        display: flex;
        margin-top: 10px;
        .radio-options {
          display: flex;
          margin-right: 30px;
          .radio-button {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #234155;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.demographic {
  .form-content {
    min-height: 410px;
    .input-group {
      select {
        max-width: 100%;
      }
    }
  }
}
.toeflsrs {
  .form-content {
    .input-group {
      select {
        max-width: 100px;
      }
    }
  }
}

.field-error {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.selected {
  background: #033755 !important;
}

.begin-screen {
  color: #013855;
  .bayan-main-heading {
    display: flex;
    font-weight: 600;
    align-items: center;
    img {
      width: 80px;
      margin-right: 20px;
    }
  }
  input {
    background: #f2f2f2;
    padding: 8px 10px;
    border-radius: 5px;
    border: 0;
    margin-right: 10px;
    width: 50%;
  }
  .addbtn {
    border: 1px solid #013855;
    border-radius: 22px;
    color: #013855;
    background-color: transparent;
    padding: 6px 25px;
  }
  .begin-btn {
    background: #e1bf42;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    padding: 7px 25px;
    color: #fff;
    border: 0;
    min-width: 160px;
    text-align: center;
  }
  .block {
    border-bottom: 1px solid #cccccc70;
    padding-bottom: 15px;
    table {
      tr {
        td {
          &:first-child {
            min-width: 100px;
            font-weight: 600;
          }
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

.disable-mouse {
  pointer-events: none;
}

.multi-images {
  padding: 0;
  li {
    list-style: none;
    span {
      display: inline-flex;
      min-width: 0px;
      overflow: hidden;
      width: 130px;
      height: 130px;
      margin: 0px 6px 0px 0px;
      position: relative;
      cursor: pointer;
      img {
        object-fit: contain;
        background-color: #000;
        &.doc-icon {
          background-color: transparent;
        }
      }
      .thumb_overlay {
        display: none;
        background-color: rgba(0, 0, 0, 0.6);
        width: 130px;
        height: 130px;
        position: absolute;
        left: 0;
        top: 0;
        .thumb_cross {
          position: absolute;
          right: 10px;
          top: 5px;
          font-size: 22px;
          cursor: pointer;
          font-weight: 600;
          color: #fff;
        }
      }
      &:hover {
        .thumb_overlay {
          display: block;
        }
      }
    }
  }
}
.btn-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.beginning-screen {
  .form-content {
    min-height: 410px;
    .input-group {
      select {
        max-width: 100%;
      }
    }
  }
}

.begin-screen .css-wmw4vi-ReactDropdownSelect {
  padding: 0 !important;
  border: 0 !important;
}
.select-field-program {
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  padding-right: 25px;
  &.select-contact-pref{
    &.contact-pref-selected {
      .react-dropdown-select-input {
        display: none;
      }
    }
    .react-dropdown-select-input {
      margin: 0;
      display: block;
    }
  }
  span {
    padding-left: 10px;
    font-size: 12px;
  }
  .react-dropdown-select-input {
    display: none;
  }
  .react-dropdown-select-dropdown-handle {
    position: absolute;
    right: 10px;
  }
  .react-dropdown-select-no-data {
    color: #013855 !important;
  }
}

.text-align-center {
  text-align: center;
}
