.faculty-new-design {
  background-color: #f2f2f2;
  padding: 15px 0;
  .headingF {
    font-size: 20px;
    margin-bottom: 0;
  }
  .sub-headingF {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400;
  }
  .inner-faculty {
    flex-wrap: wrap;
    display: flex;
    max-width: 700px;
    margin: 15px auto;

    .sm-main {
      width: 33%;
      padding: 5px 15px;
      @media (max-width: 767px) {
        width: 100%;
      }
      .imgmm {
        width: 100%;
        border: 0;
        background-color: transparent;
        padding: 0;
        text-align: center;
        img {
          border-radius: 6px;
          margin-bottom: 10px;
          height: 214px;
          width: 70%;
          background-color: #000;
          object-fit: cover;
          object-position: top;
        }
        .name {
          font-weight: 600;
        }
        p {
          font-size: 14px;
          margin: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
.backbtn {
  font-size: 24px;
  margin-bottom: 10px;
  &:before {
    content: "";
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
    height: 13px;
    width: 13px;
    display: inline-block;
    border-color: #234155;
    border-style: solid;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
}
.faculty {
  .container {
    margin-top: 30px;
    .filter-btn {
      display: flex;
      max-width: 700px;
    margin: 15px auto;
      @media (max-width: 580px) {
        justify-content: center;
      }
      .staff-btn {
        &.active {
          border-bottom: 2px solid #e1bf42db;  
        }
      }
      .fac-btn {
        margin-left: 30px;
         &.active {
          border-bottom: 2px solid #e1bf42db;  
        }
      }
      .common-btn {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dr-new {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 580px) {
        flex-direction: column;
      }
      .left {
        max-width: 200px;
        padding: 0 30px 0 0;
        display: flex;
        flex-direction: column;
        img {
          border-radius: 6px;
        }
        h5 {
          font-weight: 600;
        }
      }
      .right {
        margin-top: 20px;
        font-size: 14px;
        flex: 1;
        h2 {
          font-weight: 600;
        }
        ul {
          padding-left: 15px;
        }
      }
    }
  }
}
