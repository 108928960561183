.bayan-eoy {
	background-color: #f2f2f2;
	.sub-container {
		display: flex;
		align-items: center;
		padding: 10px;
		justify-content: center;
		.pr-per {
				color: #fff;
				max-width: 400px;
				width: 100%;
				p {
					margin-bottom: 5px;
					text-align: right;
					margin-top: 0;
				}
				.progress-rd {
					height: 6px;
					width: 100%;
					background-color: #f2f2f2;
					border-radius: 6px;
					overflow: hidden;
					.pr-inner-rd {
						background-color: red;
						height: 6px;
					}
				}
			}
	}
	
	.eoy-container {
		margin: 0 auto;
		max-width: 1024px;
		padding: 0 15px;
		margin-bottom: 40px;
		.sub-container {
			background-color: #2E5266;
			width: 100%;
			height: 120px;
			margin-bottom: 20px;
		}
		@media (min-width: 780px) {
			p {
				font-size: 16px;
				line-height: 22.2px;
			}
			h2 {
				font-size: 24px;
				font-weight: 600;
				font-family: Poppins, sans-serif !important;
				line-height: 28px;
			}
		}
		display: flex;
		justify-content: center;
		flex-direction: column;
		.landing-banner {
			margin-top: 50px;
			flex: 0%;
			justify-content: center;
			height: 550px;
			max-height: 550px;;
		}
		.eoy-description {
			h2 {
				text-align: center;
				max-width: 560px;
				margin: 20px auto;
				font-weight: 500;
			}
			p {
				letter-spacing: 1px;
				line-height: 26px;
			}
		}

	}
	.eoy-footer {
		background-color: #2d5265;
		color: #ffffff;
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: auto;
		padding: 10px;
		.inner-wrap {
			margin: 0 auto;
			max-width: 1024px;
			padding: 0 15px;
			display: flex;
			align-items: center;
			@media (max-width: 767px) {
				    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

				p {
					margin-right: 0 !important;
					width: 100%;
					margin-top: 0;
				}
			}
			p {
				font-size: 18px;
				margin-right: 30px;
			}
		}
		@media (min-width: 780px) {
			p {
				font-size: 18px;
			}
		}
		@media (max-width: 480px) {
			flex-direction: column;
			justify-content: center;
			p {
				width: 85%;
			}
		}
		.eoy-button {
			border-radius: 7px;
			border: none;
			min-width: 160px;
			height: 38px;
			color: #fff;
			background-color: #e1bf42;
			opacity: 1;
			display: inline-block;
			text-align: center;
			padding: 6px;

			cursor: pointer;
			a {
				color: #2e5266;
			}
		}
	}

	.eoy-slider {
		display: flex;
		max-width: 1024px;
		padding: 0 15px;
		margin: 0 auto;
		.slick-prev {
			left: -10px !important;
		}
		.slick-next {
			right: -10px !important;
		}
		.slick-slide {
			border-radius: 10px;
			position: relative;
			margin: 50px 50px;
			margin-top: 50px;
			@media (max-width: 780px) {
				margin: 10px !important;
			}
			@media (max-width: 600px) {
				div {
					width: 100%;
				}
			}
			img {
				width: 100%;
				height: 250px;
				object-fit: cover;
				background-color: #000;
			}
			.name {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 18px;
				background-color: #00000059;
			}
		}
	}
}
