.course-page {
  padding: 40px 0;
  color: #2f5266;
  h1 {
    text-transform: uppercase;
  }
  h2 {
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    font-size: 19px;
  }

  .scroll-down {
    font-weight: 600;
    color: #e1bf42;
    font-size: 20px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .down {
      height: 14px;
      width: 14px;
      display: inline-block;
      border: solid #e1bf42;
      border-width: 0 2.5px 2.5px 0;
      transform: rotate(45deg);
    }
  }
  .col-6 {
    max-width: 700px;
    padding: 15px;
    margin: auto;
  }
}
