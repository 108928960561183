.bayan-podcast {
  width: 40%;
  margin: 100px 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  .jihad-audio {
    display: flex;
    justify-content: center;
  }
}
