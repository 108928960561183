.certificate-page {
    padding: 40px 0;
    color: #2F5266;
    background-color: #2F5266;
    img {
        max-width: 100%;
    }
    .container-sm {
        max-width: 960px;
        margin: auto;
    }
    h1 {
        font-size: 26px;
        color: #fff;
        text-align: left;
    }
    .edu-list {
        background: #F9F9F9;
        padding: 20px;
        margin-top: 20px;
        align-items: center;
        position: relative;
        transition: .3s linear;
        position: relative;
        padding-right: 120px;
        cursor: pointer;
        @media (max-width: 768px) {
            padding-right: 0;
            border-radius: 4px;
            img {
                position: static !important;
                max-width: 60px;
            }
        }
        ul {
            padding: 0;
            li {
                list-style: none;
            }
        }
        img {
            position: absolute;
            bottom: 30px;
            right: 20px;
        }
        h2 {
            font-size: 22px;
            margin: 0;
            margin-top: 20px;
        }
        p {
            margin: 8px 0 0 0;
        }
    }
}
