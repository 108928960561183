.sc-emoji-picker {
  overflow: auto;
  width: 100%;
  max-height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 15px;
  .sc-emoji-picker--category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .sc-emoji-picker--category-title {
    min-width: 100%;
    color: #b8c3ca;
    font-weight: 200;
    font-size: 13px;
    margin: 5px;
    letter-spacing: 1px;
  }
  
  .sc-emoji-picker--emoji {
    margin: 5px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    font-size: 28px;
    transition: transform 60ms ease-out,-webkit-transform 60ms ease-out;
    transition-delay: 60ms;
    &:hover {
      transform: scale(1.4);
    }
  }
}