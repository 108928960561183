.contactus {
    padding: 40px 0;
    color: #234155;
    text-align: center;
    font-size: 14px;
    .container {
        max-width: 700px;
    }
    .call-click {
        a {
            color: #E1BF42;
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 1.36px;
        }
    }
    .socail-links {
        text-align: center;
        a {
            padding: 5px;
            img {
                max-width: 26px;
            }
        }
    }
    .sub-heading {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        margin: 20px 0;
    }
    .c-detail {
        text-align: left;
        margin-bottom: 30px;
    }
    @media (max-width: 480px) {
        iframe {
            width: 100%;
        }
    }
}