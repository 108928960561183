.webinar-body {
  background-color: #2f5266;
  padding: 10px;
  color: #ffffff;
  padding: 50px 0;
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
  .wrapper {
    max-width: 1140px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
    h1{
      margin: 10px 0;
      text-align: center;
      color: rgb(225, 191, 66);
    }
  }
  .heading {
    text-align: center;
    padding: 0;
    font-size: 30px;
    margin: 0;
    margin-bottom: 30px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
    a{
      width: 199px;
      height: 45px;
      font-size: 16px;
      background-color: rgb(225, 191, 66);
    }
  }
  .new-webinar-heading{
    text-align: left;
    max-width: 850px;
    margin: 0 auto;
    p{
      @media (max-width: 480px) {
        font-size: 30px !important;
      }
    }
    
  }
  .webinar-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
    .col {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
    .col-45 {
      -ms-flex: 0 0 45%;
      flex: 0 0 45%;
      max-width: 45%;
      @media (max-width: 1024px) {
        margin-bottom: 50px;
      }
      @media (max-width: 480px) {
        margin: 10px 0;
      }
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
    .col-55 {
      -ms-flex: 0 0 55%;
      flex: 0 0 55%;
      max-width: 55%;
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .left-description {
      h5 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        text-decoration: underline;
        margin-bottom: 10px;
      }
      p {
        margin: 0 0 10px 0;
      }
    }

    .right {
      .web-video {
        margin-bottom: 20px;

        .webinar-video-iframe {
          width: 100% !important;
          height: 360px !important;
          @media (max-width: 480px) {
            height: 260px !important;
          }
          @media (max-width: 360px) {
            height: 200px !important;
          }
        }
      }
      .cato {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.webinar-body > div {
  font-size: 14px;
}
