.edit-profile-block {
    font-size: 14px;
    .profile-banner {
        background-color: #23415573;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        overflow: hidden;
        -moz-transition: .3s linear;
        -webkit-transition: .3s ease-out;
        transition: .3s linear;
        position: relative;
        &:hover {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color:#2341558c;
            pointer-events: none;
          }
          .userimg-cover {
            transform:translateY(15px);
          }
        }
        .cover-img {
            width: 100%;
        }
        @media only screen and (max-width: 767px) {
            height: 150px;
            .userimg-cover {
              background-color: transparent !important;
              border: 0 !important;
            }
        }
        .userimg-cover {
            border: 1px dashed #FFFFFF;
            border-radius: 24px;
            width: 100%;
            padding: 10px;
            position: absolute;
            color: #fff;
            max-width: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 1;
            -moz-transition: .3s linear;
            -webkit-transition: .3s ease-out;
            transition: .3s linear;
            background-color: #2341558c;
            img {
                margin: 0 10px;
            }
            input {
                position: absolute;
                opacity: 0;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: pointer;
            }
        }
    }
    .user-application {
        padding: 10px;
        background-color: #fff;
        .h-250{
          height: 250px;
        }
        .edit-action-button {
          margin-bottom: 20px;
          button {
            margin-right: 10px;
            background-color: #fff;
          }
          .btn-yellow {
            background-color: #e1bf42;
          }
          .btn-yellow:disabled {
            background-color: #e1bf429c;
          }
        }
        .edit-field-block {
          display: flex;
          margin: 0 -10px;
          .w-100 {
            width: 100%;
            padding: 0 10px;
          }
          .signup-name-fields {
            margin-bottom: 10px;
            label {
              display: block;
            }
            select,
            input {
              background: #fff;
              border-radius: 4px;
              outline: none;
              padding: 10px 20px;
              min-width: 160px;
              flex: 1;
              display: inline-block;
              width: 100%;
              font-size: 14px;
              margin: 10px 0;
              border-width: 1px !important;

              // border: 0 !important;
            }
          }
          
        }
        @media only screen and (max-width: 1024px) {
          .row .col-md-3 {
            display: block !important;
          }
          .row .col-md-9 {
            padding: 0 15px;
          }
        }
        @media only screen and (max-width: 767px) {
          padding: 20px 0;
          margin-top: -88px;
          background-color: transparent;
          .row .col-md-3,
          .row .col-md-9 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
          .row .col-md-9 {
            background-color: #F3F3F3;
            position: relative;
            top: 20px;
          }
          .edit-field-block {
            flex-direction: column;
          }
          .edit-action-button {
            text-align: center;
          }
        }
        @media only screen and (max-width: 480px) {
          .btn {
            min-width: 130px !important;
          }
        }
        .main-app {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media only screen and (max-width: 767px) {
                flex-direction: column;
                .user-img {
                    display: flex;
                    margin: 0 !important;
                    flex-direction: column;
                    h2 {
                        margin: 10px !important;
                        font-weight: 600 !important;
                        font-size: 16px !important;
                        color: #fff;
                    }
                }
            }
            .user-img {
                display: inline-flex;
                align-items: center;
                margin: -25px 0;
                position: relative;
                .ml-15{
                    display: inline-flex;
                    flex-direction: column;
                    position: relative;
                    width: auto;
                    padding: 0 40px 0 10px;
                     .edit-btn {
                      top: 0;
                    }
                }
                @media only screen and (max-width: 767px) {
                  width: 100%;
                  .ml-15 {
                    margin-left: 0 !important;
                    text-align: center;
                    background-color: #F2F2F2;
                    margin-top: 20px;
                    padding-bottom: 10px;
                    display: inline-flex;
                    flex-direction: column;
                    position: relative;
                    width: auto;
                    padding: 0 30px;
                    h2 {
                      color: #234155;
                    }
                  }
                 
                }
                .edit-btn {
                    position: absolute;
                    background: url(/icons/pencil.svg) no-repeat;
                    background-size: 16px;
                    background-position: center;
                    background-color: #e8ebed;
                    border: 0;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    text-indent: -9999px;
                    -moz-transition: .3s linear;
                    -webkit-transition: .3s ease-out;
                    transition: .3s linear;
                    top: 5px;
                    margin-left: 25px;
                    right: 0;
                    // @media only screen and (max-width: 767px) {
                    //   top: 126px;
                    //   margin-left: 65px;
                    // }
                }
                .profileImg,
                .userimg {
                    height: 100px;
                    min-width: 100px;
                    max-width: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    .userimg-cover {
                        z-index: 222;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: #2341558c;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        -moz-transition: .3s linear;
                        -webkit-transition: .3s ease-out;
                        transition: .3s linear; 
                        opacity: 1;
                        input {
                            position: absolute;
                            opacity: 0;
                            height: 100%;
                            width: 100%;
                            cursor: pointer;
                        }
                    }
                    img {
                      max-width: 100%;
                      object-fit: cover;
                      -moz-transition: .3s linear;
                      -webkit-transition: .3s ease-out;
                      transition: .3s linear; 
                      cursor: pointer;
                      background: #ffffff00;
                    }
                  &:hover {
                    .cam {
                      transform:translateY(10px);
                      -moz-transition: .3s linear;
                        -webkit-transition: .3s ease-out;
                        transition: .3s linear; 
                    }
                  }
                    
                }
                h2 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 18px;
                }
                p{
                  margin: 0;
                }
            }
            @media only screen and (max-width: 767px) {
              .edit-mobile-des {
                background-color: #F2F2F2;
                flex: 1;
                display: flex;
                flex-direction: column;
                width: calc(100% + 30px);
                padding: 0px;
                margin-bottom: -20px;
                padding: 10px 0;
                .btn {
                    margin: 5px auto !important;
                    max-width: 200px;
                    width: 100%;
                }
            }
            }
        }
    }
    .about-card {
        margin-right: 50px;
        position: relative;
        .edit-btn {
            position: absolute;
            right: 15px;
            background: url(/icons/pencil.svg) no-repeat;
            background-size: 16px;
            background-position: center;
            background-color: #e8ebed;
            border: 0;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            text-indent: -9999px;
            -moz-transition: .3s linear;
            -webkit-transition: .3s ease-out;
            transition: .3s linear;
        }
        h2 {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
        }
        p {
            margin: 10px 0;
            min-height: 140px;
        }
        .edit-btn {
            opacity: 1;
        }
        @media only screen and (max-width: 768px) {
            margin-right: 0;
            .edit-btn {
                opacity: 1;
                background-color: transparent !important;
                background-size: 12px;
                right: 7px;
                top: 7px;
            }
            p {
                min-height: 40px;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .publications {
                height: 40px !important;
                background-color: transparent !important;
                border: 0 !important;
            }
            .btn {
                min-width: 120px !important;
                margin-top: 10px;
            }
        }
        
    }
    .publications-card {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;

        .publications {
            align-items: center !important;
            justify-content: center !important;
            text-align: left;
            background: #e4e4e469;
            height: 160px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            width: 100%;
        }
    }
    .yourself-modal.about-modal {
        h2 {
            font-weight: 600;
            font-size: 16px;
            margin-top: 0;
        }
        .detail-int {
            background: rgb(243, 243, 243);
            border-radius: 23px;
            border: 0px;
            outline: none;
            padding: 10px 20px;
            min-width: 160px;
            flex: 1 1 0%;
            display: inline-block;
            width: 100%;
            font-size: 14px;
            min-height: 150px;
        }
        .connect-btn {
            border: 1px solid #e1bf42;
            color: #fff !important;
            background-color: #e1bf42;
            display: inline-flex;
            padding: 6px;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            min-width: 150px;
            font-size: 13px;
            transition: all 0.3s linear 0s;
        }
    }
    @media only screen and (max-width: 767px) {
        .col-md-5,
        .col-md-7 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .mt-3 {
            margin-top: 0 !important;
        }
        .col-md-5 {
            display: flex;
            .about-card {
                margin-right: 3%;
            }
            .card {
                flex: 0 0 48.5% !important;
                max-width: 48.5% !important;
                margin-bottom: 0 !important;
            }
        }
        .mt-3.mobile-profile {
        .col-md-5 {
          flex-direction: column;
        }
        .publications-card {
              // margin-bottom: 100px !important;
              .pub-slider {
                height: auto !important;
              }
        }
      }
    }
    @media only screen and (max-width: 480px) {
      .col-md-5 {
          .card {
              flex: 0 0 100% !important;
              max-width: 100% !important;
              margin-bottom: 30px !important;
          }
      }
    }
}
