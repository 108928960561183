.yourself-modal {
  background-color: #23415582 !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5555 !important;
  overflow-y: auto;
  .mb-btn {
    min-width: 100px;
    height: 45px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 191, 66);
    cursor: pointer;
    color: #fff;
    border: 0;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .socail-links {
    button {
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      margin-right: 10px;
    }
  }
  .copy-text {
    background: #f3f3f3;
    border-radius: 6px;
    border: 0;
    outline: none;
    padding: 10px 20px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .share-on-text {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .area-text {
    background: #f3f3f3;
    border-radius: 16px;
    border: 0;
    outline: none;
    padding: 10px 20px;
    min-height: 100px;
    width: 100%;
  }
  .connect-btn {
    border: 1px solid #e1bf42;
    border-radius: 6px;
    color: #fff !important;
    background-color: #e1bf42;
    width: 109px;
    font-size: 11px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    @media (max-width: 480px) {
      width: 100%;
    }
    &:disabled {
      opacity: 0.6;
      cursor: auto;
    }
  }

  .vertical-scroll {
    overflow-y: auto;
    max-height: 400px;
    margin: 0 -15px;
    padding: 0 15px;
  }
  .like-loader {
    display: flex;
    min-height: 200px;
    align-items: center;
  }
  .tabs {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    display: flex;
    li {
      list-style: none;
      padding: 7px 2px;
      border-bottom: 2px solid transparent;
      margin-right: 15px;
      img {
        margin-right: 10px;
      }
    }
    .active {
      border-color: #2f5266;
    }
  }
  .card {
    margin-bottom: 0 !important;
    padding: 10px 0 !important;
    .yourself {
      .react_tinylink_card {
        max-width: 100% !important;
        border-radius: 10px !important;
        margin: 10px 0 !important;
      }
    }
  }
  .modal_content {
    z-index: 55;
    border: 0;
    margin-bottom: 20px;
    .multi-images {
      padding: 0;
      li {
        list-style: none;
        span {
          display: inline-flex;
          min-width: 0px;
          overflow: hidden;
          width: 130px;
          height: 130px;
          margin: 0px 6px 0px 0px;
          position: relative;
          cursor: pointer;
          img {
            object-fit: contain;
            background-color: #000;
            &.doc-icon {
              background-color: transparent;
            }
          }
          .thumb_overlay {
            display: none;
            background-color: rgba(0, 0, 0, 0.6);
            width: 130px;
            height: 130px;
            position: absolute;
            left: 0;
            top: 0;
            .thumb_cross {
              position: absolute;
              right: 10px;
              top: 5px;
              font-size: 22px;
              cursor: pointer;
              font-weight: 600;
              color: #fff;
            }
          }
          &:hover {
            .thumb_overlay {
              display: block;
            }
          }
        }
      }
    }
    // .roundedimg {
    //   position: absolute;
    //   left: -20px;
    //   top: -20px;
    // }
    .close-btn {
      background: #ffffff;
      position: absolute;
      top: -35px;
      right: 0px;
      height: 24px;
      width: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      color: #2f5266 !important;
    }
    .sharewith {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      flex-wrap: wrap;
      .sharewith-in {
        display: flex;
        align-items: center;
        .drop-share {
          position: relative;
          .dropdown-share {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-left: 15px;
            cursor: pointer;
            span {
              width: 20px;
              height: 20px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
            img {
              margin-right: 5px;
            }
            &:after {
              content: ">";
              font-size: 16px;
              margin-left: 10px;
              transform: rotate(90deg);
            }
          }
          .dropdown-share-more {
            position: absolute;
            background-color: #fff;
            min-width: 180px;
            border-radius: 6px;
            top: 32px;
            display: none;
            border: 1px solid #ccc;
            left: 10px;
            @media (max-width: 767px) {
              left: inherit;
              right: 0;
            }
            ul {
              padding: 5px 0;
              margin: 0;
              li {
                list-style: none;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                font-size: 12px;
                line-height: 12px;
                cursor: pointer;
                span {
                  min-width: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                img {
                  margin-right: 5px;
                  min-width: 15px;
                }
                &:first-child {
                  img {
                    min-width: 15px;
                  }
                }
                &:nth-child(3) {
                  img {
                    min-width: 11px;
                  }
                }
              }
            }
          }
          &.open {
            .dropdown-share-more {
              display: block;
            }
            .dropdown-share {
              &:after {
                content: ">";
                font-size: 16px;
                margin-left: 10px;
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      @media (max-width: 480px) {
        .sharewith-in {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 12px;
          margin-bottom: 15px;
        }
      }

      .post-btn {
        background-color: rgb(225, 191, 66);
        color: #fff;
        padding: 10px 35px;
        display: inline-block;
        border-radius: 4px;
        border: 0;
        cursor: pointer;
        @media (max-width: 480px) {
          width: 100%;
        }
      }
      select {
        border: 0;
        outline: none;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        @media (max-width: 480px) {
          font-size: 12px;
          margin: 0 !important;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
