.sitemap{
    margin: 0 auto;
    h1{
        text-align: center;
        border-bottom: 1px solid black;
    }
    ul{
        li{
            text-align: center;
            display: block;
            font-size: 20px;
            margin: 0 auto;
        }
        .highlighted{
            font-weight: 700;
        }
    }
    
}