.bayan-media {
  padding: 40px 0;
  .inner-media {
    display: flex;
    max-width: 960px;
    margin: 40px auto;

    &.blue-text {
      color: #2e5266 !important;
    }
    span {
      background: #707070;
      border: 1px solid #707070;
      border-radius: 10px;
      min-width: 300px;
      height: 382px;
      // height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
    h3 {
      opacity: 1;
      font-size: 25px;
      line-height: 35px;
      font-weight: 500;
      margin: 10px 0;
    }
    p {
      font-size: 20px;
      letter-spacing: 0px;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 0;
    .headingF {
      font-size: 27px !important;
    }
    .inner-media {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0;
      }
      p {
        display: none;
      }
    }
  }
}
.bayan-media-blue {
  background-color: #2f5266;
  h1 {
    color: #fff;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      font-size: 20px;
      font-weight: 400;
      margin: 0 10px;
      display: inline-flex;
      align-items: center;
      &:before {
        content: "";
        border-width: 0 2px 2px 0;
        transform: rotate(135deg);
        height: 10px;
        width: 10px;
        display: inline-block;
        border-color: #fff;
        border-style: solid;
        margin-right: 5px;
      }
    }
  }
  h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .inner-media {
    &.blue-text {
      color: #2e5266 !important;
    }
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    // height: auto;
    min-height: 300px;
    align-items: center;
    span {
      border: 0;
      width: 50%;
      border-radius: 0;
      // height: 100%;
      height: 382px;
      margin: 0;
    }
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    p {
      margin-top: -5px;
      font-size: 16px;
    }
    .right-detail {
      width: 50%;
      padding: 30px 50px;
      .button-container {
        .bayan-button {
          margin-top: 10px;
          background-color: #e1bf42;
          height: 45px;
          margin: 0;
        }
      }
    }
  }
  #social-media {
    max-width: 1170px;
    margin: 0 auto;
    .inner-sm {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      .iner-detail {
        padding: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        p {
          color: #333333;
        }
      }
      .socail-icon {
        margin-top: 60px;
        flex: 1;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
        img {
          width: 30px;
        }
      }
    }
  }
  .bayan-claremount-b {
    margin: 200px 0 100px 0;
    h2 {
      margin-bottom: 0;
    }
    p {
      color: #fff;
    }
  }
}
.mobile-event {
  @media only screen and (max-width: 767px) {
    background-color: #e4e4e4;
    padding: 10px 0 0 0 !important;
    .container {
      padding: 0;
      background-color: transparent !important;
    }
    h1 {
      flex-direction: column;
      color: #2f5266;
      a {
        &:before {
          border-color: #2f5266;
        }
      }
    }
    h2 {
      color: #2f5266;
      margin: 25px 0;
    }
    // padding: 20px 0;
    .headingF {
      font-size: 27px !important;
    }
    .inner-media {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      text-align: left;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      p {
        display: block;
      }
      .right-detail,
      span {
        width: 100%;
        height: 260px;
      }
      .right-detail {
        padding: 15px;
      }
    }
    .col-3 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px !important;
      min-height: 400px;
      .inner-sm {
        border-radius: 0 !important;
      }
    }
    .bayan-claremount-b {
      display: none;
    }
  }
}
