.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 55555;
  right: 0;
  transform: translate(-100%);
  transition: 0.3s linear;
  .menu-bg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #d1d1d1b8;
  }
  .left-side-menu {
    width: 100%;
    min-height: 100vh;
    max-width: 300px;
    background-color: #fff;
    position: relative;
    z-index: 222;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
      }
    }
    .menu-header {
      padding: 15px 15px 0 15px;
      height: 85px;
      p {
        font-size: 10px;
        letter-spacing: 2px;
      }
      .close-icon {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 16px;
        height: 16px;
        opacity: 1;
        &:before {
          position: absolute;
          left: 15px;
          content: " ";
          height: 16px;
          width: 2px;
          background-color: #2f5266;
          border-radius: 4px;
          transform: rotate(45deg);
        }
        &:after {
          position: absolute;
          left: 15px;
          content: " ";
          height: 16px;
          border-radius: 4px;
          width: 2px;
          background-color: #2f5266;
          transform: rotate(-45deg);
        }
      }
    }
    .menu-body {
      flex: 1;
      padding: 15px;
      font-size: 14px;
      overflow-y: auto;
      max-height: calc(100vh - 170px);
      .menu {
        li {
          a {
            padding: 10px 0;
            display: flex;
            align-items: center;
            letter-spacing: normal;
            justify-content: space-between;
            font-size: 14px;
            border-bottom: 1px solid #cccccc4a;
            &:after {
              content: "";
              background: url(https://d310ox1f4zno4b.cloudfront.net/static-content/arrow.png.webp)
                no-repeat center;
              background-size: 10px;
              height: 14px;
              width: 14px;
              display: inline-block;
              transform: rotate(-90deg);
            }
          }
          &:last-child {
            a {
              border-bottom: 0;
              &:after{
                display: none;
              }
            }
          }
          // &:first-child {
          // 	a {
          // 		&:after {
          // 			display: none;
          // 		}
          // 	}

          &:first-child {
            a {
              &:after{
                display: none;
              }
            }
          }
          // }
        }
        
      }
      .action-btns {
        padding: 10px;
        li {
          a {
            border: 1px solid #2f5266;
            color: #2f5266;
            padding: 10px;
            border-radius: 40px;
            text-align: center;
            display: block;
            margin: 15px 0;
          }
          .active {
            background-color: #e1bf42;
            border-color: #e1bf42;
            color: #fff;
          }
        }
      }
      .already-text {
        font-size: 14px;
        text-align: center;
        margin: -5px 0 0 0;
      }
      
    }
    .menu-footer {
      text-align: center;
      padding: 10px;
      height: 85px;
      h3 {
        text-transform: uppercase;
        text-align: center;
        font-size: 12px;
        margin: 0;
      }
      p {
        margin: 4px 0;
        font-size: 12px;
      }
    }
    .menu-mobile-block {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #fff;
      transform: translate(-100%);
      transition: 0.3s linear;
      .menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 15px !important;
        .backarrow {
          img {
            transform: rotate(90deg);
          }
        }
        label {
          font-weight: 600;
        }
        .close-icon {
          position: relative;
          top: 0;
          right: 10px;
        }
      }
      .menu-body {
        max-height: calc(100vh - 60px);
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        .menu-block {
          border-bottom: 1px solid #cccccc45;
          padding: 15px 0;
          label {
            font-weight: 600;
          }
          ul {
            padding: 5px 0;
            li {
              a {
                display: block;
                padding: 5px 0;
              }
            }
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        .menu-socail-block {
          ul {
            display: flex;
            li {
              margin-right: 10px;
              img {
                width: 36px;
              }
            }
          }
        }
        .start-blk {
          h3 {
            font-size: 16px;
            text-align: center;
          }
          ul {
            li {
              text-align: center;
              a {
                background-color: rgb(225, 191, 66);
                color: #fff;
                padding: 10px 35px;
                display: inline-block;
                border-radius: 30px;
              }
            }
          }
        }
      }
    }
    .menu-mobile-block.open {
      transform: translate(0);
    }
  }
}
.side-menu.open {
  transform: translate(0);
}

.admin-header {
  background: #ffffff;
  box-shadow: 0px 3px 25px #0000001a;
  font-size: 13px;
  padding: 9px;
  display: flex;
  color: #234155;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 555;
  top: 0;
  .wrapper {
    max-width: 1366px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 90%;

    .inner-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-form {
        position: relative;
        margin-left: 15px;
        input {
          background: #f3f3f3 0% 0% no-repeat padding-box;
          border-radius: 23px;
          border: 0;
          outline: none;
          padding: 10px 20px 10px 40px;
          min-width: 160px;
          display: inline-block;
        }
        img {
          position: absolute;
          top: 12px;
          left: 15px;
          max-width: 16px;
        }
      }
      .area {
        display: flex;
        .links {
          display: flex;
          align-items: center;
          margin: 0 15px;
          position: relative;
          cursor: pointer;
          @media (max-width: 1309px) {
            margin: 0 9px;
          }
          .roundedimg {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #234155;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
          .down-arrow {
            margin-left: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            transition: 0.3s linear;
            &:hover {
              background-color: #eee;
            }
            &:after {
              content: ">";
              display: block;
              color: #234155;
              transform: rotate(90deg);
              opacity: 0.8;
              transition: 0.3s linear;
              font-size: 18px;
            }
          }
          .profile-dropdown {
            position: absolute;
            top: 58px;
            background-color: #fff;
            right: -10px;
            left: 0;
            border-radius: 0 0 4px 4px;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
            z-index: 9999;
            overflow: hidden;
            .billing-btn {
              .hidden {
                display: none;
              }
            }
            div {
              border-bottom: 1px solid #eee;
              display: flex;
              align-items: center;
              transition: 0.3s linear;
              span {
                display: inline-flex;
                height: 20px;
                width: 20px;
                align-items: center;
                justify-content: center;
                margin-right: 7px;
              }
              a {
                display: flex;
                align-items: center;
                padding: 7px 10px;
                width: 100%;
              }
              &:hover {
                background-color: #eee;
              }
              &:last-child {
                padding: 7px 10px;
                border-bottom: 0;
              }
            }
          }
          // a {
          //     img {
          //         width: 20px;
          //         max-width: 20px;
          //     }
          // }
        }
        .links.open {
          .down-arrow {
            background-color: #eee;
            &:after {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .left-area,
      .right-area {
        min-width: 300px;
        position: relative;
      }
      .left-area {
        display: flex;
        align-items: center;
        a {
          min-width: 23px;
        }
        .header-logo {
          max-height: 56px;
          object-fit: contain;
          object-position: left;
        }
      }
      .right-area {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .notification {
          padding: 0 0 0 20px;
          position: relative;
          height: 43px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .circle {
            height: 16px;
            width: 16px;
            background-color: #ef5350;
            color: #fff;
            position: absolute;
            top: 0px;
            right: -10px;
            display: inline-flex;
            align-items: center;
            border-radius: 50%;
            font-size: 10px;
            justify-content: center;
            z-index: 22;
          }
          img {
            width: 20px;
            height: auto;
          }
        }
        .msgs {
          padding: 0 0 0 20px;
          position: relative;
          height: 43px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .circle {
            height: 16px;
            width: 16px;
            background-color: #ef5350;
            color: #fff;
            position: absolute;
            top: 0px;
            right: -10px;
            display: inline-flex;
            align-items: center;
            border-radius: 50%;
            font-size: 10px;
            justify-content: center;
            z-index: 22;
          }
          img {
            width: 42px;
          }
        }
      }
    }
    .mobile-header {
      display: none;
    }
    @media (max-width: 1024px) {
      .desktop-header {
        display: none;
      }

      .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 3px 25px #0000001a;
      }
    }
  }
}
