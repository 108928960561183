button {
  border-radius: 10px;
  background-color: grey;
  outline: none;
}

button:hover {
  background-color: orange;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 30px;
  right: 30px;
  max-width: 750px;
  margin: auto;
  width: auto;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.close-btn {
  color: Black;
  float: right;
  font-size: 20px;
}

.close-btn:hover {
  cursor: pointer;
}

.socialMediaPopper {
  top: 300px !important;
  left: unset !important;
  right: 0px !important;
  display: grid;
}

.socialMediaButton {
  &:hover > svg {
    height: 50px !important;
    width: 50px !important;
  }
}